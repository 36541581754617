import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServices,
  listBundleServices,
  serviceRatesUpdate,
  serviceDefaultUpdate,
  getService,
  listDefaultServices,
  getCSVData,
  publishService,
  unpublishService,
  listServiceAgreements,
  assignServiceToAgreements,
  getBundleService,
  updatePackageStatus,
  listAddedServices,
  listDefaultServicesType
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR, APP_LIVE_URL } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import localization from '../../../localization/localization';
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Services extends Component {
  constructor() {
    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.state = {
      tableData: [],
      tableDataBundle: [],
      bundleServiceDetails: [],
      bundleDetails: [],
      serviceData: [],
      bundlesData: [],
      defaultServices: [],
      bundleId: "",
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",

      unpublishErrorMessage: "",
      unpublishSuccessMessage: "",
      unpublishWaitMessage: "",

      product_summary: "",
      rate_margin: 0,
      total_commission: 0,

      mainError: "",
      tableLoader: true,
      tableLoaderBundle: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      defaultRate: "",
      defaultSmsIn: "",
      defaultSmsOut: "",
      agent_mrc_comission: 0,
      agent_nrc_comission: 0.0,
      agent_rate_comission: 0,
      sms_in_comission: 0,
      sms_out_comission: 0,
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      service_type: "",
      default_mrc: "",
      default_nrc: "",
      default_rate: "",
      default_sms_in: "",
      default_sms_out: "",
      sms_in: "",
      sms_out: "",
      sms_in_percentage: "",
      sms_out_percentage: "",
      mrc_percentage: "",
      rate_percentage: "",
      select_service: "",
      rate_error_text: "",
      rate_error: false,
      mrc_error_text: "",
      mrc_error: false,
      nrc_error_text: "",
      nrc_error: false,
      sms_in_error_text: "",
      sms_in_error: false,
      sms_out_error_text: "",
      sms_out_error: false,
      main_type: "",
      effective_date: "",
      rate_deck_file: "",
      assigned_agreements: [],
      list_agreements: [],
      assignErrorMessage: "",
      assignSuccessMessage: "",
      statusSuccessMessage: "",
      statusErrorMessage: "",
      package_status: "",
      package_id: "",
      did_agreement: [],
      columnBundleServices: [],
      all_service_types: [],
      selected_service_type: null,
      service_type_id: "",
      defaultValue: null,
      loadingIndex: null,

      columnServices: [
        { name: "Id" },
        { name: "Title" },
        { name: "Name" },
        {
          name: "Date Added",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        { name: "SKU" },
        { name: "Rate" },
        {
          name: "Marketing",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.marketingLink(value, tableMeta)}</div>;
            },
          },
        },
        /*{
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },*/
        {
          name: "Published",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.publishedText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="Edit Rate" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/services/Editservice/" +
                                          tableMeta.rowData[0]
                                      )
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>{localization.productListing.listingLink1}</span>
                                  </a>
                                </Tooltip>
                                {/*<a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.setMarkUp(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Service Popup</span>
                                </a>*/}
                              </li>
                              <li>
                                <Tooltip
                                  title="Rate / Rate Deck Detail"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.showDetails(value, tableMeta)
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-list"></em>
                                    <span>{localization.productListing.listingLink2}</span>
                                  </a>
                                </Tooltip>
                              </li>
                              {/*<li>
                                <Tooltip
                                  title="Assign agreement to the service"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.agreementAlert(value, tableMeta)
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-link-group"></em>
                                    <span>Assign Agreement</span>
                                  </a>
                                </Tooltip>
                                  </li>*/}
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  format_price = (value) => {
    let price = value;

    if (price !== "") {
      return "$" + price;
    } else {
      return "";
    }
  };

  package_status = (value) => {
    let package_status = value;

    if (package_status === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <Tooltip title="Set as Default Rate / Rate Deck" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.setDefaultService(value, tableMeta)}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> Make Default
            </span>
          </a>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  marketingLink = (value, tableMeta) => {
    const { loadingIndex } = this.state;
    const rowIndex = tableMeta.rowIndex;
    let is_default = tableMeta.rowData[6];
    let marketing = is_default.split("___");
    if (marketing[0] === "link") {
      return (
        <Tooltip title="Open Marketing Link" placement="top">
          <a
            style={{ cursor: "pointer" }}
            href={marketing[1]}
            target="_blank"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View Link
            </span>
          </a>
        </Tooltip>
      );
    } else if (marketing[0] === "pdf") {
      return (
        <Tooltip title="Download PDF" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setLoadingState(rowIndex, marketing[1]);
            }}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
            }
          >
            {loadingIndex === rowIndex ? (
              <>
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-primary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <span className="badge badge-outline-primary">
                <em className="icon ni ni-signin"></em>
                View PDF
              </span>
            )}
          </a>
        </Tooltip>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  marketingLinkPackage = (value) => {
    let is_default = value;
    let marketing = is_default.split("___");
    if (marketing[0] === "link") {
      return (
        <Tooltip title="Open Marketing Link" placement="top">
          <a
            style={{ cursor: "pointer" }}
            href={marketing[1]}
            target="_blank"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View Link
            </span>
          </a>
        </Tooltip>
      );
    } else if (marketing[0] === "pdf") {
      return (
        <Tooltip title="Download PDF" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.downloadMarketingPdf(marketing[1])}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View PDF
            </span>
          </a>
        </Tooltip>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  publishedText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[7];
    if (is_default === "No") {
      return (
        <Tooltip
          title="Publish this service for clients and agents"
          placement="top"
        >
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.publishService(value, tableMeta)}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-caution"></em> Publish
            </span>
          </a>
        </Tooltip>
      );
    } else {
      return (
        <>
          
          <Tooltip
            title="Unpublish this service for clients and agents"
            placement="top"
          >
            <a
              style={{ cursor: "pointer", marginTop:"5px" }}
              onClick={() => this.unpublishService(value, tableMeta)}
              disabled={
                this.props.is_msa_signed === "No" ||
                this.props.defaultSettings === "missing"
                  ? true
                  : false
              }
            >
              <span className="badge badge-outline-warning">
                <em className="icon ni ni-caution"></em> Unpublish
              </span>
            </a>
          </Tooltip>
        </>
      );
    }
  };

  async componentDidMount() {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    }
    await this.getServiceTypes();
  }

  setLoadingState = (index, pdfUrl) => {
    this.setState({ loadingIndex: index });

    setTimeout(() => {
      this.setState({ loadingIndex: null });

      if (pdfUrl) {
        this.downloadMarketingPdf(pdfUrl);
      }
    }, 1000);
  };

  listServicesTypesFun = async () => {
    const servicesResponce = await listDefaultServicesType(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        mainError: "There is some error while getting the services Types",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const defaultValue = servicesResponce.data.data[0]?.[3] || "";
      this.setState({
        defaultValue,
        tableLoader: false,
      });
    } else {
      this.setState({
        defaultValue: "",
        mainError: "There is some error while getting the services",
      });
    }
  };


  reloadServices = async () => {
    const { service_type_id } = this.state;
    this.setState({
      tableLoader: true,
    });
    console.log("service_type_id: ", service_type_id);
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken(),
      service_type_id
    );
    console.log("reloadServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    }
  };

  getServiceTypes = async () => {
    /*const { defaultValue } = this.state;
    console.log("defaultValue", defaultValue);
    let serviceValue = localStorage.getItem("serviceValue");
    console.log("serviceValue", serviceValue);

    const servicesResponce = await listAddedServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.reloadServices();
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      let first_value = null;
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (i === 0) {
            if (serviceValue) {
              first_value = serviceValue;
            } else if (defaultValue) {
              first_value = defaultValue;
            } else {
              first_value = servicesResponce.data.data[i]["product_id"];
            }
          }

          let newArr = {
            label: servicesResponce.data.data[i]["product_title"],
            value: servicesResponce.data.data[i]["product_id"],
          };
          all_clients.push(newArr);
        }
      }

      this.setState({
        all_service_types: all_clients,
        
      });

      let get_initial_selection = this.getInitialSelectedOption(
        first_value,
        all_clients
      );

      this.setState(
        {
          selected_service_type: get_initial_selection,
          service_type_id: get_initial_selection.value,
        },
        () => {
          this.reloadServices();
        }
      );
    } else {
      this.reloadServices();
    }*/
      const servicesResponce = await listAddedServices(
        auth.getAccount(),
        auth.getToken()
      );
  
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.reloadServices();
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        let all_clients = [];
        if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
          for (let i = 0; i < servicesResponce.data.data.length; i++) {
            
            let newArr = {
              label: servicesResponce.data.data[i]["product_title"],
              value: servicesResponce.data.data[i]["product_id"],
            };
            all_clients.push(newArr);
          }
        }
  
        this.setState({
          all_service_types: all_clients,
        });
  
      } else {
        this.reloadServices();
      }
  };

  getInitialSelectedOption = (value, all_clients) => {
    // Retrieve the initial selected option from your options array
    let options = all_clients; // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value == value) || null;
      
    }
  };

  handleChangeSearch = async (changed_value) => {
    if (changed_value) {
      this.setState(
        (prevState) => ({
          service_type_id: changed_value.value,
          selected_service_type: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          //localStorage.setItem("serviceValue", changed_value.value);
          this.reloadServices();
        }
      );
    } else {
      this.setState(
        {
          service_type_id: null,
          selected_service_type: null,
        },
        () => {
          console.log("Cleared selection");
          //localStorage.removeItem("serviceValue");
          this.reloadServices();
        }
      );
    }
  };

  getBundleServices = async () => {
    const bundleResponce = await listBundleServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("bundleResponce.data.data: ", bundleResponce.data.data);

    if (
      bundleResponce.data.status === 403 ||
      bundleResponce.data.errors === "authentication missing" ||
      bundleResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (bundleResponce.data.status === 404) {
      this.setState({
        tableDataBundle: [],
        tableLoaderBundle: false,
      });
    } else if (
      bundleResponce.data.status === 200 &&
      bundleResponce.data.message === "success"
    ) {
      this.setState({
        tableDataBundle: bundleResponce.data.data,
        tableLoaderBundle: false,
        columnBundleServices: [
          {
            name: "bundle_title",
            label: "Title",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "per_month_rate",
            label: "Per Month Rate (Charge Monthly)",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_price(value)}</div>;
              },
            },
          },
          {
            name: "per_year_rate",
            label: "Per Month Rate (Charge Yearly)",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_price(value)}</div>;
              },
            },
          },
          {
            name: "date_added",
            label: "Date Added",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "marketing",
            label: "Marketing",
            options: {
              customBodyRender: (value) => {
                return <div>{this.marketingLinkPackage(value)}</div>;
              },
            },
          },
          {
            name: "status",
            label: "Status",
            options: {
              customBodyRender: (value) => {
                return <div>{this.package_status(value)}</div>;
              },
            },
          },
          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                {/*<li>
                                  <Tooltip title="Edit Bundle" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.addMenuHistory(
                                          "/services/Editbundle/" + value
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Edit Bundle</span>
                                    </a>
                                  </Tooltip>
                                    </li>*/}
                                <li>
                                  <Tooltip
                                    title="Package Details"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.showBundleDetails(value)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-list"></em>
                                      <span>Package Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Edit Package Status"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.showUpdateStatus(value, tableMeta)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Update Status</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Assign agreement to the bundle"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-link-group"></em>
                                      <span>Assign Agreement</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      this.setState({
        tableDataBundle: [],
        tableLoaderBundle: false,
      });
    }
  };

  showUpdateStatus = async (value, tableMeta) => {
    console.log("showUpdateStatus ID: ", value + " " + tableMeta.rowData[4]);
    this.setState({
      package_id: value,
      package_status: tableMeta.rowData[4],
      statusSuccessMessage: "",
      statusErrorMessage: "",
    });
    window.$("#modalFormStatus").modal("show");
  };

  updateStatus = async () => {
    const { package_id, package_status } = this.state;
    let new_pkg_stauts = "";
    console.log("this.state.package_status: ", this.state.package_status);
    if (this.state.package_status === true) {
      new_pkg_stauts = "Y";
    } else {
      new_pkg_stauts = "N";
    }
    this.setState({
      disabled: true,
    });
    const statusResponce = await updatePackageStatus(
      auth.getAccount(),
      auth.getToken(),
      package_id,
      new_pkg_stauts
    );

    console.log("updateStatus: ", statusResponce.data);

    if (
      statusResponce.data.status === 403 ||
      statusResponce.data.errors === "authentication missing" ||
      statusResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      statusResponce.data.status === 404 &&
      statusResponce.data.message === "not_exists"
    ) {
      this.setState({
        statusErrorMessage: "Package does not exists.",
        disabled: false,
      });
    } else if (
      statusResponce.data.status === 200 &&
      statusResponce.data.message === "success"
    ) {
      this.setState({
        statusSuccessMessage: "Package status updated successfully.",
      });
      this.getBundleServices();
      setTimeout(() => {
        this.setState({
          statusSuccessMessage: "",
          statusErrorMessage: "",
          disabled: false,
        });
        window.$("#modalFormStatus").modal("hide");
      }, 3000);
    } else {
      this.setState({
        statusErrorMessage:
          "There is some error while updating the package status.",
        disabled: false,
      });
    }
  };

  agreementAlert = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    console.log("serviceId: ", id);
    this.setState({
      serviceId: id,
      assignSuccessMessage: "",
      assignErrorMessage: "",
    });
    const agreementResponce = await listServiceAgreements(
      auth.getAccount(),
      auth.getToken(),
      id
    );

    console.log("serviceAlert: ", agreementResponce.data);

    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        list_agreements: [],
        assignErrorMessage: "There are no agreements.",
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        emptyService: true,
        disabled: false,
        list_agreements: agreementResponce.data.data,
        assigned_agreements: agreementResponce.data.assigned,
      });
    } else {
      this.setState({
        list_agreements: [],
        assignErrorMessage: "There are no agreements.",
      });
    }
    window.$("#modalAssignService").modal("show");
  };

  assignAgreement = async () => {
    const { did_agreement, serviceId } = this.state;
    console.log("did_agreement: ", did_agreement);
    console.log("serviceId: ", serviceId);
    var checkedValues = $("input:radio.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();
    console.log("checkedValues: ", checkedValues);
    if (checkedValues.length === 0) {
      this.setState({
        assignErrorMessage: "Please choose the service.",
        assignSuccessMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
      const agreementResponce = await assignServiceToAgreements(
        auth.getAccount(),
        auth.getToken(),
        JSON.stringify(checkedValues),
        serviceId
      );

      console.log("status: ", agreementResponce.data);

      if (
        agreementResponce.data.status === 403 ||
        agreementResponce.data.errors === "authentication missing" ||
        agreementResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.note === "empty"
      ) {
        this.setState({
          assignErrorMessage: agreementResponce.data.message,
          assignSuccessMessage: "",
          disabled: false,
        });
      } else if (
        agreementResponce.data.status === 200 &&
        agreementResponce.data.message === "success"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage: "Agreement assigned successfully.",
          disabled: false,
        });
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalAssignService").modal("hide");
        }, 3000);
      } else {
        this.setState({
          assignErrorMessage:
            "There is some error while assigning the agreement.",
          assignSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
    }, 4000);
  };

  get_default_services = async () => {
    const servicesResponce = await listDefaultServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Service Data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        defaultServices: servicesResponce.data.data,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    this.get_default_services();
    const servicesResponce = await getService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("getService: ", servicesResponce.data.data);
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let mrc_percentage = (
        servicesResponce.data.data[0][5] *
        (servicesResponce.data.data[0][12] / 100)
      );
      mrc_percentage = this.formatRate(mrc_percentage);

      let rate_percentage = (
        servicesResponce.data.data[0][7] *
        (servicesResponce.data.data[0][14] / 100)
      );
      rate_percentage = this.formatRate(rate_percentage);

      let sms_in_percentage = (
        servicesResponce.data.data[0][10] *
        (servicesResponce.data.data[0][17] / 100)
      );
      sms_in_percentage = this.formatRate(sms_in_percentage);

      let sms_out_percentage = (
        servicesResponce.data.data[0][11] *
        (servicesResponce.data.data[0][18] / 100)
      );
      sms_out_percentage = this.formatRate(sms_out_percentage);

      this.setState({
        // serviceData: servicesResponce.data.data,
        serviceId: servicesResponce.data.data[0][0],
        update_nrc: servicesResponce.data.data[0][4],
        update_mrc: servicesResponce.data.data[0][5],
        update_rate: servicesResponce.data.data[0][7],
        service_title: servicesResponce.data.data[0][1],
        select_service: servicesResponce.data.data[0][8],
        service_type_edit: servicesResponce.data.data[0][6],
        sms_in: servicesResponce.data.data[0][10],
        sms_out: servicesResponce.data.data[0][11],
        product_desc: servicesResponce.data.data[0][3],
        agent_mrc_comission: servicesResponce.data.data[0][12],
        agent_nrc_comission: servicesResponce.data.data[0][13],
        agent_rate_comission: servicesResponce.data.data[0][14],
        sms_in_comission: servicesResponce.data.data[0][17],
        sms_out_comission: servicesResponce.data.data[0][18],
        default_mrc: this.formatRate(servicesResponce.data.data[0][15]),
        default_rate: this.formatRate(servicesResponce.data.data[0][16]),
        default_nrc: this.formatRate(servicesResponce.data.data[0][21]),
        default_sms_in: this.formatRate(servicesResponce.data.data[0][15]),
        default_sms_out: this.formatRate(servicesResponce.data.data[0][16]),
        product_summary: servicesResponce.data.data[0][54],
        rate_margin: servicesResponce.data.data[0][55],
        total_commission: servicesResponce.data.data[0][56],
        sms_in_percentage: "Calculated Commission: $" + sms_in_percentage,
        sms_out_percentage: "Calculated Commission: $" + sms_out_percentage,
        mrc_percentage: "Calculated Commission: $" + mrc_percentage,
        rate_percentage: "Calculated Commission: $" + rate_percentage,
        main_type: servicesResponce.data.data[0][23],
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
    }

    window.$("#modalForm").modal("show");
    //window.$("#modalRateDeck").modal("show");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit,
    sms_in,
    sms_out
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
      sms_in: sms_in,
      sms_out: sms_out,
    });
  };

  publishService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[0];
    let service_type = tableMeta.rowData[7];
    let nrc = tableMeta.rowData[3];
    let mrc = tableMeta.rowData[4];
    let main_type = tableMeta.rowData[23];

    this.setState({ errorMessage: "" });
    console.log("service_type: ", service_type);
    /*if (
      service_type === "az_term" ||
      service_type === "tfn_term" ||
      service_type === "teams"
    ) {
      this.setState({
        errorMessage:
          "Publishing this service type is not active. Please contact system admin.",
        waitMessage: "",
        disabled: true,
        successMessage: "",
      });
      window.$("#modalFormPublish").modal("show");
    } else {*/
    this.setState({
      errorMessage: "",
      waitMessage: "Please wait while we are publishing the service.",
      disabled: true,
      successMessage: "",
    });
    window.$("#modalFormPublish").modal("show");

    const saveResponce = await publishService(
      auth.getAccount(),
      auth.getToken(),
      id,
      service_id,
      service_type
    );
    console.log("publishService.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "ratedeck"
    ) {
      this.setState({
        errorMessage:
          "Publishing this service type is not active. Please contact system admin.",
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service has been published successfully.",
        disabled: false,
        waitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormPublish").modal("hide");
      }, 3000);
      this.reloadServices();
    } else {
      this.setState({
        errorMessage: "There is some error while publishing the service.",
        successMessage: "",
        disabled: false,
        waitMessage: "",
      });
      //window.location.replace("/error");
    }
    //}
  };

  unpublishService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[0];
    let service_type = tableMeta.rowData[7];
    

    this.setState({ unpublishErrorMessage: "" });
    console.log("service_type: ", service_type);
    
    this.setState({
      unpublishErrorMessage: "",
      unpublishWaitMessage: "Please wait while we are unpublishing the service.",
      disabled: true,
      unpublishSuccessMessage: "",
    });
    window.$("#modalFormUnPublish").modal("show");

    const saveResponce = await unpublishService(
      auth.getAccount(),
      auth.getToken(),
      id,
      service_id,
      service_type
    );
    console.log("unpublishService.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        unpublishErrorMessage: saveResponce.data.note,
        unpublishSuccessMessage: "",
        unpublishWaitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "ratedeck"
    ) {
      this.setState({
        unpublishErrorMessage:
          "Unpublishing this service type is not active. Please contact system admin.",
          unpublishSuccessMessage: "",
          unpublishWaitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        unpublishErrorMessage: "",
        unpublishSuccessMessage: "Service has been unpublished successfully.",
        disabled: false,
        unpublishWaitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormUnPublish").modal("hide");
      }, 3000);
      this.reloadServices();
    } else {
      this.setState({
        unpublishErrorMessage: "There is some error while unpublishing the service.",
        unpublishSuccessMessage: "",
        disabled: false,
        unpublishWaitMessage: "",
      });
      //window.location.replace("/error");
    }
    //}
  };

  stopAndShowService = async (serviceId, bundleId) => {
    window.$("#modalFormDetailBundle").modal("hide");
    this.showDetailsAgain(serviceId, bundleId);
  };

  stopAndShowBundle = async (serviceId, bundleId) => {
    window.$("#modalFormDetailService").modal("hide");
    this.showBundleDetails(bundleId);
  };

  showDetailsAgain = async (value, bundleId) => {
    let service_id = value;
    const servicesResponce = await getService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("getService: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        errorMessage: "",
        bundleId: bundleId,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailService").modal("show");
  };

  showDetails = async (value, tableMeta) => {
    this.setState({
      bundleId: "",
    });
    let service_id = tableMeta.rowData[0];
    const servicesResponce = await getService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("getService: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailService").modal("show");
  };

  showBundleDetails = async (value) => {
    let service_id = value;
    console.log("Bundle ID: ", service_id);
    const servicesResponce = await getBundleService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("showBundleDetails: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the bundle data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bundleServiceDetails: servicesResponce.data.bundleServices,
        bundleDetails: servicesResponce.data.bundleData,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the bundle data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailBundle").modal("show");
  };

  setDefaultService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[0];
    let service_type = tableMeta.rowData[7];
    let nrc = tableMeta.rowData[3];
    let mrc = tableMeta.rowData[4];
    let rate = tableMeta.rowData[6];

    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      waitMessage: "Please wait while we are making this service to default.",
      disabled: true,
      successMessage: "",
    });
    window.$("#modalFormDefault").modal("show");

    const saveResponce = await serviceDefaultUpdate(
      auth.getAccount(),
      auth.getToken(),
      id,
      service_id,
      service_type
    );
    console.log("saveResponce.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service has been changed to default successfully.",
        disabled: false,
        waitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDefault").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
        waitMessage: "",
      });
      //window.location.replace("/error");
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    let {
      update_nrc,
      update_mrc,
      service_title,
      service_type_edit,
      update_rate,
      sms_in,
      sms_out,
      product_desc,
      default_nrc,
      default_mrc,
      default_rate,
      default_sms_in,
      default_sms_out,
      agent_mrc_comission,
      agent_nrc_comission,
      agent_rate_comission,
      sms_in_comission,
      sms_out_comission,
    } = this.state;

    this.setState({
      rate_error: false,
      rate_error_text: "",
      nrc_error: false,
      nrc_error_text: "",
      mrc_error: false,
      mrc_error_text: "",
      sms_in_error: false,
      sms_in_error_text: "",
      sms_out_error: false,
      sms_out_error_text: "",
    });

    var is_validated = false;
    if (service_title === "") {
      is_validated = true;
    } else if (service_type_edit === "sms") {
      if (sms_in === "") {
        is_validated = true;
      } else {
        if (sms_in <= this.state.default_sms_in) {
          is_validated = true;
          this.setState({
            sms_in_error: true,
          });
        }
        if (sms_in > default_sms_in) {
          console.log(
            "sms_in - default_sms_in: ",
            sms_in + " " + default_sms_in
          );
          let sms_in_diff = sms_in - default_sms_in;
          let sms_in_percentage = (sms_in / 100) * sms_in_comission;
          console.log(
            "sms_in_diff - sms_in_percentage: ",
            sms_in_diff + " " + sms_in_percentage
          );
          if (sms_in_percentage > sms_in_diff) {
            is_validated = true;
            this.setState({
              sms_in_error: true,
              sms_in_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
      if (sms_out === "") {
        is_validated = true;
      } else {
        if (sms_out < this.state.default_sms_out) {
          is_validated = true;
          this.setState({
            sms_out_error: true,
          });
        }
        if (sms_out > default_sms_out) {
          let sms_out_diff = sms_out - default_sms_out;
          let sms_out_percentage = (sms_out / 100) * sms_out_comission;
          if (sms_out_percentage > sms_out_diff) {
            is_validated = true;
            this.setState({
              sms_out_error: true,
              sms_out_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (service_type_edit === "term") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        console.log("rate > defaultRate: ", update_rate + " " + default_rate);
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        if (update_rate > default_rate) {
          let term_diff = update_rate - default_rate;
          let term_percentage = (update_rate / 100) * agent_rate_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (service_type_edit === "tfn_term") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        console.log("rate > defaultRate: ", update_rate + " " + default_rate);
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        if (update_rate > default_rate) {
          let term_diff = update_rate - default_rate;
          let term_percentage = (update_rate / 100) * agent_rate_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (service_type_edit === "az_term") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        console.log("rate > defaultRate: ", update_rate + " " + default_rate);
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        if (update_rate > default_rate) {
          let term_diff = update_rate - default_rate;
          let term_percentage = (update_rate / 100) * agent_rate_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (service_type_edit === "ivr") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        if (update_rate > default_rate) {
          let term_diff = update_rate - default_rate;
          let term_percentage = (update_rate / 100) * agent_rate_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (service_type_edit === "live_agent") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        if (update_rate > default_rate) {
          let term_diff = update_rate - default_rate;
          let term_percentage = (update_rate / 100) * agent_rate_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    } else if (
      service_type_edit === "did" ||
      service_type_edit === "tfn" ||
      service_type_edit === "teams"
    ) {
      if (update_nrc === "") {
        is_validated = true;
      } else {
        if (update_nrc < this.state.default_nrc) {
          is_validated = true;
          this.setState({ nrc_error: true });
        }
        if (update_nrc > this.state.default_nrc) {
          let nrc_diff = update_nrc - this.state.default_nrc;
          let nrc_percentage = agent_nrc_comission;
          if (nrc_percentage > nrc_diff) {
            is_validated = true;
            this.setState({
              nrc_error: true,
              nrc_error_text:
                "Agent Spiff should be less than reseller margin.",
            });
          }
        }
      }
      if (agent_nrc_comission === "" || agent_nrc_comission === 0) {
        is_validated = true;
      } else {
        let nrc_diff = update_nrc - this.state.default_nrc;
        if (agent_nrc_comission > nrc_diff) {
          is_validated = true;
          this.setState({ nrc_comission_error: true });
        }
      }
      if (update_mrc === "") {
        is_validated = true;
      } else {
        if (update_mrc < this.state.default_mrc) {
          is_validated = true;
          this.setState({ mrc_error: true });
        }
        if (update_mrc > this.state.default_mrc) {
          let term_diff = update_mrc - this.state.default_mrc;
          let term_percentage = (update_mrc / 100) * agent_mrc_comission;
          if (term_percentage > term_diff) {
            is_validated = true;
            this.setState({
              mrc_error: true,
              mrc_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < this.state.default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
        console.log(
          "update_rate > this.state.default_rate: ",
          update_rate + " " + this.state.default_rate
        );
        if (update_rate > this.state.default_rate) {
          let rate_diff = this.formatRate(update_rate - this.state.default_rate);
          let rate_percentage = this.formatRate(
            (update_rate / 100) *
            agent_rate_comission
          );
          console.log(
            "rate_percentage > rate_diff: ",
            rate_percentage + " " + rate_diff
          );
          if (rate_percentage > rate_diff) {
            is_validated = true;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
    }
    /*else if (service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      }
      if (update_mrc === "") {
        is_validated = true;
      }
      if (update_rate === "") {
        is_validated = true;
      }
    }*/

    if (is_validated) {
      return this.setState({
        errorMessage:
          "Please enter the required fields or check the highlighted text fields.",
      });
    } else {
      this.setState({ errorMessage: "" });

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await serviceRatesUpdate(
        auth.getAccount(),
        auth.getToken(),
        update_nrc,
        update_mrc,
        update_rate,
        this.state.serviceId,
        service_title,
        service_type_edit,
        sms_in,
        sms_out,
        this.state.agent_mrc_comission,
        this.state.agent_nrc_comission,
        this.state.agent_rate_comission,
        this.state.default_mrc,
        this.state.default_rate,
        this.state.default_nrc,
        this.state.sms_in_comission,
        this.state.sms_out_comission,
        this.state.product_desc
      );

      console.log("status: ", saveResponce.data);
      //console.log("message: ", saveResponce.data.message);
      //console.log("note: ", saveResponce.data.note);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Rates Saved Successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
        //this.componentDidMount();
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetailService").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChangeDefault = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("handleChangeDefault: ", name + " " + value);
    let service_data = this.state.defaultServices;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: this.formatRate(service_data[i][3]),
          defaultMrc: this.formatRate(service_data[i][4]),
          rate_type: service_data[i][5],
          defaultRate: this.formatRate(service_data[i][5]),
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type_edit: service_data[i][9],
        });
      }
    }
  };

  downloadCsv = async (page_id) => {
    const reportsData = await getCSVData(
      auth.getAccount(),
      auth.getToken(),
      page_id
    );
    //console.log("reportsData.data.data: ", reportsData.data.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      const response = await fetch(url);
      const data = await response.text();
      const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = page_id;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  downloadMarketingPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      var url = d_f_name;
      const parts = url.split("/");
      let file_name = parts[parts.length - 1];
      console.log("url: ", url);

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  };

  formatRate = (rate) => {
    if (typeof rate !== "number") {
      rate = parseFloat(rate);
    }
    let decimals = '00';
    if(this.decimalPoint === 3){
      decimals = '000';
    } else if(this.decimalPoint === 4){
      decimals = '0000';
    } else if(this.decimalPoint === 5){
      decimals = '00000';
    } else if(this.decimalPoint === 6){
      decimals = '000000';
    }
    if (!rate) {
      return `0.${decimals}`
    }
    if (rate === 0) {
      return `0.${decimals}`
      //return "0.00";
    }

    

    const rateString = rate.toString();
    const decimalIndex = rateString.indexOf(".");
    console.log(
      "decimalIndex: " +
      decimalIndex +
        " this.rate: " +
        rate
    );
    if (decimalIndex === -1) {
      return `${rateString}.${decimals}`;
    }

    const decimalPlaces = rateString.length - decimalIndex - 1;

    let displayDecimalPlaces;
    if (decimalPlaces < 2) {
      displayDecimalPlaces = this.decimalPoint;
    } else if (decimalPlaces < this.decimalPoint) {
      displayDecimalPlaces = this.decimalPoint;
    } else {
      displayDecimalPlaces = this.decimalPoint;
      // Rounding only when decimal places exceed this.decimalPoint
      rate = parseFloat(rate.toFixed(this.decimalPoint + 1));
    }

    let formattedRate;
    
    if (displayDecimalPlaces <= 2) {
      formattedRate = parseFloat(rate).toFixed(2);
    } else {
      formattedRate = parseFloat(rate).toFixed(displayDecimalPlaces);
      //formattedRate = formattedRate.replace(/\.?0+$/, '');
    }

    return formattedRate;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  modalHideAssign = () => {
    window.$("#modalAssignService").modal("hide");
  };

  downloadDeck = async (d_f_name) => {
    if (d_f_name !== "") {
      var url = APP_LIVE_URL + "files_data/logos/" +d_f_name;
      const parts = url.split("/");
      let file_name = d_f_name;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/*
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Services" icon="icon ni ni-card-view" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div className="dropdown">
                        <a
                          href="#"
                          className="btn btn-large btn-primary"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>Add New Services</span>
                          <em className="icon ni ni-chevron-down"></em>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-auto mt-1">
                          <ul className="link-list-plain">
                            <li>
                              {this.props.defaultSettings !== "missing" ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.addMenuHistory("/services/Addservice")
                                  }
                                >
                                  <span>
                                    <em className="icon ni ni-plus"></em> Add
                                    New Flat Rate
                                  </span>
                                </a>
                              ) : null}
                            </li>
                            <li>
                              {this.props.defaultSettings !== "missing" ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.addMenuHistory("/services/Addratedeck")
                                  }
                                >
                                  <span>
                                    <em className="icon ni ni-plus"></em> Add
                                    New Rate Deck
                                  </span>
                                </a>
                              ) : null}
                            </li>
                            <li>
                              {this.props.defaultSettings !== "missing" ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.addMenuHistory("/services/Addbundle")
                                  }
                                >
                                  <span>
                                    <em className="icon ni ni-plus"></em> Add
                                    New Package
                                  </span>
                                </a>
                              ) : null}
                                </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                                </div>*/}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name={localization.productListing.heading}
                        icon="icon ni ni-card-view"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() =>
                                this.addMenuHistory("/services/Addservice")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus"></em> {localization.productListing.addNewButton}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <div className="row g-gs mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                  {localization.productListing.selectServiceType}{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.all_service_types}
                                    name="selected_service_type"
                                    placeholder={localization.productListing.selectServiceTypePlaceHolder}
                                    value={this.state.selected_service_type}
                                    autoComplete="off"
                                    emptyMessage={localization.productListing.selectServiceTypeEmpty}
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*<ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#tabItemServices"
                              >
                                <em className="icon ni ni-card-view"></em>
                                <span>Services</span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tabItemBundles"
                                onClick={() => this.getBundleServices()}
                              >
                                <em className="icon ni ni-link-group"></em>

                                <span>Packages</span>
                              </a>
                                </li>
                          </ul>*/}
                          <div className="tab-content">
                            <div
                              className="tab-pane active"
                              id="tabItemServices"
                            >
                              {this.state.mainError !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.mainError}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {/* START DATATABLE */}
                              {this.state.tableLoader === true ? (
                                tableLoader()
                              ) : (
                                <DataTableExtended
                                  columns={this.state.columnServices}
                                  tableData={this.state.tableData}
                                  title=""
                                />
                              )}

                              {/* END TABLE */}
                            </div>
                            <div className="tab-pane" id="tabItemBundles">
                              {this.state.tableLoaderBundle === true ? (
                                tableLoader()
                              ) : (
                                <>
                                  {this.state.tableDataBundle &&
                                  this.state.tableDataBundle.length > 0 ? (
                                    <DataTableExtended
                                      columns={this.state.columnBundleServices}
                                      tableData={this.state.tableDataBundle}
                                      title="All Packages"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/*  DETAILS MODAL*/}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetailService"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Product Details{" "}
                              {this.state.bundleId !== "" ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.stopAndShowBundle(this.state.bundleId)
                                  }
                                >
                                  {"   "}
                                  <span className="badge badge-outline-primary">
                                    <em className="icon ni ni-step-back"></em>{" "}
                                    Go Back
                                  </span>
                                </a>
                              ) : null}
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.serviceData ? (
                            <>
                              <div>
                                {this.state.serviceData.map((services, idx) => (
                                  <div
                                    className="card card-bordered"
                                    key={`servi${idx}`}
                                  >
                                    <div className="card-inner-group">
                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              {services[48] &&
                                              services[48] !== ""
                                                ? services[48]
                                                : null}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "Voice Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "cnam"
                                                  ? "CNAM"
                                                  : ""}
                                                {services[6] === "generic"
                                                  ? "Other Service"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>
                                          <div
                                            className="sp-plan-desc sp-plan-desc-mb"
                                            style={{
                                              marginBottom: "15px",
                                              borderBottom: "1px solid #f6f6f6",
                                              paddingBottom: "7px",
                                            }}
                                          >
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC Buy Rate{" "}
                                                    <Tooltip
                                                      title="NRC Buy Rate"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${this.formatRate(services[21])}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC Buy Rate
                                                    <Tooltip
                                                      title="MRC Buy Rate"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${this.formatRate(services[15])}
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" &&
                                              services[28] !== "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute Buy Rate
                                                      <Tooltip
                                                        title="Rate Per Minute Buy Rate"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[16])}
                                                  </p>
                                                </li>
                                              ) : null}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Per Session Buy Rate
                                                      <Tooltip
                                                        title="Per Session Buy Rate"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[32])}
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC
                                                    <Tooltip
                                                      title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Spiff
                                                    <Tooltip
                                                      title="Sales Performance Incentive Fund is an Incentive amount earned against a sale / subscription."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[4]} / $
                                                  {services[13]}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC{" "}
                                                    <Tooltip
                                                      title="Monthly Recurring Charge - what you pay each month for the service."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${this.formatRate(services[5])} / $
                                                  {this.formatRate(
                                                    (services[5] -
                                                      services[15]) *
                                                    (services[12] / 100)
                                                  )}{" "}
                                                  ({services[12]}%)
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" &&
                                              services[28] !== "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[7])} / $
                                                    {this.formatRate(
                                                      (services[7] -
                                                        services[16]) *
                                                      (services[14] / 100)
                                                    )}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Session{" "}
                                                      <Tooltip
                                                        title="Rate assigned per session."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[7])} / $
                                                    {this.formatRate(
                                                      (services[7] -
                                                        services[32]) *
                                                      (services[14] / 100)
                                                    )}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "term" ||
                                      services[6] === "ivr" ||
                                      services[6] === "live_agent" ||
                                      services[6] === "tfn_term" ||
                                      services[6] === "az_term" ||
                                      services[6] === "generic" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              {services[48] &&
                                              services[48] !== "" ? (
                                                <>({services[48]})</>
                                              ) : null}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {services[6] === "generic"
                                                  ? "Other Services"
                                                  : ""}
                                                {services[23] === "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>
                                          <div
                                            className="sp-plan-desc sp-plan-desc-mb"
                                            style={{
                                              marginBottom: "15px",
                                              borderBottom: "1px solid #f6f6f6",
                                              paddingBottom: "7px",
                                            }}
                                          >
                                            <ul className="row gx-1">
                                              {services[23] ===
                                              "ratedeck" ? null : (
                                                <>
                                                  {services[28] !==
                                                  "unmetered" ? (
                                                    <li className="col-sm-4">
                                                      <p>
                                                        <span className="text-soft">
                                                          {services[6] ===
                                                          "generic"
                                                            ? "Cost"
                                                            : "Per Minute Buy Rate"}{" "}
                                                          <Tooltip
                                                            title="Rate Per Minute Cost"
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>
                                                        </span>{" "}
                                                        $
                                                        {this.formatRate(services[16])}
                                                      </p>
                                                    </li>
                                                  ) : null}
                                                </>
                                              )}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      {services[6] === "generic"
                                                        ? "Price"
                                                        : "Rate Per Minute Cost"}{" "}
                                                      <Tooltip
                                                        title="Rate Per Minute Cost"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[32])}
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              {services[23] === "ratedeck" ? (
                                                <>
                                                  {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                </>
                                              ) : (
                                                <>
                                                  {services[28] !==
                                                  "unmetered" ? (
                                                    <li className="col-sm-4">
                                                      <p>
                                                        <span className="text-soft">
                                                          {services[6] ===
                                                          "generic"
                                                            ? "Price"
                                                            : "Rate Per Minute"}{" "}
                                                          <Tooltip
                                                            title="Rate assigned per minute."
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          / Margin{" "}
                                                          <Tooltip
                                                            title="Difference between cost and price."
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>
                                                          / Commission{" "}
                                                          <Tooltip
                                                            title="Commission amount for agent, taken out of profit."
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>
                                                        </span>{" "}
                                                        $
                                                        {this.formatRate(
                                                          services[7]
                                                        )}{" "}
                                                        / $
                                                        {this.formatRate(
                                                          services[55]
                                                        )}{" "}
                                                        / $
                                                        {this.formatRate(
                                                          (services[7] -
                                                            services[16]) *
                                                          (services[14] / 100)
                                                        )}{" "}
                                                        ({services[14]}%)
                                                      </p>
                                                    </li>
                                                  ) : null}
                                                </>
                                              )}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Per Session Sell Rate
                                                      <Tooltip
                                                        title="Per Session Buy Rate"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${this.formatRate(services[7])} / $
                                                    {this.formatRate(
                                                      (services[7] -
                                                        services[16]) *
                                                      (services[14] / 100)
                                                    )}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                          {services[6] === "generic" ? (
                                            <>
                                              <div
                                                className="sp-plan-head"
                                                style={{
                                                  marginTop: "15px",
                                                  borderTop:
                                                    "1px solid #f6f6f6",
                                                  paddingTop: "15px",
                                                }}
                                              >
                                                <h6 className="title">
                                                  {localization.productListing.detailsPopupOtherServicesHead}
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <table className="table">
                                                  <thead className="thead-light">
                                                    <tr>
                                                      <th scope="col">Title</th>
                                                      <th scope="col">
                                                        Charge Type
                                                      </th>
                                                      <th scope="col">Cycle</th>
                                                      <th scope="col">Price</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {services[46].map(
                                                      (miscrate, idx) => (
                                                        <tr key={`mis${idx}`}>
                                                          <td>
                                                            {
                                                              miscrate.generic_title
                                                            }
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                            "recurring"
                                                              ? "Recurring"
                                                              : ""}

                                                            {miscrate.pay_type ===
                                                            "one_time"
                                                              ? "One Time"
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {miscrate.pay_type ===
                                                            "recurring" ? (
                                                              <>
                                                                {miscrate.generic_type ===
                                                                "per_minute"
                                                                  ? "Per Minute"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                "per_hour"
                                                                  ? "Per Hour"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                "per_second"
                                                                  ? "Per Second"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                "per_day"
                                                                  ? "Per Day"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                "per_month"
                                                                  ? "Per Month"
                                                                  : ""}
                                                                {miscrate.generic_type ===
                                                                "per_year"
                                                                  ? "Per Year"
                                                                  : ""}
                                                              </>
                                                            ) : null}
                                                          </td>
                                                          <td>
                                                            ${this.formatRate(miscrate.generic_rate)}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                      {services[6] === "sms" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              {services[48] &&
                                              services[48] !== "" ? (
                                                <>({services[48]})</>
                                              ) : null}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>
                                          <div
                                            className="sp-plan-desc sp-plan-desc-mb"
                                            style={{
                                              marginBottom: "15px",
                                              borderBottom: "1px solid #f6f6f6",
                                              paddingBottom: "7px",
                                            }}
                                          >
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In Buy Rate{" "}
                                                    <Tooltip
                                                      title="Cost for SMS IN Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  
                                                  {this.formatRate(services[26])}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out Buy Rate{" "}
                                                    <Tooltip
                                                      title="Cost for SMS Out Services"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[27])}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS In Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${this.formatRate(services[10])} / $
                                                  {this.formatRate(
                                                    (services[10] -
                                                      services[15]) *
                                                    (services[17] / 100)
                                                  )}{" "}
                                                  ({services[17]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS Out Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${this.formatRate(services[11])} / $
                                                  {this.formatRate(
                                                    (services[11] -
                                                      services[16]) *
                                                    (services[18] / 100)
                                                  )}{" "}
                                                  ({services[18]}%)
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}

                                      {/* CNAM */}
                                      {services[6] === "cnam" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              {services[48] &&
                                              services[48] !== "" ? (
                                                <>({services[48]})</>
                                              ) : null}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>
                                          <div
                                            className="sp-plan-desc sp-plan-desc-mb"
                                            style={{
                                              marginBottom: "15px",
                                              borderBottom: "1px solid #f6f6f6",
                                              paddingBottom: "7px",
                                            }}
                                          >
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Update CNAM (MRC) Buy Rate{" "}
                                                    <Tooltip
                                                      title="Cost for Update CNAM (MRC)."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[36])}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    CNAM DIP Buy Rate{" "}
                                                    <Tooltip
                                                      title="Cost for CNAM DIP"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[37])}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Storage DIP Buy Rate{" "}
                                                    <Tooltip
                                                      title="Cost for Storage DIP"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[38])}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Update CNAM (MRC){" "}
                                                    <Tooltip
                                                      title="Price applied to Update CNAM (MRC)."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[33])}{" "}
                                                  / $
                                                  {this.formatRate(
                                                    (services[33] -
                                                      services[36]) *
                                                    (services[39] / 100)
                                                  )}{" "}
                                                  ({services[39]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    CNAM DIP{" "}
                                                    <Tooltip
                                                      title="Price applied to CNAM DIP."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[34])}{" "}
                                                  / $
                                                  {this.formatRate(
                                                    (services[34] -
                                                      services[37]) *
                                                    (services[41] / 100)
                                                  )}{" "}
                                                  ({services[41]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Storage DIP{" "}
                                                    <Tooltip
                                                      title="Price applied to Storage DIP."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {this.formatRate(services[35])}{" "}
                                                  / $
                                                  {this.formatRate(
                                                    (services[35] -
                                                      services[38]) *
                                                    (services[40] / 100)
                                                  )}{" "}
                                                  ({services[40]}%)
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {/* CNAM */}

                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <>
                                          {services[30] &&
                                          services[30].length > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Porting Rates
                                                </h6>
                                              </div>

                                              <div
                                                className="sp-plan-desc sp-plan-desc-mb"
                                                style={{
                                                  marginBottom: "15px",
                                                  borderBottom:
                                                    "1px solid #f6f6f6",
                                                  paddingBottom: "7px",
                                                }}
                                              >
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Buy Rate
                                                      </span>{" "}
                                                      $
                                                      {this.formatRate(services[30][0].portin_default_rate)}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portout Buy Rate
                                                      </span>{" "}
                                                      $
                                                      {this.formatRate(services[30][0].portout_default_rate)}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {this.formatRate(services[30][0].portin_sell_rate)}{" "}
                                                      / $
                                                      {this.formatRate(services[30][0].portin_agent_spiff)}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {this.formatRate(services[30][0].portout_sell_rate)}{" "}
                                                      / $
                                                      {this.formatRate(services[30][0].portout_agent_spiff)}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      <div className="card-inner">
                                        {services[23] !== "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                              {localization.productListing.detailsPopupCancellationHead}
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                    {localization.productListing.detailsPopupCancellationText}
                                                    </span>{" "}
                                                    
                                                        ${this.formatRate(services[29])}
                                                      
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                            {services[54] && services[54] !== "" ? (
                                              <>
                                            <div className="sp-plan-head" style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}>
                                              <h6 className="title">
                                              {localization.productListing.detailsPopupSummary}
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-12">
                                                  <p>
                                                    
                                                    {services[54]}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                            </>
                                            ) : null}
                                          </>
                                        ) : null}
                                        {services[23] === "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Rate Deck / Effective Date
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Deck
                                                    </span>{" "}
                                                    {services[25] &&
                                                    services[25] !== "" ? (
                                                      <>
                                                        <a
                                                          onClick={() =>
                                                            this.downloadCsv(
                                                              services[25]
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="btn btn-outline-primary"
                                                        >
                                                          <em className="icon ni ni-download"></em>{" "}
                                                          Download Rate Deck
                                                        </a>
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Effective Date
                                                    </span>{" "}
                                                    {services[24] &&
                                                    services[24] !== "" ? (
                                                      <>
                                                        {this.format_date(
                                                          services[24]
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[31] &&
                                        services[31].length > 0 ? (
                                          <>
                                            <div
                                              className="sp-plan-head"
                                              style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              <h6 className="title">
                                              {localization.productListing.detailsPopupMiscHead}
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">
                                                      Rate Type
                                                    </th>
                                                    <th scope="col">Rate</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {services[31].map(
                                                    (miscrate, idx) => (
                                                      <tr key={`mis${idx}`}>
                                                        <td>
                                                          {miscrate.misc_title}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_type ===
                                                          "per_minute"
                                                            ? "Per Minute"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "per_hour"
                                                            ? "Per Hour"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "one_time"
                                                            ? "One Time"
                                                            : ""}
                                                        </td>
                                                        <td>
                                                          ${this.formatRate(miscrate.misc_rate)}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                          {localization.productListing.detailsPopupDatesHead}
                                          </h6>
                                        </div>
                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                {localization.productListing.detailsPopupDatesAdd}
                                                </span>{" "}
                                                {services[19] !== ""
                                                  ? this.format_date(
                                                      services[19]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                {localization.productListing.detailsPopupDatesUpdate}
                                                </span>{" "}
                                                {services[20] !== ""
                                                  ? this.format_date(
                                                      services[20]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      {services[42] &&
                                      services[42] === "link" ? (
                                        <>
                                          {services[43] !== "" ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                {localization.productListing.detailsPopupMarketingHead}
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                      {localization.productListing.detailsPopupMarketingText}
                                                      </span>{" "}
                                                      <a
                                                        href={services[43]}
                                                        target="_blank"
                                                      >
                                                        <em className="icon ni ni-link-h"></em>{" "}
                                                        {localization.productListing.detailsPopupMarketingLinkOpen}
                                                      </a>
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      {services[42] &&
                                      services[42] === "pdf" ? (
                                        <>
                                          {services[43] !== "" ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                {localization.productListing.detailsPopupMarketingHead}
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                      {localization.productListing.detailsPopupMarketingText}
                                                      </span>{" "}
                                                      <a
                                                        href={services[43]}
                                                        target="_blank"
                                                      >
                                                        <em className="icon ni ni-file-pdf"></em>{" "}
                                                        {localization.productListing.detailsPopupMarketingLinkView}
                                                      </a>
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}

                                    {services[51] && services[51] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Document / Rate Deck{" "}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                    <br/>
                                                  <a
                                                    onClick={() => this.downloadDeck(services[51])}
                                                    style={{cursor:"pointer"}}
                                                    className="text-primary"
                                                  >
                                                    <em class="icon ni ni-download"></em>{" "}
                                                    {services[50]}
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[52] && services[52] !== "" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                              {localization.productListing.detailsPopupIntegration}{" "}
                                              </h6>
                                              <span className="ff-italic text-soft">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: services[53],
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}

                                      <div className="card-inner">
                                        <div className="sp-plan-head-group">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                            {localization.productListing.detailsPopupDescriptionHead}
                                            </h6>
                                            <span className="ff-italic text-soft">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: services[3],
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetailBundle"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Package Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.bundleDetails &&
                          this.state.bundleDetails.length > 0 ? (
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                <div className="card-inner">
                                  <div className="sp-plan-head">
                                    <h6 className="title">
                                      {this.state.bundleDetails[0].bundle_title}
                                    </h6>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Monthly){" "}
                                            <Tooltip
                                              title="Per Month Rate (Charge Monthly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.formatRate(this.state.bundleDetails[0].per_month_rate)}
                                        </p>
                                      </li>
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Yearly)
                                            <Tooltip
                                              title="Per Month Rate (Charge Yearly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.formatRate(this.state.bundleDetails[0].per_year_rate)}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Date Added
                                            <Tooltip
                                              title="Date Added"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                          </span>{" "}
                                          {this.state.bundleDetails[0]
                                            .date_added !== ""
                                            ? this.format_date(
                                                this.state.bundleDetails[0]
                                                  .date_added
                                              )
                                            : null}
                                        </p>
                                      </li>
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Date Updated{" "}
                                            <Tooltip
                                              title="Date Updated"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                          </span>{" "}
                                          {this.state.bundleDetails[0]
                                            .date_updated !== ""
                                            ? this.format_date(
                                                this.state.bundleDetails[0]
                                                  .date_updated
                                              )
                                            : null}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  {this.state.bundleDetails[0]
                                    .marketing_content !== "" &&
                                  this.state.bundleDetails[0]
                                    .marketing_content === "link" ? (
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-6">
                                          <p>
                                            <span className="text-soft">
                                              Marketing Link
                                              <Tooltip
                                                title="Marketing Link"
                                                placement="top"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                            </span>{" "}
                                            <a
                                              href={
                                                this.state.bundleDetails[0]
                                                  .marketing_link !== ""
                                                  ? this.state.bundleDetails[0]
                                                      .marketing_link
                                                  : null
                                              }
                                              target="_blank"
                                            >
                                              <em className="icon ni ni-link-h"></em>{" "}
                                              Click to open link
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                  {this.state.bundleDetails[0]
                                    .marketing_content !== "" &&
                                  this.state.bundleDetails[0]
                                    .marketing_content === "pdf" ? (
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-6">
                                          <p>
                                            <span className="text-soft">
                                              Marketing PDF{" "}
                                              <Tooltip
                                                title="Marketing PDF"
                                                placement="top"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                            </span>{" "}
                                            <a
                                              href={
                                                this.state.bundleDetails[0]
                                                  .marketing_link !== ""
                                                  ? this.state.bundleDetails[0]
                                                      .marketing_link
                                                  : null
                                              }
                                              target="_blank"
                                            >
                                              <em className="icon ni ni-file-pdf"></em>{" "}
                                              View PDF
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                  {this.state.bundleDetails[0].product_desc !==
                                  "" ? (
                                    <div
                                      className="sp-plan-desc sp-plan-desc-mb"
                                      style={{
                                        marginTop: "15px",
                                        borderTop:
                                          "1px solid rgb(246, 246, 246)",
                                        paddingTop: "7px",
                                      }}
                                    >
                                      <ul className="row gx-1">
                                        <li className="col-sm-6">
                                          <p>
                                            <span className="text-soft">
                                              Package Description
                                              <Tooltip
                                                title="Package Description"
                                                placement="top"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                            </span>{" "}
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  this.state.bundleDetails[0]
                                                    .product_desc,
                                              }}
                                            />
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <h4
                            style={{ marginTop: "20px", mrginBottom: "20px" }}
                          >
                            Assigned Services
                          </h4>

                          {this.state.bundleServiceDetails &&
                          this.state.bundleServiceDetails.length > 0 ? (
                            <>
                              {this.state.bundleServiceDetails.map(
                                (bundle_services, idk) => (
                                  <div
                                    className="nk-block"
                                    key={`bundle${idk}`}
                                  >
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        <div className="nk-help">
                                          <div className="nk-help-text">
                                            <h5>
                                              {bundle_services.service_title}
                                              {"  "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {bundle_services.service_type ===
                                                "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "cnam"
                                                  ? "CNAM"
                                                  : ""}
                                                {bundle_services.main_type ===
                                                "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h5>
                                            <p className="text-soft"></p>
                                          </div>
                                          <div className="nk-help-action">
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.stopAndShowService(
                                                  bundle_services.id,
                                                  this.state.bundleDetails[0].id
                                                )
                                              }
                                              className="btn btn-lg btn-outline-primary"
                                            >
                                              Service Details
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDefault"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Set Default</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}
                  {/* DEFAULT MODAL START */}
                  <div class="modal fade" tabIndex="-1" id="modalFormStatus">
                    <div
                      class="modal-dialog modal-lg modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Update Package Status</h5>
                        </div>
                        <div class="modal-body">
                          {this.state.statusErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.statusErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.statusSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.statusSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <p>
                            Active package will be available for the agents. If
                            you change the status of package to inactive it can
                            effect the rates in switching portal.
                          </p>
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="This is the status of the package. Active package will be visible for agents."
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Status <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input vdrSelected3"
                                    id="package_status"
                                    name="package_status"
                                    checked={this.state.package_status}
                                    value="1"
                                    onChange={this.handleChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="package_status"
                                  >
                                    {this.state.package_status
                                      ? "Active"
                                      : "Inactive"}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="modal-footer bg-light"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.updateStatus();
                            }}
                            disabled={this.state.disabled}
                          >
                            Update Status
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* DEFAULT MODAL End */}
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormPublish"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">
                              Publish Service / Product
                            </h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}

                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormUnPublish"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">
                              Unpublish Service / Product
                            </h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.unpublishErrorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.unpublishErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.unpublishSuccessMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.unpublishSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.unpublishWaitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.unpublishWaitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}
                  {/*  START MARKUP RATES MODAL*/}
                  <div
                    className="modal fade zoom edit_service_modal"
                    tabIndex="-1"
                    id="modalForm"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-edit"></em> Edit Service
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.errorMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.main_type === "ratedeck" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h4>NOTE</h4>
                                  <p>
                                    You can add new rate deck it will override
                                    the previous one.
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                          {/* NRC DIVS START */}
                          {this.state.main_type === "simple" ? (
                            <>
                              <div className="row g-gs">
                                <div className="col-sm-7  col-lg-7">
                                  <div className="form-group">
                                    <MutextField
                                      id="service_title"
                                      name="service_title"
                                      label="Service Title *"
                                      variant="outlined"
                                      fullWidth
                                      value={this.state.service_title}
                                      onChange={this.handleChange}
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-5  col-sm-5">
                                  <div className="form-group">
                                    <MutextField
                                      id="select_service"
                                      name="select_service"
                                      select
                                      label="Select Service"
                                      value={this.state.select_service}
                                      onChange={this.handleChangeDefault}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText="Default service list."
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                    >
                                      <option key="" value=""></option>
                                      {this.state.defaultServices.map(
                                        (services) => (
                                          <option value={services[0]}>
                                            {services[1]}
                                          </option>
                                        )
                                      )}
                                    </MutextField>
                                  </div>
                                </div>
                              </div>
                              {this.state.service_type_edit !== "sms" ? (
                                <>
                                  <div
                                    className="row g-gs"
                                    style={{ marginTop: "20px" }}
                                  >
                                    {this.state.service_type_edit !== "term" &&
                                    this.state.service_type_edit !== "ivr" &&
                                    this.state.service_type_edit !==
                                      "live_agent" &&
                                    this.state.service_type_edit !==
                                      "tfn_term" &&
                                    this.state.service_type_edit !==
                                      "az_term" ? (
                                      <>
                                        <div className="col-lg-4 col-sm-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="default_mrc"
                                              name="default_mrc"
                                              label="MRC Buy Rate"
                                              value={this.state.default_mrc}
                                              placeholder="Default MRC"
                                              onChange={this.handleChange}
                                              type="text"
                                              onKeyPress={() => {
                                                this.checkNumber();
                                              }}
                                              disabled
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="update_mrc"
                                              name="update_mrc"
                                              label="MRC Sell Rate *"
                                              value={this.state.update_mrc}
                                              placeholder="MRC Sell Rate"
                                              onChange={this.handleChange}
                                              type="text"
                                              onKeyPress={() => {
                                                this.checkNumber();
                                              }}
                                              variant="outlined"
                                              helperText={
                                                this.state.mrc_error_text
                                              }
                                              error={this.state.mrc_error}
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="agent_mrc_comission"
                                              name="agent_mrc_comission"
                                              select
                                              label="Agent Commission"
                                              value={
                                                this.state.agent_mrc_comission
                                              }
                                              onChange={this.handleChange}
                                              SelectProps={{
                                                native: true,
                                              }}
                                              helperText={
                                                this.state.mrc_percentage
                                              }
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="5" value="5">
                                                5%
                                              </option>
                                              <option key="10" value="10">
                                                10%
                                              </option>
                                              <option key="15" value="15">
                                                15%
                                              </option>
                                              <option key="20" value="20">
                                                20%
                                              </option>
                                              <option key="25" value="25">
                                                25%
                                              </option>
                                              <option key="30" value="30">
                                                30%
                                              </option>
                                              <option key="35" value="35">
                                                35%
                                              </option>
                                              <option key="40" value="40">
                                                40%
                                              </option>
                                              <option key="45" value="45">
                                                45%
                                              </option>
                                              <option key="50" value="50">
                                                50%
                                              </option>
                                            </MutextField>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="default_nrc"
                                              name="default_nrc"
                                              label="NRC Buy Rate"
                                              value={this.state.default_nrc}
                                              placeholder="Default NRC"
                                              onChange={this.handleChange}
                                              type="text"
                                              onKeyPress={() => {
                                                this.checkNumber();
                                              }}
                                              disabled
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="update_nrc"
                                              name="update_nrc"
                                              label="NRC Sell Rate *"
                                              value={this.state.update_nrc}
                                              placeholder="NRC Sell Rate"
                                              onChange={this.handleChange}
                                              type="text"
                                              onKeyPress={() => {
                                                this.checkNumber();
                                              }}
                                              variant="outlined"
                                              error={this.state.nrc_error}
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm-4 col-lg-4">
                                          <div className="form-group">
                                            <MutextField
                                              id="agent_nrc_comission"
                                              name="agent_nrc_comission"
                                              label="Agent Spiff"
                                              value={
                                                this.state.agent_nrc_comission
                                              }
                                              placeholder="NRC"
                                              onChange={this.handleChange}
                                              type="text"
                                              onKeyPress={() => {
                                                this.checkNumber();
                                              }}
                                              variant="outlined"
                                              error={
                                                this.state.nrc_comission_error
                                              }
                                              helperText={
                                                this.state.nrc_error_text
                                              }
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="col-lg-4 col-sm-4">
                                      <div className="form-group">
                                        <MutextField
                                          id="default_rate"
                                          name="default_rate"
                                          label="Per Minute Buy Rate"
                                          value={this.state.default_rate}
                                          placeholder="Default Rate"
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          disabled
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-lg-4">
                                      <div className="form-group">
                                        <MutextField
                                          id="update_rate"
                                          name="update_rate"
                                          label="Per Minute Sell Rate *"
                                          value={this.state.update_rate}
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          helperText={
                                            this.state.rate_error_text
                                          }
                                          variant="outlined"
                                          error={this.state.rate_error}
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-lg-4">
                                      <div className="form-group">
                                        <MutextField
                                          id="agent_rate_comission"
                                          name="agent_rate_comission"
                                          select
                                          label="Agent Commission"
                                          value={
                                            this.state.agent_rate_comission
                                          }
                                          onChange={this.handleChange}
                                          SelectProps={{
                                            native: true,
                                          }}
                                          helperText={
                                            this.state.rate_percentage
                                          }
                                          variant="outlined"
                                          fullWidth
                                          error={
                                            this.state.rate_comission_error
                                          }
                                        >
                                          <option key="5" value="5">
                                            5%
                                          </option>
                                          <option key="10" value="10">
                                            10%
                                          </option>
                                          <option key="15" value="15">
                                            15%
                                          </option>
                                          <option key="20" value="20">
                                            20%
                                          </option>
                                          <option key="25" value="25">
                                            25%
                                          </option>
                                          <option key="30" value="30">
                                            30%
                                          </option>
                                          <option key="35" value="35">
                                            35%
                                          </option>
                                          <option key="40" value="40">
                                            40%
                                          </option>
                                          <option key="45" value="45">
                                            45%
                                          </option>
                                          <option key="50" value="50">
                                            50%
                                          </option>
                                        </MutextField>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {this.state.service_type_edit === "sms" ? (
                                <>
                                  <div
                                    className="row row-gs"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="col-lg-12 col-sm-12">
                                      <h6>SMS Settings </h6>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="default_sms_in"
                                          name="default_sms_in"
                                          label="Default SMS Inbound Rate *"
                                          value={this.state.default_sms_in}
                                          placeholder="SMS Inbound Rate"
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          disabled
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="sms_in"
                                          name="sms_in"
                                          label="SMS Inbound Rate *"
                                          value={this.state.sms_in}
                                          placeholder="SMS Inbound Rate"
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          variant="outlined"
                                          error={this.state.sms_in_error}
                                          helperText={
                                            this.state.sms_in_error_text
                                          }
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="sms_in_comission"
                                          name="sms_in_comission"
                                          select
                                          label="Agent Commission"
                                          value={this.state.sms_in_comission}
                                          onChange={this.handleChange}
                                          SelectProps={{
                                            native: true,
                                          }}
                                          helperText={
                                            this.state.sms_in_percentage
                                          }
                                          variant="outlined"
                                          fullWidth
                                        >
                                          <option key="5" value="5">
                                            5%
                                          </option>
                                          <option key="10" value="10">
                                            10%
                                          </option>
                                          <option key="15" value="15">
                                            15%
                                          </option>
                                          <option key="20" value="20">
                                            20%
                                          </option>
                                          <option key="25" value="25">
                                            25%
                                          </option>
                                          <option key="30" value="30">
                                            30%
                                          </option>
                                          <option key="35" value="35">
                                            35%
                                          </option>
                                          <option key="40" value="40">
                                            40%
                                          </option>
                                          <option key="45" value="45">
                                            45%
                                          </option>
                                          <option key="50" value="50">
                                            50%
                                          </option>
                                        </MutextField>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="default_sms_out"
                                          name="default_sms_out"
                                          label="Default SMS Outbound Rate *"
                                          value={this.state.default_sms_out}
                                          placeholder="Default SMS Outbound Rate"
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          disabled
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="sms_out"
                                          name="sms_out"
                                          label="SMS Outbound Rate *"
                                          value={this.state.sms_out}
                                          placeholder="SMS Outbound Rate"
                                          onChange={this.handleChange}
                                          type="text"
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          variant="outlined"
                                          error={this.state.sms_out_error}
                                          helperText={
                                            this.state.sms_out_error_text
                                          }
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 mt-lg-gs">
                                      <div className="form-group">
                                        <MutextField
                                          id="sms_out_comission"
                                          name="sms_out_comission"
                                          select
                                          label="Agent Commission"
                                          value={this.state.sms_out_comission}
                                          onChange={this.handleChange}
                                          SelectProps={{
                                            native: true,
                                          }}
                                          helperText={
                                            this.state.sms_out_percentage
                                          }
                                          variant="outlined"
                                          fullWidth
                                        >
                                          <option key="5" value="5">
                                            5%
                                          </option>
                                          <option key="10" value="10">
                                            10%
                                          </option>
                                          <option key="15" value="15">
                                            15%
                                          </option>
                                          <option key="20" value="20">
                                            20%
                                          </option>
                                          <option key="25" value="25">
                                            25%
                                          </option>
                                          <option key="30" value="30">
                                            30%
                                          </option>
                                          <option key="35" value="35">
                                            35%
                                          </option>
                                          <option key="40" value="40">
                                            40%
                                          </option>
                                          <option key="45" value="45">
                                            45%
                                          </option>
                                          <option key="50" value="50">
                                            50%
                                          </option>
                                        </MutextField>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* MRC DIVS END */}
                              <div className="form-group  mt-lg-gs">
                                <h6>Short Description </h6>
                              </div>
                              <div className="form-group  mt-lg-gs">
                                <TextareaAutosize
                                  maxrows={4}
                                  name="product_desc"
                                  id="product_desc"
                                  aria-label=""
                                  placeholder=""
                                  value={this.state.product_desc}
                                  onChange={this.handleChange}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderColor: "rgba(0, 0, 0, 0.125)",
                                    borderRadius: "4px",
                                  }}
                                  variant="outlined"
                                />
                              </div>
                              <br />
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-lg"
                                  onClick={() => {
                                    this.formSave();
                                  }}
                                  style={{
                                    backgroundColor: THEME_COLOR,
                                    color: THEME_TEXT_COLOR,
                                    float: "right",
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  Apply
                                </button>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* MARKUP RATES MODAL */}
                  {/*### Assign Service Modal ###*/}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalAssignService"
                  >
                    <div
                      className="modal-dialog modal-lg modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideAssign();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em className="icon ni ni-link-group"></em> Assign
                            agreement to the service
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.assignErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.assignErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.assignSuccessMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.assignSuccessMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div
                            className="col-lg-12 col-md-12 col-xxl-12"
                            style={{ marginBottom: "30px" }}
                          >
                            <div
                              id="accordion-2"
                              className="accordion accordion-s3"
                            >
                              <div className="accordion-item">
                                <a
                                  href="#"
                                  className="accordion-head"
                                  data-toggle="collapse"
                                  data-target="#accordion-item-2-1"
                                  style={{ borderBottom: "1px solid #dbdfea" }}
                                >
                                  <h6 className="title">
                                    Select agreement to assign to the service
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse show"
                                  id="accordion-item-2-1"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    {this.state.list_agreements ? (
                                      <>
                                        <div className="col-lg-12 col-md-12">
                                          <div className="form-group select_services">
                                            <label className="form-label">
                                              Select Agreement
                                            </label>
                                            <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                              {this.state.list_agreements.map(
                                                (splits, index) => (
                                                  <li key={`addser${index}`}>
                                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                      <input
                                                        type="radio"
                                                        className="custom-control-input vdrSelected"
                                                        id={`cp1-package-${index}`}
                                                        name="did_agreement"
                                                        defaultChecked={false}
                                                        value={splits[2]}
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        ref="did_agreement"
                                                        required=""
                                                      />
                                                      <label
                                                        className="custom-control-label"
                                                        htmlFor={`cp1-package-${index}`}
                                                      >
                                                        <span className="d-flex align-center">
                                                          <span className="user-avatar sq bg-primary-dim">
                                                            <span className="icon ni ni-briefcase"></span>
                                                          </span>
                                                          <span className="ms-2">
                                                            <span className="lead-text">
                                                              {splits[1]}
                                                            </span>
                                                            <span className="sub-text"></span>
                                                          </span>
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          className="form-group text-right col-md-12"
                                          style={{ marginTop: "20px" }}
                                        >
                                          <button
                                            className="btn btn-lg btn-primary"
                                            type="button"
                                            disabled={this.state.disabled}
                                            onClick={() => {
                                              this.assignAgreement();
                                            }}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <a
                                  href="#"
                                  className="accordion-head collapsed"
                                  data-toggle="collapse"
                                  data-target="#accordion-item-2-2"
                                  style={{ borderBottom: "1px solid #dbdfea" }}
                                >
                                  <h6 className="title">
                                    Already Assigned Agreement
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </a>
                                <div
                                  className="accordion-body collapse"
                                  id="accordion-item-2-2"
                                  data-parent="#accordion-2"
                                >
                                  <div className="accordion-inner">
                                    {this.state.assigned_agreements.length >
                                    0 ? (
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th scope="col">Service</th>
                                            <th scope="col">Date Assigned</th>
                                            <th scope="col">Update Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.assigned_agreements.map(
                                            (sel_ser, inde) => (
                                              <tr key={`sel_ser${inde}`}>
                                                <td>{sel_ser[0]}</td>
                                                <td>
                                                  {sel_ser[1] !== "" ? (
                                                    <>
                                                      {dateFormat(
                                                        sel_ser[1],
                                                        "mmmm dS, yyyy, h:MM:ss TT"
                                                      )}
                                                    </>
                                                  ) : null}
                                                </td>
                                                <td>
                                                  {sel_ser[2] !== "" ? (
                                                    <>
                                                      {dateFormat(
                                                        sel_ser[2],
                                                        "mmmm dS, yyyy, h:MM:ss TT"
                                                      )}
                                                    </>
                                                  ) : null}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>Note</h4>
                                            <p>
                                              Agreement not assigned to this
                                              service.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*### Assign Service Modal ###*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
