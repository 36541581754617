import React from "react";
import { v4 as uuid } from "uuid";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FormRenderer from "./formRender";
import MutextField from "@material-ui/core/TextField";
import axios from "axios";

const useStyles = (theme) => ({
  formContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "100%",
    margin: "auto",
    marginTop: "1rem",
  },
  questionContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #dbdfea",
    paddingBottom: "1rem"

  },
  questionTop: {
    display: "flex",
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
  },
});

class FormBuilderClass extends React.Component {
  constructor(props) {
    super(props);
    let form_content = this.props.form_content;
    let form_title =
      this.props.form_title && this.props.form_title !== ""
        ? this.props.form_title
        : "";
    if (!form_content || form_content === "" || form_content.length <= 0) {
      form_content = [
        {
          title: "",
          id: uuid(),
          questions: [
            {
              id: uuid(),
              questionText: "",
              answerType: "",
              required: false,
              options: [],
              imageUrl: "",
            },
          ],
          errorMessage: "",
        },
      ];
    }
    this.state = {
      form_title: form_title,
      forms: form_content,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.forms !== this.state.forms) {
      this.props.onFormContentChange(this.state.forms);
    }
  }

  handleQuestionTextChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, questionText: value }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAnswerTypeChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, answerType: value, options: [] }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleRequiredChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, required: value === "true" }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleOptionChange = (formId, questionId, optionIndex, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: question.options.map((option, index) =>
                        index === optionIndex ? value : option
                      ),
                    }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAddOption = (formId, questionId) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, options: [...question.options, ""] }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleDeleteOption = (formId, questionId, optionIndex) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: question.options.filter(
                        (_, index) => index !== optionIndex
                      ),
                    }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAddQuestion = (formId) => {
    const newQuestion = {
      id: uuid(),
      questionText: "",
      answerType: "",
      required: false,
      options: [],
      imageUrl: "",
    };
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? { ...form, questions: [...form.questions, newQuestion] }
          : form
      ),
    }));
  };

  handleDeleteQuestion = (formId, questionId) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.filter((q) => q.id !== questionId),
            }
          : form
      ),
    }));
  };

  handleImageUpload = (formId, questionId, event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}forms/form_image_admin`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const imageUrl = response.data.default;
          this.setState((prevState) => ({
            forms: prevState.forms.map((form) =>
              form.id === formId
                ? {
                    ...form,
                    questions: form.questions.map((question) =>
                      question.id === questionId
                        ? { ...question, imageUrl }
                        : question
                    ),
                  }
                : form
            ),
          }));
        })
        .catch((error) => {
          console.error("There was an error uploading the image!", error);
        });
    }
  };

  handleSaveForm = () => {
    this.props.onFormSubmit();
  };

  handleOpenForm = () => {
    const previewContent = (
      <FormRenderer
        formData={this.state.forms}
        formTitle={this.state.form_title}
        id={this.state.forms[0].id}
      />
    );
    this.setState({ previewContent }, () => {
      window.$("#modalPreviewForm").modal("show");
    });
  };

  render() {
    const { classes } = this.props;
    const { forms } = this.state;

    return (
      <div>
        
        {forms.map((form) => (
          <div key={form.id} className={classes.formContainer}>
            {form.questions.map((question, indexes) => (
              <div key={question.id} className={classes.questionContainer}>
                <div className={`row g-4 ${classes.questionTop}`}>
                <div className="col-lg-1">
                <div class="nk-kycfm-count">{indexes + 1}</div>
                </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <MutextField
                        label="Question Text"
                        value={question.questionText}
                        onChange={(e) =>
                          this.handleQuestionTextChange(
                            form.id,
                            question.id,
                            e.target.value
                          )
                        }
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <MutextField
                        id={`answer-type-label-${question.id}`}
                        name={`answer-type-label-${question.id}`}
                        select
                        label="Answer Type"
                        value={question.answerType}
                        onChange={(e) =>
                          this.handleAnswerTypeChange(
                            form.id,
                            question.id,
                            e.target.value
                          )
                        }
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        <option key="" value=""></option>
                        <option key="input" value="input">
                          Input
                        </option>
                        <option key="textarea" value="textarea">
                          Textarea
                        </option>
                        <option key="dropdown" value="dropdown">
                          Dropdown
                        </option>
                        <option key="checkbox" value="checkbox">
                          Checkbox
                        </option>
                        <option key="radio" value="radio">
                          Radio
                        </option>
                        <option key="image_input" value="image_input">
                          Image Input
                        </option>
                        <option key="datetime" value="datetime">
                          Datetime
                        </option>
                        <option key="date" value="date">
                          Date
                        </option>
                        <option key="time" value="time">
                          Time
                        </option>
                      </MutextField>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="form-group">
                      <MutextField
                        id={`required-label-${question.id}`}
                        name={`required-label-${question.id}`}
                        select
                        label="Required"
                        value={question.required.toString()}
                        onChange={(e) =>
                          this.handleRequiredChange(
                            form.id,
                            question.id,
                            e.target.value
                          )
                        }
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        <option key="false" value="false">
                          No
                        </option>
                        <option key="true" value="true">
                          Yes
                        </option>
                      </MutextField>
                    </div>
                  </div>
                  <div
                    className="col-lg-3"
                    style={{ alignContent: "center", display: "flex" }}
                  >
                    <div style={{ alignContent: "center" }}>
                      <input
                        id={`image-upload-${question.id}`}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          this.handleImageUpload(form.id, question.id, e)
                        }
                      />
                      <button
                        className={`btn btn-dim btn-primary ${classes.deleteButton}`}
                        onClick={() => {
                          const fileInput = document.getElementById(
                            `image-upload-${question.id}`
                          );
                          if (fileInput) {
                            fileInput.click();
                          }
                        }}
                      >
                        Upload Image
                      </button>
                    </div>
                    <div className="" style={{ alignContent: "center" }}>
                      <a
                        className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                        onClick={() =>
                          this.handleDeleteQuestion(form.id, question.id)
                        }
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                  {question.imageUrl && (
                    <div className="col-lg-12">
                      <img
                        src={question.imageUrl}
                        alt="Admin Uploaded"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          display: "block",
                        }}
                      />
                    </div>
                  )}
                  {question.answerType === "checkbox" ||
                  question.answerType === "radio" ||
                  question.answerType === "dropdown" ? (
                    <div className="col-lg-6">
                      {question.options.map((option, index) => (
                        <div key={index} className={classes.optionContainer}>
                          <MutextField
                            label={`Option ${index + 1}`}
                            value={option}
                            onChange={(e) =>
                              this.handleOptionChange(
                                form.id,
                                question.id,
                                index,
                                e.target.value
                              )
                            }
                            variant="outlined"
                            fullWidth
                          />
                          <a
                            className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                            onClick={() =>
                              this.handleDeleteOption(
                                form.id,
                                question.id,
                                index
                              )
                            }
                          >
                            Delete
                          </a>
                        </div>
                      ))}
                      <a
                        className={`btn btn-outline-light`}
                        onClick={() =>
                          this.handleAddOption(form.id, question.id)
                        }
                        style={{ marginTop: "20px" }}
                      >
                        Add Option
                      </a>
                    </div>
                  ) : null}
                  {question.answerType === "image_input" ? (
                    <div className="col-lg-4">
                      <input type="file" accept="image/*" disabled />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
            <ul className="nk-block-tools g-3">
              <li className="nk-block-tools-opt">
                <a
                  style={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => this.handleAddQuestion(form.id)}
                  className={`btn btn-primary ${classes.addButton}`}
                >
                  <AddIcon />
                  &nbsp;Add Question
                </a>
              </li>
            </ul>
          </div>
        ))}
        <div className="button-container border-top pt-4">
          <ul className="nk-block-tools g-3" style={{ justifyContent: "End" }}>
            <li className="nk-block-tools-opt">
              <a
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={this.handleOpenForm}
                className="btn btn-primary"
              >
                <em className="icon ni ni-card-view"></em>
                &nbsp;Preview Form
              </a>
            </li>
            <li className="nk-block-tools-opt">
              <a
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={this.handleSaveForm}
                className="btn btn-primary"
              >
                <em className="icon ni ni-save"></em>
                &nbsp;Save Form
              </a>
            </li>
          </ul>
        </div>
        <div className="modal fade" tabIndex="-1" id="modalPreviewForm">
          <div
            className="modal-dialog modal-lg modal-dialog-top"
            role="document"
          >
            <div className="modal-content">
              <a
                style={{ cursor: "pointer" }}
                className="close"
                onClick={() => window.$("#modalPreviewForm").modal("hide")}
              >
                <em className="icon ni ni-cross"></em>
              </a>
              <div className="modal-body">
                <div className="nk-modal">{this.state.previewContent}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(FormBuilderClass);
