import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getSingleForm,
  getAllSubmissionTotals,
  getTotalFormSubmissions,
  sendFormEmail,
  viewGeneralSubmission,
  deleteGeneralSubmission,
  assignForm,
  getClientSubmissions,
  getAgentSubmissions,
  viewClientSubmission,
  viewAgentSubmission,
} from "./../../../config/forms_api_calls";
import $ from "jquery";
import { AGENT_PORTAL_URL, APP_LIVE_URL } from "../../../config/config";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import FormRenderer from "../../../components/Reseller/formRender";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import MutextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select2 from "react-select";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class Forms extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      successMessaged: "",
      errorMessaged: "",
      errorMessageTotals: "",
      errorMessageTotalsTitle: "",
      errorMessageClients: "",
      errorMessageClientsTitle: "",
      errorMessageAgents: "",
      errorMessageAgentsTitle: "",
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      errorMessageDelete: "",
      successMessageDelte: "",
      mainError: "",
      tableLoader: true,
      disabled: false,
      form_accountno: "",
      form_title: "",
      form_desc: "",
      form_status: "",
      form_content: [],
      showOnDashboard: false,
      form_sequence: "1",
      form_sequence_error: "",
      btn_text: "",
      allow_multiple: false,
      is_default: false,
      activeTab: "total_submissions",
      totalSubmissions: [],
      totalClientSubmissions: [],
      clientSubmissions: [],
      agentSubmissions: [],
      assignedLoader: false,
      viewGeneralSubmission: [],
      viewClientSubmissions: [],
      viewAgentSubmissions: [],
      viewLoader: true,
      submission_id: "",
      isProcessing: false,
      total_general_submissions: 0,
      total_client_submissions: 0,
      total_agent_submissions: 0,
      total_general_completed: 0,
      total_client_completed: 0,
      total_agent_completed: 0,
      //Send Email
      emailto: "",
      emailtoname: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      clientColumnServices: [],
      agentColumnServices: [],
      generalColumnServices: [
        {
          name: "id",
          label: "Form Title",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_client_title(value)}</div>;
            },
          },
        },
        {
          name: "submitter_name",
          label: "Sent To",
        },
        {
          name: "is_submitted",
          label: "Submitted",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_submitted(value)}</div>;
            },
          },
        },
        {
          name: "submitted_on",
          label: "Submitted On",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "sent_by_email",
          label: "Sent By",
        },
        {
          name: "pdf_name",
          label: "Download",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>{this.format_download_general(value, tableMeta)}</div>
              );
            },
          },
        },

        {
          name: "id",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="View Details" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.viewGeneralSubmission(
                                        value,
                                        tableMeta
                                      )
                                    }
                                  >
                                    <em class="icon ni ni-card-view"></em>
                                    <span>View Details</span>
                                  </a>
                                </Tooltip>
                              </li>
                              <li>
                                <Tooltip
                                  title="Delete Submission"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.delete_archived_details(
                                        value,
                                        tableMeta
                                      )
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>Delete</span>
                                  </a>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  async componentDidMount() {
    let form_accountno = this.props.props.match.params.id;
    this.setState({
      errorMessage: "",
      successMessage: "",
      form_accountno: form_accountno,
    });
    const servicesResponce = await getSingleForm(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );
    console.log("servicesResponce: ", servicesResponce);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let form_sequence =
        servicesResponce.data.data[0]["form_sequence"] &&
        servicesResponce.data.data[0]["form_sequence"] > 0
          ? servicesResponce.data.data[0]["form_sequence"]
          : "1";
      let btn_text =
        servicesResponce.data.data[0]["btn_text"] &&
        servicesResponce.data.data[0]["btn_text"] !== ""
          ? servicesResponce.data.data[0]["btn_text"]
          : "";
      this.setState({
        form_title: servicesResponce.data.data[0]["form_title"],
        form_desc: servicesResponce.data.data[0]["form_desc"],
        form_content: JSON.parse(servicesResponce.data.data[0]["form_content"]),
        showOnDashboard: servicesResponce.data.data[0]["show_on_dashboard"],
        allow_multiple: servicesResponce.data.data[0]["allow_multiple"],
        is_default: servicesResponce.data.data[0]["is_default"],
        form_status: servicesResponce.data.data[0]["status"],
        form_sequence: form_sequence,
        btn_text: btn_text,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
    this.getAllSubmissionTotals();
    this.getTotalSubmissions();
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      assignedLoader: true,
    });
  };

  getAllSubmissionTotals = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getAllSubmissionTotals(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getAllSubmissionTotals: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        total_general_submissions:
          servicesResponse.data.total_general_submissions,
        total_client_submissions:
          servicesResponse.data.total_client_submissions,
        total_agent_submissions: servicesResponse.data.total_agent_submissions,
        total_general_completed: servicesResponse.data.total_general_completed,
        total_client_completed: servicesResponse.data.total_client_completed,
        total_agent_completed: servicesResponse.data.total_agent_completed,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
    }
  };

  getTotalSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getTotalFormSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageTotalsTitle: "",
        errorMessageTotals: "",
        totalSubmissions: servicesResponse.data.data,
        assignedLoader: false,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  getClientSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getClientSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getClientSubmissions: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageClientsTitle: "No Submission",
        errorMessageClients: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageClientsTitle: "",
        errorMessageClients: "",
        totalClientSubmissions: servicesResponse.data.data,
        clientColumnServices: [
          {
            name: "id",
            label: "Form Title",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_client_title(value)}</div>;
              },
            },
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download(value, tableMeta)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewClientSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Delete Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
        assignedLoader: false,
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageClientsTitle: "No Submission",
        errorMessageClients: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  format_client_title = (value) => {
    return this.state.form_title;
  };

  format_approved = (value) => {
    if (value === "Yes") {
      return (
        <Tooltip title="This agreement is approved." placement="top">
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_submitted = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_download = (value, tableMeta) => {
    console.log("format_download value: ", value);
    if (value && value !== "") {
      return (
        <a
          onClick={() => this.downloadPdf(value, this.state.form_title)}
          title="Download Agreement"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em>
          </span>
        </a>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  format_download_general = (value, tableMeta) => {
    console.log("format_download value: ", value);
    if (value && value !== "") {
      return (
        <a
          onClick={() => this.downloadPdfGeneral(value)}
          title="Download Agreement"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em>
          </span>
        </a>
      );
    } else {
      return <span className="badge badge-outline-light">---</span>;
    }
  };

  getAgentSubmissions = async () => {
    const { form_accountno } = this.state;
    const servicesResponse = await getAgentSubmissions(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("getAgentSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        errorMessageAgentsTitle: "No Submission",
        errorMessageAgents: "There are no submissions right now.",
        assignedLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        errorMessageAgentsTitle: "",
        errorMessageAgents: "",
        agentSubmissions: servicesResponse.data.data,
        assignedLoader: false,
        agentColumnServices: [
          {
            name: "id",
            label: "Form Title",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_client_title(value)}</div>;
              },
            },
          },
          {
            name: "submitter_name",
            label: "Sent To",
          },
          {
            name: "is_submitted",
            label: "Submitted",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_submitted(value)}</div>;
              },
            },
          },
          {
            name: "submitted_on",
            label: "Submitted On",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "sent_by_email",
            label: "Sent By",
          },
          {
            name: "pdf_name",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download(value, tableMeta)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewAgentSubmission(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Delete Submission"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });

      //window.$("#modalAssignForm").modal("show");
    } else {
      this.setState({
        errorMessageAgentsTitle: "No Submission",
        errorMessageAgents: "There are no submissions right now.",
        assignedLoader: false,
      });
    }
  };

  viewGeneralSubmission = async (id) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewGeneralSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );
    window.$("#modalViewGeneralSubmission").modal("show");
    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        viewGeneralSubmission: servicesResponse.data.data,
        viewLoader: false,
      });

      //
    }
  };

  viewClientSubmission = async (id, tableMeta) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewClientSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );

    window.$("#modalClientSubmission").modal("show");
    console.log("viewClientSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        viewClientSubmissions: servicesResponse.data.data,
        viewLoader: false,
      });

      //
    }
  };

  viewAgentSubmission = async (id, tableMeta) => {
    const { form_accountno } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await viewAgentSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      id
    );

    window.$("#modalAgentSubmission").modal("show");
    console.log("viewAgentSubmission: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageTotalsTitle: "No Submission",
        errorMessageTotals: "There are no submissions right now.",
        viewLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        viewAgentSubmissions: servicesResponse.data.data,
        viewLoader: false,
      });

      //
    }
  };

  deleteGeneralModal = async (id) => {
    this.setState({ submission_id: id });
    window.$("#modalDeleteGeneralAlert").modal("show");
  };

  modalHideDelGeneral = async () => {
    this.setState({ submission_id: "" });
    window.$("#modalDeleteGeneralAlert").modal("hide");
  };

  deleteGeneralSubmission = async (id) => {
    const { form_accountno, submission_id } = this.state;
    this.setState({ viewLoader: true });
    const servicesResponse = await deleteGeneralSubmission(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      submission_id
    );

    console.log("getTotalSubmissions.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "form_not_found"
    ) {
      this.setState({
        errorMessageDelete: "There are no submissions right now.",
        isProcessing: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelte: "Submission deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            this.getTotalSubmissions();
            window.$("#modalDeleteGeneralAlert").modal("hide");
            this.setState({ successMessage: "" });
            //window.location.reload();
          }, 3000);
        }
      );
    } else {
      this.setState({
        errorMessageDelete: "Error failed to delete submission.",
        isProcessing: false,
      });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  fieldValidation = () => {
    let { emailtoname, emailto, emailsubject, emailcontent } = this.state;

    if (emailtoname === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Name is required.",
      });
      return false;
    } else if (emailto === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "To email address is required.",
      });
      return false;
    } else if (this.validateEmailAdd(emailto) === false) {
      this.setState({
        emailErrorMessageTitle: "Invalid Format",
        emailErrorMessage: "Invalid email address format.",
      });
      return false;
    } else if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Subject is required.",
      });
      return false;
    } else if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        emailErrorMessage: "Custom content is required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        emailErrorMessage: "",
      });
    }
    return true;
  };

  submitFormEmail = async () => {
    const { form_accountno, emailtoname, emailto, emailsubject, emailcontent } =
      this.state;

    if (this.fieldValidation() === true) {
      this.setState({
        email_button: true,
        emailErrorMessage: "",
        emailErrorMessageTitle: "",
        emailSuccessMessage: "",
      });
      const servicesResponce = await sendFormEmail(
        auth.getAccount(),
        auth.getToken(),
        form_accountno,
        emailtoname,
        emailto,
        emailsubject,
        emailcontent
      );
      console.log("submitOrderEmail: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "already_sent"
      ) {
        this.setState({
          emailErrorMessageTitle: "Already Sent",
          emailErrorMessage:
            "This form has already been sent to the email and is still not submitted.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 404 &&
        servicesResponce.data.message === "insert_error"
      ) {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage: "This is some error while sending the form.",
          email_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          emailSuccessMessage: "Email sent successfully.",
          payment_status: servicesResponce.data.data,
          emailErrorMessageTitle: "",
          emailErrorMessage: "",
          emailsubject: "",
          emailto: "",
          emailtoname: "",
          emailcontent: "",
          email_button: false,
        });
      } else {
        this.setState({
          emailErrorMessageTitle: "System Error",
          emailErrorMessage:
            "There is some error while processing your request.",
          email_button: false,
        });
      }
    }

    setTimeout(() => {
      this.setState({
        emailErrorMessage: "",
        emailSuccessMessage: "",
      });
    }, 4000);
  };

  downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url =
        AGENT_PORTAL_URL + "files_data/form_response_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  downloadPdfGeneral = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/form_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  render() {
    return (
      <div class="nk-content ">
        <div class="container-fluid">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Forms /{" "}
                      <strong class="text-primary small">
                        {this.state.form_title}
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Form ID:{" "}
                          <span class="text-base">
                            {this.state.form_accountno}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistory("/forms/")}
                      href="#"
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistory("/forms/")}
                      href="#"
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "total_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("total_submissions");
                              this.getTotalSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>General Forms</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "client_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("client_submissions");
                              this.getClientSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Client Submissions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "agent_submissions"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("agent_submissions");
                              this.getAgentSubmissions();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Channel Partner Submissions</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "email_form"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("email_form");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <em class="icon ni ni-mail"></em>
                            <span>Email Form</span>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "email_form"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="email_forms"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="row g-gs">
                                <div class="col-sm-12">
                                  <h5>Send Form</h5>
                                  {this.state.emailErrorMessage !== "" ? (
                                    <div class="alert alert-pro alert-danger">
                                      <div class="alert-text">
                                        <h6>
                                          {this.state.emailErrorMessageTitle}
                                        </h6>
                                        <p>{this.state.emailErrorMessage}</p>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.emailSuccessMessage !== "" ? (
                                    <div class="alert alert-pro alert-success">
                                      <div class="alert-text">
                                        <h6>Success</h6>
                                        <p>{this.state.emailSuccessMessage}</p>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group mt-2">
                                    <label class="form-label" for="emailto">
                                      To Name <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="emailtoname"
                                        name="emailtoname"
                                        defaultValue={this.state.emailtoname}
                                        onChange={this.handleChange}
                                        placeholder="Enter the full name"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group mt-2">
                                    <label class="form-label" for="emailto">
                                      To <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="emailto"
                                        name="emailto"
                                        defaultValue={this.state.emailto}
                                        onChange={this.handleChange}
                                        placeholder="Enter email address"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 mt-2">
                                  <div class="form-group">
                                    <label
                                      class="form-label"
                                      for="emailsubject"
                                    >
                                      Subject <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="emailsubject"
                                        name="emailsubject"
                                        defaultValue={this.state.emailsubject}
                                        onChange={this.handleChange}
                                        placeholder="Email Subject"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 mt-2">
                                  <div class="form-group">
                                    <label
                                      class="form-label"
                                      for="emailcontent"
                                    >
                                      Custom Message <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <textarea
                                        class="form-control"
                                        id="emailcontent"
                                        name="emailcontent"
                                        defaultValue={this.state.emailcontent}
                                        onChange={this.handleChange}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 mt-2 d-flex justify-content-end">
                                  <div class="nk-block-head-content">
                                    <ul class="nk-block-tools gx-3">
                                      {this.state.email_button === true ? (
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Processing... </span>
                                        </button>
                                      ) : (
                                        <li>
                                          <a
                                            onClick={() =>
                                              this.submitFormEmail()
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#fff",
                                            }}
                                            class="btn btn-primary"
                                          >
                                            <em class="icon ni ni-check-round-cut"></em>{" "}
                                            <span>Send Email</span>{" "}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        <div
                          className={
                            this.state.activeTab === "total_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">
                                  General Forms Submissions
                                </h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.generalColumnServices
                                          }
                                          tableData={
                                            this.state.totalSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* EMAIL FORM */}
                        {/* CLIENT SUBMISSIONS */}
                        <div
                          className={
                            this.state.activeTab === "client_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements2"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">Client Submissions</h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned2"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.clientColumnServices
                                          }
                                          tableData={
                                            this.state.totalClientSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* CLIENT SUBMISSIONS */}
                        {/* AGENT SUBMISSIONS */}
                        <div
                          className={
                            this.state.activeTab === "agent_submissions"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements3"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title mb-4">Agent Submissions</h5>

                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div
                                      className="tab-pane active"
                                      id="tabItemSigned3"
                                    >
                                      <div className="card card-bordered card-preview">
                                        <DataTableExtended
                                          columns={
                                            this.state.agentColumnServices
                                          }
                                          tableData={
                                            this.state.agentSubmissions
                                          }
                                          title=""
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* AGENT SUBMISSIONS */}
                        <div class="nk-divider divider md"></div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-info">
                              {this.state.form_status &&
                              this.state.form_status === 1 ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Active
                                </div>
                              ) : (
                                <div class="badge badge-outline-light badge-pill ucap">
                                  Inactive
                                </div>
                              )}

                              <h5>{this.state.form_title}</h5>
                              <span class="sub-text"></span>
                            </div>
                          </div>
                        </div>

                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-2">
                            Completed / Pending
                          </h6>
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_general_completed} /{" "}
                                  {this.state.total_general_submissions}
                                </span>
                                <span class="sub-text">General</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_client_completed} /{" "}
                                  {this.state.total_client_submissions}
                                </span>
                                <span class="sub-text">Client</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">
                                  {this.state.total_agent_completed} /{" "}
                                  {this.state.total_agent_submissions}
                                </span>
                                <span class="sub-text">CP</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">User ID:</span>
                              <span>UD003054</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Last Login:</span>
                              <span>15 Feb, 2019 01:02 PM</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">KYC Status:</span>
                              <span class="lead-text text-success">
                                Approved
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Register At:</span>
                              <span>Nov 24, 2019</span>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalViewGeneralSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Submission
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewGeneralSubmission &&
                                this.state.viewGeneralSubmission.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewGeneralSubmission.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-success">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadPdfGeneral(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {general.extra_files &&
                                                  general.extra_files !== "" ? (
                                                    <>
                                                      {general.extra_files
                                                        .split(",")
                                                        .map((file, index) => (
                                                          <>
                                                            {file &&
                                                            file !== "" ? (
                                                              <div
                                                                key={index}
                                                                className="col-md-12"
                                                                style={{
                                                                  paddingLeft:
                                                                    "0px",
                                                                }}
                                                              >
                                                                <span
                                                                  onClick={() =>
                                                                    this.downloadPdfGeneral(
                                                                      file.trim()
                                                                    )
                                                                  }
                                                                  class="badge badge-outline-primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  title="Download / View"
                                                                >
                                                                  <em class="icon ni ni-download"></em>{" "}
                                                                  {file.trim()}
                                                                </span>
                                                              </div>
                                                            ) : null}
                                                          </>
                                                        ))}
                                                    </>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View General Submissions */}
              {/* View Client Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalClientSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Submission
                          Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewClientSubmissions &&
                                this.state.viewClientSubmissions.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewClientSubmissions.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-primary">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadFormPdf(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {general.extra_files &&
                                                  general.extra_files !== ""
                                                    ? general.extra_files
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View Client Submissions */}

              {/* View Client Submissions */}
              <div
                className="modal fade zoom"
                tabIndex="-1"
                id="modalAgentSubmission"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-list-round"></em> Channel
                          Partner Submission Details{" "}
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* NRC DIVS START */}

                      {this.state.viewLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div>
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                {this.state.viewAgentSubmissions &&
                                this.state.viewAgentSubmissions.length > 0 ? (
                                  <div className="card-inner">
                                    <div className="sp-plan-head">
                                      <h6 className="title">
                                        Form Submission Details
                                      </h6>
                                    </div>
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <table className="table table-bordered table-striped">
                                        {this.state.viewAgentSubmissions.map(
                                          (general, index) => (
                                            <tbody
                                              key={index}
                                              className="text-dark"
                                            >
                                              <tr>
                                                <td>Sent To Name</td>
                                                <td>
                                                  {general.submitter_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent To Email</td>
                                                <td>
                                                  {general.submitter_email}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent By</td>
                                                <td>{general.sent_by_email}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Date</td>
                                                <td>
                                                  {this.format_date(
                                                    general.sent_on
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submitted</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <span class="badge badge-dot badge-primary">
                                                      Yes
                                                    </span>
                                                  ) : (
                                                    <span class="badge badge-dot badge-danger">
                                                      No
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Submission Date</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1
                                                    ? this.format_date(
                                                        general.submitted_on
                                                      )
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>View PDF</td>
                                                <td>
                                                  {general.is_submitted &&
                                                  general.is_submitted === 1 ? (
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.downloadFormPdf(
                                                          general.pdf_name
                                                        )
                                                      }
                                                    >
                                                      <span class="badge badge-outline-dark">
                                                        View
                                                      </span>
                                                    </a>
                                                  ) : (
                                                    "---"
                                                  )}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Extra Files</td>
                                                <td>
                                                  {general.extra_files &&
                                                  general.extra_files !== ""
                                                    ? general.extra_files
                                                    : "---"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Subject</td>
                                                <td>{general.email_subject}</td>
                                              </tr>
                                              <tr>
                                                <td>Sent Form Message</td>
                                                <td>{general.email_content}</td>
                                              </tr>
                                            </tbody>
                                          )
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* View Agent Submissions */}
              {/* START DELETE MODAL  */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalDeleteGeneralAlert"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => {
                        this.modalHideDel();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                        <h4 className="nk-modal-title">Delete Submission?</h4>

                        <div className="nk-modal-text">
                          {this.state.errorMessageDelete !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageDelete}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageDelte !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageDelte}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="caption-text">
                            Are you sure you want to delete this submission
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHideDelGeneral();
                            }}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-danger"
                            onClick={() => {
                              this.deleteGeneralSubmission();
                            }}
                            disabled={
                              this.state.disabled || this.state.isProcessing
                            } // disable button when processing
                          >
                            {this.state.isProcessing ? (
                              <span>DELETING...</span>
                            ) : (
                              <span>DELETE</span>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END DELETE MODAL  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
