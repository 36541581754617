import { React } from "react";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  COMPANY_NAME,
  KYC_URL,
  APP_URL,
  AGENT_PORTAL_URL,
  AGENT_SERVER_URL,
} from "../../../config/config";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { ValidateEmail } from "../../../config/utility";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";
import { withRouter } from "react-router-dom";

import {
  listClientAllowedServices,
  getClientSettings,
  getAgreementPdf,
  getClientInfo,
  updateClientAllowedServices,
  listClients,
  listServicesGroups,
  listAssignedServicesWithProjection,
  listAssignedServicesAgreements,
  assignServicesToCompany,
  revokeClientSignedAgreements,
  sendUnsignedClientEmail,
  submitClientServicesData,
  getClientKycDetail,
  initiateClientKyc,
  emailKycDetails,
  resendActivation,
  getCreditApplications,
  rejectCreditApplication,
  approveCreditApplication,
  listAgents,
  sendClientInvite,
  submitKycApproval,
  clientLoginSso,
  listAgentSharelinks,
  editClient,
  approveClientSignedAgreement,
  getClientArchivedAgreements,
  getClientArchivedAgreementsSingle,
  deleteClientArchivedAgreement,
} from "./../../../config/api_calls";
import {
  ListClientAssignRoles,
  editClientAssignRole,
} from "../../../config/client_role_api_calls";
import { getClientAgreementSigneeDetails } from "../../../config/signed_agreements_api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";

import TextField from "../../../components/InputFields/TextField";

import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
} from "./../../../config/config";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import countryList from "react-select-country-list";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";

const animatedComponents = makeAnimated();
const auth = new HelperClass();
class ViewClient extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      formLoader: true,
      signupLoader: true,
      sharelinkLoader: false,
      showServiceLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      teamsArray: [],
      tfnTermArray: [],
      azTermArray: [],
      cnamArray: [],

      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      teams_service: "",
      tfn_term_service: "",
      az_term_service: "",
      cnam_service: "",
      liveagent_service: "",

      loginErrorMessage: "",
      login_url: "",

      filer_id: "",

      signedData: [],
      unsignedData: [],
      isRevokeReasonActive: false,
      revoke_reason: "",
      unsigned_email_agreement_accountno: 0,
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      packagesAssignedData: [],
      client_services_welcome_content: "",
      client_services_email: "",
      company_id: "",
      company_title: "",
      successMessage: "",
      successMessageAgreements: "",
      disabled: false,
      showSendEmail: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      errorMessageAgreements: "",
      errorMessageArchiveAgreements: "",
      extra_details: [],
      emptyService: 0,
      all_client_data: [],
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      showKycApproved: false,
      showApprovedAction: "",
      showRejectAction: "",
      loading_button: false,
      kycErrorMessage: "",
      kycSuccessMessage: "",
      client_register_date: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      client_services_client_id: "",
      client_accountno: "",
      kyc_email_address: "",
      kyc_approved: "",
      credit_applications: [],
      processing_request: false,
      creditErrorMessage: "",
      creditSuccessMessage: "",
      application_no: "",
      rejection_container: false,
      rejection_reason: "",
      select_agent: "",
      client_email: "",
      client_name: "",
      clientErrorMessage: "",
      clientSuccessMessage: "",
      selected_agent: null,
      selected_agent_accountno: "",
      all_active_agents: [],
      all_sharelinks: [],
      sharelinkErrorMessage: "",
      client_comp_name: "",
      client_update_name: "",
      client_name_initials: "",
      client_title: "",
      client_update_email: "",

      client_phone: "",
      client_address: "",
      client_address2: "",
      client_country: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_status: "",
      client_password: "",
      client_confirm_password: "",
      errorClientTitle: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientPass: "",
      errorClientAddress: "",
      errorClientCountry: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientMessage: "",
      errorClientMessageEdit: "",
      successClientMessage: "",
      successClientMessageEdit: "",
      isApproveActive: false,
      approve_client_id: "",
      approve_agreement_accountno: "",

      selected_products: [],
      checkAll: false,
      update_service_button: false,
      assignedErrorMessage: "",
      assignedSuccessMessage: "",
      assignedLoader: true,
      activeTab: "agreements",
      isEditorReady: false,
      notifyCheckBox: false,
      totalClientAgreements: 0,
      signedClientAgreements: 0,
      unsignedClientAgreements: 0,
      totalSalesOrders: 0,
      paidSalesOrders: 0,
      completedSalesOrders: 0,
      kyc_percentage: 0,
      client_account_status: "",

      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",

      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      statusChanged: false,

      selected_client_accountno: "",
      signee_details: [],
      selected_agreement_id: "",

      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      add_picture: "",
      add_picture_name: "",

      archivedAgreements: [],
      archivedAgreementsDetails: [],
      processing_request_archived: false,
      errorMessageArchivedDetails: "",
      delete_archive_id: "",
      successMessageDelete: "",
      errorMessageDelete: "",
    };
    this.countryOptions = countryList()
    .getData()
    .filter(
      (country) => country.label !== "United States Minor Outlying Islands"
    );
    this.phoneInputRef = createRef();
  }
  async componentDidMount() {
    let tab_type = this.props.props.match.params.tab_type;
    let client_accountno = this.props.props.match.params.client_accountno;
    this.setState({
      client_accountno: client_accountno,
    });
    const getClientInfoResponce = await getClientInfo(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      const client_initials = getClientInfoResponce.data.data.name
        .split(" ")
        .map((word) => {
          const firstChar = word.charAt(0);
          return /^[a-zA-Z]+$/.test(firstChar) ? firstChar : "";
        })
        .join("");
      let kyc_percentage =
        (100 / getClientInfoResponce.data.data.steps_allowed) *
        getClientInfoResponce.data.data.steps_completed;
      this.setState({
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        kyc_email_address: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_address2: getClientInfoResponce.data.data.address2,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
        client_status: getClientInfoResponce.data.data.isVerified,
        client_name_initials: client_initials,
        totalClientAgreements: getClientInfoResponce.data.data.totalAgreements,
        signedClientAgreements:
          getClientInfoResponce.data.data.signedAgreements,
        unsignedClientAgreements:
          getClientInfoResponce.data.data.unsignedAgreements,
        totalSalesOrders: getClientInfoResponce.data.data.totalOrders,
        paidSalesOrders: getClientInfoResponce.data.data.paidOrders,
        completedSalesOrders: getClientInfoResponce.data.data.completedOrders,
        kyc_percentage: kyc_percentage.toFixed(0),
        filer_id: getClientInfoResponce.data.data.filer_id,
      });
      console.log("this.state.kyc_percentage: ", this.state.kyc_percentage);
    } else {
      // window.location.replace("/error");
    }
    this.clientAgreements();
  }

  format_date = (value, tableMeta) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  handleEditorChange = (event, editor) => {
    const content = editor.getData();
    this.setState({
      client_services_welcome_content: content,
    });
    //console.log("Content was updated:", content);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;

    if (is_default === "inactive") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === "active") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  clientServices = async () => {
    const { client_accountno } = this.state;
    const servicesResponce = await getClientSettings(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );

    //console.log("getClientSettings: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        client_services_welcome_content: servicesResponce.data.data,
        errorMessageAgreements: "",
        successMessageAgreements: "",
        assignedLoader: false,
        isEditorReady: true,
      });
    }

    // window.$("#modalFormClientServices").modal("show");
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  showAssignedServices = async () => {
    const { client_accountno } = this.state;
    const servicesResponce = await listClientAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("showAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        showServiceLoader: false,
        assignedLoader: false,
      });
    } else {
      this.setState({
        showServiceLoader: false,
        assignedLoader: false,
      });
    }
  };

  handleCheckboxChange = (id) => {
    const serviceAssignedData = this.state.serviceAssignedData.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    this.setState({ serviceAssignedData });
  };

  handleCheckAllChange = () => {
    const { checkAll, serviceAssignedData } = this.state;
    const updatedCheckboxes = serviceAssignedData.map((checkbox) => ({
      ...checkbox,
      checked: !checkAll,
    }));
    this.setState({
      serviceAssignedData: updatedCheckboxes,
      checkAll: !checkAll,
    });
  };

  updateAssignedServices = async () => {
    const { checkAll, serviceAssignedData, client_accountno } = this.state;
    this.setState({
      update_service_button: true,
    });
    console.log(
      "updateAssignedServices serviceAssignedData: ",
      serviceAssignedData
    );
    const servicesResponce = await updateClientAllowedServices(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      JSON.stringify(serviceAssignedData)
    );
    console.log("updateAssignedServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        assignedErrorMessage: "",
        assignedSuccessMessage:
          "Services / Products assigned successfully to the client.",
        update_service_button: false,
      });
    } else {
      this.setState({
        update_service_button: false,
        assignedErrorMessage:
          "There is some error while assigning the services to the client.",
        assignedSuccessMessage: "",
      });
    }
    setTimeout(() => {
      this.setState({
        update_service_button: false,
        assignedErrorMessage: "",
        assignedSuccessMessage: "",
      });
    }, 4000);
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  handleClientAgreementRevokeSubmit = async (
    agreement_accountno,
    client_id,
    revoke_reason
  ) => {
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        errorMessageAgreements: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
        disabled: true,
      });

      const servicesResponce = await revokeClientSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        client_id,
        agreement_accountno,
        revoke_reason
      );
      //console.log("handleAgentAgreementRevokeSubmit.data: ", servicesResponce.data);
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessageAgreements: "Agreement is revoked successfully.",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        setTimeout(() => {
          this.clientAgreements();
        }, 4000);
      } else {
        this.setState({
          errorMessageAgreements:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
      });
    }, 4000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    if (name === "client_account_status") {
      this.setState({ statusChanged: true });
      console.log("Status Changed:", true);
    }
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handleChangeClientServices = async (event) => {
    const { target } = event;
    // console.log('handleChangeClientServicesWelcomeContent',target);
    // return;
    const value = target.value;
    const { name } = target;
    //console.log(name);
    this.setState({
      [name]: value,
    });
  };

  handle_accordion_head = (number) => {
    if (number === 0) {
      return "accordion-head";
    } else {
      return "accordion-head collapsed";
    }
  };

  handle_accordion_body = (number) => {
    if (number === 0) {
      return "accordion-body collapse show";
    } else {
      return "accordion-body collapse";
    }
  };

  downloadPdf = async (d_f_name, agreement_title) => {
    const reportsData = await getAgreementPdf(
      auth.getAccount(),
      auth.getToken(),
      d_f_name
    );
    //console.log("PDF.data: ", reportsData.data.data + " " + d_f_name);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      let file_name = agreement_title.replace(/[^a-zA-Z0-9]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  print_details = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  showApprovedCont = (kyc_action, accountno) => {
    this.setState({
      showKycApproved: true,
      showApprovedAction: kyc_action,
    });
  };

  showRejectCont = (kyc_action, accountno) => {
    this.setState({
      showKycApproved: true,
      showApprovedAction: kyc_action,
    });
  };

  addMenuHistoryLeft = (link) => {
    this.props.history.push(link);
  };

  openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      assignedLoader: true,
    });
  };

  clientAgreements = async (event) => {
    let company_id = this.state.client_accountno;
    this.setState({
      isRevokeReasonActive: false,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      client_accountno: company_id,
    });
    // this.setState({
    //   company_title: company_title
    // });
    // window.$("#modalFormClientAgreements").modal("show");
    // return;

    //console.log("tableMeta: ", tableMeta);
    let clientAgreementsInfo = [];
    const servicesResponce = await listAssignedServicesAgreements(
      auth.getAccount(),
      auth.getToken(),
      company_id
    );

    console.log("clientAgreements: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.clientAgreementsInfo &&
        servicesResponce.data.clientAgreementsInfo.length > 0
      ) {
        clientAgreementsInfo = servicesResponce.data.clientAgreementsInfo;
      }
      const combinedAgreements = [
        ...clientAgreementsInfo,
        ...servicesResponce.data.unSignedAgreements,
      ];
      this.setState({
        signedData: combinedAgreements,
        unsignedData: servicesResponce.data.unSignedAgreements,
        errorMessage: "",
        successMessage: "",
        assignedLoader: false,
      });
    } else {
      this.setState({
        signedData: [],
        unsignedData: [],
        errorMessage: "",
        successMessage: "",
        assignedLoader: false,
      });
    }

    //let company_title = tableMeta.rowData[1];

    // window.$("#modalFormClientAgreements").modal("show");
  };

  approveAgreement = async (agreement_accountno) => {
    const { client_accountno } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      disabled: true,
    });

    const servicesResponce = await approveClientSignedAgreement(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      agreement_accountno
    );
    console.log("approveAgreement.data: ", servicesResponce.data);
    this.setState({
      disabled: false,
    });
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.clientAgreements();
      this.setState({
        successMessage: "Agreement approved successfully",
        isApproveActive: false,
      });
      setTimeout(() => {
        this.clientAgreements();
      }, 4000);
    } else {
      this.setState({
        errorMessage:
          "There is an error while approving an agreement. Please try again.",
      });
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 4000);
  };

  reloadClientAgreements = async (company_id) => {
    let clientAgreementsInfo = [];
    const servicesResponce = await listAssignedServicesAgreements(
      auth.getAccount(),
      auth.getToken(),
      company_id
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.clientAgreementsInfo &&
        servicesResponce.data.clientAgreementsInfo.length > 0
      ) {
        clientAgreementsInfo = servicesResponce.data.clientAgreementsInfo;
      }
      this.setState({
        signedData: clientAgreementsInfo.filter(
          (data) => data.is_signed === "Yes"
        ),
        unsignedData: servicesResponce.data.unSignedAgreements,
        errorMessage: "",
        successMessage: "",
      });
    } else {
    }
  };

  revokeAgreement = async (agreement_accountno) => {
    //console.log('revokeAgreement',agreement_id,agreement_accountno);
    this.setState({
      isRevokeReasonActive: true,
      isApproveActive: false,
      revoke_reason: "",
      errorMessage: "",
      successMessage: "",
      revokeAgreementData: {
        agreement_accountno: agreement_accountno,
      },
    });
    this.scrollToDiv("card-content");
    //console.log("agreement_accountno: ", agreement_accountno);
  };

  showDetails = async () => {
    let extra_details = this.state.client_accountno;
    this.setState({
      kyc_details: [],
      kyc_key: "",
      steps_allowed: 0,
      steps_completed: 0,
      completed_percentage: 0,
      kycErrorMessage: "",
      loading_button: false,
      kycSuccessMessage: "",
      kyc_approved: "",
    });

    const kycResponce = await getClientKycDetail(
      auth.getAccount(),
      auth.getToken(),
      extra_details
    );
    console.log("getClientKycDetail: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting client details. Please try again later.",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      let completed_percentage =
        (100 / kycResponce.data.steps_allowed) *
        kycResponce.data.steps_completed;
      this.setState({
        kyc_details: kycResponce.data.data,
        kyc_key: kycResponce.data.kyc_key,
        steps_allowed: kycResponce.data.steps_allowed,
        steps_completed: kycResponce.data.steps_completed,
        completed_percentage: completed_percentage.toFixed(0),
        client_register_date: this.format_date(kycResponce.data.register_date),
        kyc_approved: kycResponce.data.kyc_approved,
        assignedLoader: false,
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
      });
    }

    // window.$("#modalClientDetails").modal("show");
  };

  initiateKyc = async () => {
    const { client_accountno } = this.state;
    this.setState({
      errorMessageAgreements: "",
      loading_button: true,
      successMessageAgreements: "",
    });
    const kycResponce = await initiateClientKyc(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("initiateKyc: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting client details. Please try again later.",
        loading_button: false,
        successMessageAgreements: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        successMessageAgreements: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        loading_button: false,
        errorMessageAgreements: "",
        successMessageAgreements:
          "Kyc process initiated successfully. An email has also been sent to the customer with URL.",
      });
      // setTimeout(function () {
      //   window.$("#modalClientDetails").modal("hide");
      // }, 3000);
    } else {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the data. Please try again.",
        loading_button: false,
        successMessageAgreements: "",
      });
    }
    //window.$("#modalClientDetails").modal("show");
  };

  downloadKycPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      d_f_name = d_f_name + ".pdf";
      var url = APP_URL + "files_data/kyc_signup/" + d_f_name;
      console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessageAgreements:
              "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ errorMessageAgreements: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  submit_client_kyc_approval = async () => {
    const { client_accountno, kyc_reason, showApprovedAction } = this.state;
    this.setState({
      errorMessageAgreements: "",
      disabled: true,
      successMessageAgreements: "",
    });
    if (kyc_reason === "") {
      this.setState({
        errorMessageAgreements: "Please enter the rejection / approval reason.",
        disabled: false,
        successMessageAgreements: "",
      });
    } else {
      const kycResponce = await submitKycApproval(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        kyc_reason,
        showApprovedAction
      );
      console.log("email_kyc_Details: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          errorMessageAgreements:
            "There is an error while processing your request. Please try again later.",
          disabled: false,
          successMessageAgreements: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          errorMessageAgreements:
            "There is an error while processing your request. Please try again.",
          disabled: false,
          successMessageAgreements: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          disabled: false,
          errorMessageAgreements: "",
          successMessageAgreements:
            "Your request has been processed successfully.",
          kyc_reason: "",
          kyc_approved: kycResponce.data.kyc_approved,
        });
        setTimeout(() => {
          this.setState({
            showKycApproved: false,
          });
        }, 4000);
      } else {
        this.setState({
          errorMessageAgreements:
            "There is an error while processing your request. Please try again.",
          disabled: false,
          successMessageAgreements: "",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessageAgreements: "",
        successMessageAgreements: "",
      });
    }, 5000);
  };

  email_kyc_Details = async () => {
    const { client_accountno, kyc_email_address } = this.state;
    this.setState({
      errorMessageAgreements: "",
      disabled: true,
      successMessageAgreements: "",
    });
    if (kyc_email_address === "") {
      this.setState({
        errorMessageAgreements: "Please enter the email address.",
        disabled: false,
        successMessageAgreements: "",
      });
    } else if (ValidateEmail(kyc_email_address) === false) {
      this.setState({
        errorMessageAgreements: "Please enter the valid email address.",
        disabled: false,
        successMessageAgreements: "",
      });
    } else {
      const kycResponce = await emailKycDetails(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        kyc_email_address
      );
      //console.log("email_kyc_Details: ", kycResponce.data);
      if (
        kycResponce.data.status === 403 ||
        kycResponce.data.errors === "authentication missing" ||
        kycResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (kycResponce.data.status === 406) {
        this.setState({
          errorMessageAgreements:
            "There is an error while sending an email. Please try again later.",
          disabled: false,
          successMessageAgreements: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 404 &&
        kycResponce.data.message === "not_exists"
      ) {
        this.setState({
          errorMessageAgreements:
            "There is an error while sending an email. Please try again.",
          disabled: false,
          successMessageAgreements: "",
        });
        //window.location.replace("/error");
      } else if (
        kycResponce.data.status === 200 &&
        kycResponce.data.message === "success"
      ) {
        this.setState({
          disabled: false,
          errorMessageAgreements: "",
          successMessageAgreements:
            "An email has been sent to the customer with URL to complete his profile.",
        });
      } else {
        this.setState({
          errorMessageAgreements:
            "There is an error while sending an email. Please try again.",
          disabled: false,
          successMessageAgreements: "",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessageAgreements: "",
        successMessageAgreements: "",
      });
    }, 5000);

    //window.$("#modalClientDetails").modal("show");
  };

  handleClientServicesSubmit = async (client_id, welcome_content, email) => {
    //console.log('handleClientAgreementRevokeSubmit',client_id, revoke_reason, agreement_accountno);
    const {
      client_accountno,
      client_services_welcome_content,
      client_services_email,
    } = this.state;

    let is_validated = true;
    if (client_services_welcome_content === "") {
      is_validated = false;
      this.setState({
        errorMessageAgreements: "Welcome content is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
        disabled: true,
      });

      const servicesResponce = await submitClientServicesData(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        client_services_welcome_content,
        client_services_email
      );
      //console.log("handleAgentAgreementRevokeSubmit.data: ", servicesResponce.data);
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          errorMessageAgreements:
            "There is an error while submitting a data. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessageAgreements:
            "There is an error while submitting a data. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          successMessageAgreements: "Client notes updated successfully.",
        });
        setTimeout(function () {
          window.$("#modalFormClientServices").modal("hide");
        }, 4000);
      } else {
        this.setState({
          errorMessageAgreements:
            "There is an error while submitting a data. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        successMessageAgreements: "",
        errorMessageAgreements: "",
      });
    }, 4000);
  };

  showCreditApplications = async () => {
    const { client_accountno } = this.state;
    const kycResponce = await getCreditApplications(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("showCreditApplications: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
        processing_request: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        credit_applications: kycResponce.data.data,
        processing_request: false,
        assignedLoader: false,
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
        processing_request: false,
        assignedLoader: false,
      });
    }
    // window.$("#modalCreditApplications").modal("show");
  };

  editClientModal = async () => {
    const { client_accountno } = this.state;
    const getClientInfoResponce = await getClientInfo(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log(
      "getClientInfoResponce.data.data : ",
      getClientInfoResponce.data.data
    );
    if (
      getClientInfoResponce.data.data.status === 403 ||
      getClientInfoResponce.data.data.errors === "authentication missing" ||
      getClientInfoResponce.data.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      getClientInfoResponce.data.data.status === 404 ||
      getClientInfoResponce.data.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      getClientInfoResponce.data.data.status === 200 &&
      getClientInfoResponce.data.data.message === "success"
    ) {
      window.$("#modalUpdateClient").modal("show");
      this.setState({
        update_client_account_no: client_accountno,
        client_comp_name: getClientInfoResponce.data.data.comp_name,
        client_update_name: getClientInfoResponce.data.data.name,
        client_title: getClientInfoResponce.data.data.title,
        client_update_email: getClientInfoResponce.data.data.email,
        client_phone: getClientInfoResponce.data.data.phone,
        client_address: getClientInfoResponce.data.data.address,
        client_address2: getClientInfoResponce.data.data.address2,
        client_country: getClientInfoResponce.data.data.country,
        client_city: getClientInfoResponce.data.data.city,
        client_state: getClientInfoResponce.data.data.state,
        client_zip: getClientInfoResponce.data.data.zip,
        client_account_status: getClientInfoResponce.data.data.account_status,
      });
    } else {
      // window.location.replace("/error");
    }
    // console.log('edit client account_no : ',tableMeta.rowData[0]);
  };

  formUpdateClient = async () => {
    let {
      client_accountno,
      client_title,
      client_update_name,
      client_update_email,
      client_password,
      client_confirm_password,
      client_phone,
      client_comp_name,
      client_state,
      client_address,
      client_city,
      client_zip,
      client_country,
      client_account_status,
      statusChanged,
      client_address2
    } = this.state;
    const modalBody = document.getElementById("modalUpdateClient");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    let is_validated = true;

    if (!client_comp_name || client_comp_name === "") {
      this.setState({
        errorClientCompName: "error",
        errorClientPhone: "",
        errorClientName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Company name is required.",
      });
      is_validated = false;
    } else if (!client_update_name || client_update_name === "") {
      this.setState({
        errorClientPhone: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientName: "error",
        errorClientMessageEdit: "Name is required.",
      });
      is_validated = false;
    } else if (!client_phone || client_phone === "") {
      this.setState({
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientPhone: "error",
        errorClientMessageEdit: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(client_phone)) {
      this.setState({
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientPhone: "error",
        errorClientMessageEdit: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(client_update_email) === false) {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientPass: "",
        errorClientEmail: "error",
        errorClientMessageEdit: "Invalid email format.",
      });
      is_validated = false;
    } else if (!client_address || client_address === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Address is required.",
      });
      is_validated = false;
    } else if (!client_city || client_city === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "error",
        errorClientMessageEdit: "City is required.",
      });
      is_validated = false;
    } else if (!client_state || client_state === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "",
        errorClientState: "error",
        errorClientMessageEdit: "State is required.",
      });
      is_validated = false;
    } else if (client_zip === null || client_zip === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientCity: "",
        errorClientState: "",
        errorClientZip: "error",
        errorClientMessageEdit: "Zip/postal code is required.",
      });
      is_validated = false;
    } else if (!client_country || client_country === "") {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
        errorClientMessageEdit: "Country is required.",
      });
      is_validated = false;
    } else if (client_password && client_password !== "") {
      if (client_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (client_confirm_password === "") {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Confirm password is required.",
        });
        is_validated = false;
      } else if (client_confirm_password.length < 8) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit:
            "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (client_password.trim() !== client_confirm_password.trim()) {
        this.setState({
          errorClientPhone: "",
          errorClientName: "",
          errorClientCompName: "",
          errorClientEmail: "",
          errorClientPass: "error",
          errorClientMessageEdit: "Password and confirm password do not match.",
        });
        is_validated = false;
      }
    } else if (client_account_status === "") {
      this.setState({
        errorMessageEdit: "Account Status is required.",
        disabled: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      this.setState({
        errorClientPhone: "",
        errorClientName: "",
        errorClientCompName: "",
        errorClientEmail: "",
        errorClientPass: "",
      });
      let apiResponce = await editClient(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        client_title,
        client_update_name,
        client_update_email,
        client_password,
        client_phone,
        client_comp_name,
        client_address,
        client_city,
        client_state,
        client_zip,
        client_country,
        this.state.notifyCheckBox.toString(),
        client_account_status,
        statusChanged,
        client_address2
      );
      console.log("Edit Client RES: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";
        this.setState({
          errorClientMessageEdit: formattedErrorMessage,
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successClientMessageEdit: "Client updated successfully.",
          errorClientMessageEdit: "",
          tableLoader: false,
        });
        setTimeout(async function () {
          //window.$("#modalFormDetail").modal("hide");
          window.location.reload();
        }, 3000);
      } else if (
        apiResponce.data.status === 404 &&
        apiResponce.data.message === "fail"
      ) {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorClientMessageEdit: "Something went wrong,try again later.",
          successClientMessageEdit: "",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  resendActivation = async () => {
    let { client_accountno } = this.state;
    this.setState({
      activation_button: true,
      activation_tick: false,
      activation_cross: false,
    });
    window.$("#modalAlertResend").modal("show");
    const kycResponce = await resendActivation(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("resendActivation: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        activation_button: false,
        activation_tick: true,
        activation_cross: false,
      });
    } else {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: true,
      });
    }
    setTimeout(() => {
      this.setState({
        activation_button: false,
        activation_tick: false,
        activation_cross: false,
      });
      window.$("#modalAlertResend").modal("hide");
    }, 4000);
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          client_city: city && city !== "" ? city : "",
          client_state: state && state !== "" ? state : "",
          client_zip: zipCode && zipCode !== "" ? zipCode : "",
          client_country: country && country !== "" ? country : "",
          client_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
            client_address2: address2 && address2 !== "" ? address2 : "", 
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  clientLoginSso = async (value, tableMeta) => {
    let { client_accountno } = this.state;
    this.setState({
      loginErrorMessage: "",
      login_url: "",
    });
    window.$("#modalDefaultLogin").modal("show");
    const servicesResponce = await clientLoginSso(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("agentLogin.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        login_url: servicesResponce.data.login_url,
      });

      window.open(servicesResponce.data.login_url, "_blank");
      window.$("#modalDefaultLogin").modal("hide");
    } else {
      this.setState({
        loginErrorMessage:
          "There is some error while authenticating the account.",
      });
    }
  };

  handleChangeCountry = (event) => {
    const country = event.label;
    this.setState({
      client_country: country,
    });
  };

  scrollToDiv = (cont_id) => {
    const targetDiv = document.getElementsByClassName(cont_id);

    if (targetDiv) {
      const targetPosition = targetDiv.offsetTop;

      const scroll = () => {
        const currentPosition = window.scrollY;
        const distance = targetPosition;

        const easeOutCubic = (t) => t * (2 - t);

        const duration = 800; // Adjust the duration as needed
        let start = null;

        const step = (timestamp) => {
          if (!start) start = timestamp;

          const progress = timestamp - start;
          const percentage = progress / duration;

          window.scrollTo(
            0,
            currentPosition + distance * easeOutCubic(percentage)
          );

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };

        window.requestAnimationFrame(step);
      };

      scroll();
    }
  };

  archived_agreements = async (client_accountno) => {
    console.log("archived_agreements client_accountno: ", client_accountno);

    const kycResponce = await getClientArchivedAgreements(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("archived_agreements: ", kycResponce.data);
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageAgreements:
          "There is an error while getting the credit applications. Please try again later.",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        archivedAgreements: kycResponce.data.data,
        processing_request_archived: false,
        assignedLoader: false,
        columnServices: [
          { name: "agreement_title", label: "Agreement Name" },
          {
            name: "archived_date",
            label: "Archived date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "signed_date",
            label: "Signed Date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "approved",
            label: "Approved",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_approved(value)}</div>;
              },
            },
          },
          {
            name: "pdf_path",
            label: "Download",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_download(value, tableMeta)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.get_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Delete Agreement"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.delete_archived_details(
                                          value,
                                          tableMeta
                                        )
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-edit"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      this.setState({
        errorMessageAgreements: "",
        processing_request_archived: false,
        assignedLoader: false,
      });
    }
  };

  format_approved = (value) => {
    if (value === "Yes") {
      return (
        <Tooltip title="This agreement is approved." placement="top">
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_download = (value, tableMeta) => {
    if (value !== "") {
      return (
        <a
          onClick={() => this.downloadPdf(value, tableMeta.rowData[0])}
          title="Download Agreement"
          style={{
            cursor: "pointer",
          }}
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em>
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> --
        </span>
      );
    }
  };

  get_archived_details = async (value, tableMeta) => {
    const { client_accountno } = this.state;
    let archive_id = tableMeta.rowData[5];
    console.log("get_archived_details archive_id: ", archive_id);

    const kycResponce = await getClientArchivedAgreementsSingle(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      archive_id
    );
    console.log("get_archived_details: ", kycResponce.data);
    window.$("#modalArchivedDetails").modal("show");
    if (
      kycResponce.data.status === 403 ||
      kycResponce.data.errors === "authentication missing" ||
      kycResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (kycResponce.data.status === 406) {
      this.setState({
        errorMessageArchivedDetails:
          "There is an error while getting the credit applications. Please try again later.",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 404 &&
      kycResponce.data.message === "not_exists"
    ) {
      this.setState({
        errorMessageArchivedDetails: "",
      });
      //window.location.replace("/error");
    } else if (
      kycResponce.data.status === 200 &&
      kycResponce.data.message === "success"
    ) {
      this.setState({
        archivedAgreementsDetails: kycResponce.data.data,
      });
    } else {
      this.setState({
        errorMessageArchivedDetails: "",
      });
    }
  };

  delete_archived_details = async (value, tableMeta) => {
    const { client_accountno } = this.state;
    let archive_id = tableMeta.rowData[5];
    this.setState({
      delete_archive_id: archive_id,
      successMessageDelete: "",
      errorMessageDelete: "",
      disabled: false,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  delete_archived_agreement = async () => {
    const { delete_archive_id, client_accountno } = this.state;
    this.setState({
      successMessageDelete: "",
      errorMessageDelete: "",
      disabled: true,
    });
    const agreementResponce = await deleteClientArchivedAgreement(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      delete_archive_id
    );

    console.log("deleteAgreement: ", agreementResponce.data);
    //$("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessageDelete: "There is some error while processing the request.",
        successMessageDelete: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      //await this.reLoadClients();
      this.setState({
        errorMessageDelete: "",
        successMessageDelete: "Archived agreement deleted successfully.",
        //disabled: false,
      });

      setTimeout(() => {
        window.$("#modalDeleteAlert").modal("hide");
        this.archived_agreements(client_accountno);
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessageDelete: "There is some error while processing the request.",
        successMessageDelete: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  agentAssignRole = async (agentId, agentEmail, company, name) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      agentRoleLoader: true,
      disableRoleBtn: false,
      disableSubRoleBtn: false,
      rolesAgentName: company + " (" + name + ")",
      checkedName: name,
      checkedEmail: agentEmail,
    });
    window.$("#modalAgentAssignRole").modal("show");
    this.getAgentRoleData(agentId, agentEmail);
  };

  getAgentRoleData = async (agentId, agentEmail) => {
    const listAgentAssignRoles = await ListClientAssignRoles(
      auth.getAccount(),
      auth.getToken(),
      agentId,
      agentEmail
    );

    console.log("ListAgentRoles Res: ", listAgentAssignRoles.data.data);
    if (
      listAgentAssignRoles.data.status === 403 ||
      listAgentAssignRoles.data.errors === "authentication missing" ||
      listAgentAssignRoles.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentAssignRoles.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentAssignRoles.data.status === 200 &&
      listAgentAssignRoles.data.message === "success"
    ) {
      const assignRoleData = listAgentAssignRoles.data.data.roles;
      const assignSubRoleData = listAgentAssignRoles.data.data.subroles;
      const allRoleData = listAgentAssignRoles.data.data.Allroles.map(
        (role) => {
          const isActiveRole = assignRoleData.some(
            (assignedRole) =>
              assignedRole.alt === role.alt && assignedRole.isactive === 1
          );
          return {
            ...role,
            isChecked: isActiveRole,
          };
        }
      );

      const allSubRoleData = listAgentAssignRoles.data.data.Allsubroles.map(
        (subRole) => {
          const isActiveSubRole = assignSubRoleData.some(
            (assignedSubRole) =>
              assignedSubRole.alt === subRole.alt &&
              assignedSubRole.isactive === 1
          );
          return {
            ...subRole,
            isChecked: isActiveSubRole,
          };
        }
      );

      this.setState({
        assignRoleData,
        assignSubRoleData,
        allRoleData,
        allSubRoleData,
        agentRoleLoader: false,
      });

      const initialActiveRoles = assignRoleData
        .filter((role) => role.isactive === 1)
        .map((role) => ({
          alt: role.alt,
          isChecked: true,
        }));

      const initialActiveSubRoles = assignSubRoleData
        .filter((subRole) => subRole.isactive === 1)
        .map((subRole) => ({
          id: subRole.id,
          isChecked: true,
        }));

      this.setState({
        changedRolesRights: initialActiveRoles,
        changedSubRolesRights: initialActiveSubRoles,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  handleSubmitRoleCheckbox = async (name, email) => {
    this.setState({
      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      disableRoleBtn: true,
    });

    const { allRoleData, allSubRoleData } = this.state;

    // Prepare the CheckRoleFields array with IDs and isactive status
    const CheckRoleFields = allRoleData.map((role) => ({
      id: role.id,
      isactive: role.isChecked ? 1 : 0,
    }));

    const CheckSubRoleFields = allSubRoleData.map((role) => ({
      id: role.tabdetailid,
      isactive: role.isChecked ? 1 : 0,
    }));

    try {
      // Call the backend API to save the roles
      const saveResponce = await editClientAssignRole(
        auth.getAccount(),
        auth.getToken(),
        CheckRoleFields,
        CheckSubRoleFields,
        name,
        email
      );

      // Handle response status
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        this.setState({
          errorMessageAgentRole:
            "There is some error while updating the client Role.",
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessageAgentRole: "",
          successMessageAgentRole: "Client Role updated successfully.",
          disableRoleBtn: false,
        });
        setTimeout(() => {
          window.$("#modalAgentAssignRole").modal("hide");
        }, 3000);
      } else {
        this.setState({
          errorMessageAgentRole: saveResponce.data.message,
          successMessageAgentRole: "",
          disableRoleBtn: false,
        });
      }
    } catch (error) {
      console.error("Error while saving roles:", error);
      this.setState({
        errorMessageAgentRole: "An error occurred while saving roles.",
        successMessageAgentRole: "",
        disableRoleBtn: false,
      });
    }
  };

  changedSubRolesRights = (event) => {
    const { name, checked, Id } = event.target;
    const { changedSubRolesRights } = this.state;

    const index = changedSubRolesRights.findIndex(
      (item) => item.id === parseInt(name)
    );

    if (index !== -1) {
      const updatedchangedSubRolesRights = [...changedSubRolesRights];
      updatedchangedSubRolesRights[index] = {
        id: parseInt(name),
        isChecked: checked,
      };
      this.setState({ changedSubRolesRights: updatedchangedSubRolesRights });
    } else {
      this.setState((prevState) => ({
        changedSubRolesRights: [
          ...prevState.changedSubRolesRights,
          {
            id: parseInt(name),
            isChecked: checked,
          },
        ],
      }));
    }
  };

  changedRolesRights = (event) => {
    const { name, checked } = event.target;
    const { assignRoleData, assignSubRoleData } = this.state;

    // Find the main role in assignRoleData
    const mainRole = assignRoleData.find((item) => item.id === parseInt(name));

    if (!mainRole) {
      return;
    }

    // Update the checked state of the main role
    const updatedChangedRolesRights = this.state.changedRolesRights.map(
      (item) =>
        item.id === mainRole.id ? { ...item, isChecked: checked } : item
    );

    // If the main role is not found in changedRolesRights, add it
    if (!updatedChangedRolesRights.some((item) => item.id === mainRole.id)) {
      updatedChangedRolesRights.push({ ...mainRole, isChecked: checked });
    }

    // Find the associated sub roles
    const associatedSubRoles = assignSubRoleData.filter(
      (subRole) => subRole.parent_role_id === mainRole.parent_role_id
    );

    // Update the checked state of associated sub roles
    const updatedSubRolesRights = this.state.changedSubRolesRights.map(
      (subRole) => {
        // Check if the sub role is associated with the main role
        const isAssociated = associatedSubRoles.some(
          (assocSubRole) => assocSubRole.id === subRole.id
        );

        // If associated, update isChecked based on the checked state of the main role
        if (isAssociated) {
          return { ...subRole, isChecked: checked };
        } else {
          return subRole;
        }
      }
    );

    // If associated sub roles are not found in changedSubRolesRights, add them
    associatedSubRoles.forEach((assocSubRole) => {
      if (
        !updatedSubRolesRights.some((subRole) => subRole.id === assocSubRole.id)
      ) {
        updatedSubRolesRights.push({ ...assocSubRole, isChecked: checked });
      }
    });

    // Update the state with the modified arrays
    this.setState({
      changedRolesRights: updatedChangedRolesRights,
      changedSubRolesRights: updatedSubRolesRights,
    });
  };

  changedAllRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedRoleIds in the state
    const { checkedRoleIds } = this.state;
    const updatedCheckedRoleIds = checked
      ? [...checkedRoleIds, name] // add ID if checked
      : checkedRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedRoleIds: updatedCheckedRoleIds });

    // Update allRoleData with isChecked property
    const { allRoleData, allSubRoleData } = this.state;
    const updatedAllRoleData = allRoleData.map((role) => {
      if (role.id.toString() === name) {
        return {
          ...role,
          isChecked: checked,
        };
      }
      return role;
    });

    // Update associated sub roles
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.role_id.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({
      allRoleData: updatedAllRoleData,
      allSubRoleData: updatedAllSubRoleData,
    });
  };

  changedAllSubRolesRights = (event) => {
    const { name, checked } = event.target;

    // Update checkedSubRoleIds in the state
    const { checkedSubRoleIds } = this.state;
    const updatedCheckedSubRoleIds = checked
      ? [...checkedSubRoleIds, name] // add ID if checked
      : checkedSubRoleIds.filter((id) => id !== name); // remove ID if unchecked

    this.setState({ checkedSubRoleIds: updatedCheckedSubRoleIds });

    // Update allSubRoleData with isChecked property
    const { allSubRoleData } = this.state;
    const updatedAllSubRoleData = allSubRoleData.map((subRole) => {
      if (subRole.tabdetailid.toString() === name) {
        return {
          ...subRole,
          isChecked: checked,
        };
      }
      return subRole;
    });

    this.setState({ allSubRoleData: updatedAllSubRoleData });
  };

  openSigneeDetails = async (agreement_accountno) => {
    const { client_accountno } = this.state;
    let selected_client_accountno = client_accountno;
    let selected_agreement_id = agreement_accountno;
    this.setState({
      selected_client_accountno: selected_client_accountno,
      selected_agreement_id: selected_agreement_id,
      signee_details: [],
    });
    const servicesResponce = await getClientAgreementSigneeDetails(
      auth.getAccount(),
      auth.getToken(),
      selected_client_accountno,
      selected_agreement_id
    );
    console.log("openSigneeDetails.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "signee_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Signee details not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "agent_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Agent not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signee_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalOpenSignee").modal("show");
  };

  openUploadContract = async (agreement_accountno) => {
    const { client_accountno } = this.state;
    let selected_client_accountno = client_accountno;
    let selected_agreement_id = agreement_accountno;
    this.setState({
      selected_client_accountno: selected_client_accountno,
      selected_agreement_id: selected_agreement_id,
      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      add_picture: "",
      add_picture_name: "",
    });
    window.$("#modalOpenUploadContract").modal("show");
  };

  submitClientContract = async () => {
    let {
      selected_client_accountno,
      selected_agreement_id,
      add_picture,
      add_picture_name,
    } = this.state;
    this.setState({
      upload_agreement_button: true,
    });
    if (add_picture_name === "") {
      this.setState({
        uploadErrorMessage: "Agreement file is required.",
        uploadErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          uploadErrorTitle: "",
          uploadErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("client_accountno", Number(selected_client_accountno));
      formData.append("agreement_id", Number(selected_agreement_id));

      try {
        axios
          .post(
            AGENT_SERVER_URL +
              "signed_agreements_external_api_calls/upload_client_agreement",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                uploadErrorMessage: "Record not found.",
                uploadErrorTitle: "System Error",
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage: response.data.message,
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                uploadSuccessMessage: "Agreement added successfully.",
                uploadErrorMessage: "",
                uploadErrorTitle: "",
                upload_agreement_button: true,
              });
              //this.reloadClients();
              setTimeout(() => {
                window.$("#modalOpenUploadContract").modal("hide");
              }, 4000);
            } else {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage:
                  "There is some error while adding the agreement.",
                uploadSuccessMessage: "",
                upload_agreement_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          uploadErrorTitle: "System Error",
          uploadErrorMessage: "There is some error while adding the agreement.",
          disabled: false,
          uploadSuccessMessage: "",
          upload_agreement_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          uploadErrorMessage: "",
          uploadErrorTitle: "",
        });
      }, 4000);
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };


  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".zip",
      ".txt",
      ".rtf",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content " id="Clients_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}

          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Users /{" "}
                      <strong class="text-primary small">
                        {this.state.client_update_name} (
                        {this.state.client_comp_name})
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          User ID:{" "}
                          <span class="text-base">
                            {this.state.client_accountno}
                          </span>
                        </li>
                        {/*<li>
                          Last Login:{" "}
                          <span class="text-base">15 Feb, 2019 01:02 PM</span>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistoryLeft("/clients")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistoryLeft("/clients")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap clientsView">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card viewClient_nav-tabs_customStyle">
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "agreements"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("agreements");
                              this.clientAgreements();
                            }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Agreements</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "assigned_products"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("assigned_products");
                              this.showAssignedServices();
                            }}
                          >
                            <em class="icon ni ni-file-text"></em>
                            <span>Assigned Products</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "kyc_form"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("kyc_form");
                              this.showDetails();
                            }}
                          >
                            <em className="icon ni ni-user-list"></em>
                            <span>KYC Form</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "notes" ? "active" : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("notes");
                              this.clientServices();
                            }}
                          >
                            <em className="icon ni ni-file-docs"></em>
                            <span>Client Notes</span>
                          </a>
                        </li>
                        <li
                          class="nav-item viewClients_nav-item_customStyle"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "credit_app"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("credit_app");
                              this.showCreditApplications();
                            }}
                          >
                            <em className="icon ni ni-coin"></em>
                            <span>Credit Applications</span>
                          </a>
                        </li>
                        <li>
                          <div className="dropdown mt-3">
                            <a
                              className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                              data-toggle="dropdown"
                              data-offset="0,5"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-md  dropdown-menu-s">
                              <ul className="link-list-plain">
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.openLinkInNewTab(
                                        "/Quotations/" +
                                          this.state.client_accountno
                                      )
                                    }
                                    title="Quotations"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>Quotations</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.openLinkInNewTab(
                                        "/Rfqs/" + this.state.client_accountno
                                      )
                                    }
                                    title="RFQs"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>RFQs</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.openLinkInNewTab(
                                        "/SalesOrders/" +
                                          this.state.client_accountno
                                      )
                                    }
                                    title="Sale Orders"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>Sale Orders</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.agentAssignRole(
                                        this.state.client_accountno,
                                        this.state.client_update_email,
                                        this.state.client_comp_name,
                                        this.state.client_update_name
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-users"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>Control Panel</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      this.changeActiveTab(
                                        "archived_agreements"
                                      );
                                      this.archived_agreements(
                                        this.state.client_accountno
                                      );
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-file-text"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>Archived Agreements</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.openLinkInNewTab(
                                        "/clients/subclients/" +
                                          this.state.client_accountno
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-users"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>Sub Clients</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "assigned_products"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemProducts"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <h5 class="title">
                                  Assigned Products & Services
                                </h5>
                                <p>
                                  This list comprises the products or services
                                  allocated or permitted for this client.
                                </p>
                              </div>
                              {this.state.assignedErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.assignedErrorMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.assignedSuccessMessage}</p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.assignedLoader === true ? (
                                FormLoader()
                              ) : (
                                <div class="table-responsive">
                                  {this.state.serviceAssignedData &&
                                  this.state.serviceAssignedData.length > 0 ? (
                                    <>
                                      <table class="table table-striped">
                                        <thead>
                                          <tr>
                                            <th>
                                              <div class="custom-control custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={this.state.checkAll}
                                                  class="custom-control-input"
                                                  name="selected_products_1"
                                                  onChange={
                                                    this.handleCheckAllChange
                                                  }
                                                  id="product_ids"
                                                />
                                                <label
                                                  class="custom-control-label"
                                                  htmlFor="product_ids"
                                                ></label>
                                              </div>
                                            </th>
                                            <th>Item</th>
                                            <th>SKU</th>
                                            <th>Title</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.serviceAssignedData.map(
                                            (row, index) => (
                                              <tr key={`prokey${index}`}>
                                                <td>
                                                  <div class="custom-control custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      checked={row.checked}
                                                      class="custom-control-input"
                                                      name="selected_products"
                                                      onChange={() =>
                                                        this.handleCheckboxChange(
                                                          row.id
                                                        )
                                                      }
                                                      value={row.id}
                                                      id={`product_id_${index}`}
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      htmlFor={`product_id_${index}`}
                                                    ></label>
                                                  </div>
                                                </td>
                                                <td>{row.id}</td>
                                                <td>
                                                  {row.sku && row.sku !== ""
                                                    ? row.sku
                                                    : "---"}
                                                </td>
                                                <td>{row.service_title}</td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                      <div className="col-sm-12 mt-4 d-flex justify-content-end">
                                        <div class="nk-block-head-content">
                                          <ul class="nk-block-tools gx-3">
                                            {this.state
                                              .update_service_button ===
                                            true ? (
                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                disabled
                                              >
                                                <span
                                                  className="spinner-grow spinner-grow-sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                                <span> Processing... </span>
                                              </button>
                                            ) : (
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    this.updateAssignedServices()
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                  }}
                                                  class="btn btn-primary"
                                                >
                                                  <em class="icon ni ni-check-round-cut"></em>{" "}
                                                  <span>Update</span>{" "}
                                                </a>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div class="alert alert-pro alert-primary">
                                      <div class="alert-text">
                                        <h6>No Service Assigned</h6>
                                        <p>
                                          No service has been assigned to this
                                          client.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "agreements"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="nk-block-between mb-4">
                                  <div className="nk-block-head-content">
                                    <h5 className="nk-block-title page-title">
                                      Agreements
                                    </h5>
                                  </div>
                                  <div
                                    className="nk-block-head-content"
                                    id="HeadContent"
                                  >
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li className="nk-block-tools-opt">
                                            <a
                                              style={{
                                                cursor: "pointer",
                                                color: "#ffffff",
                                              }}
                                              onClick={(e) => {
                                                this.changeActiveTab(
                                                  "archived_agreements"
                                                );
                                                this.archived_agreements(
                                                  this.state.client_accountno
                                                );
                                              }}
                                              className="btn btn-primary"
                                            >
                                              <em className="icon ni ni-file-text"></em>
                                              &nbsp; Archived Agreements
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.isRevokeReasonActive ? (
                                          <div id="revokeReasonItems">
                                            <div className="tb-odr-id">
                                              Reason to revoke
                                            </div>
                                            <div className="card card-bordered card-preview mt-1">
                                              <TextareaAutosize
                                                maxrows={4}
                                                name="revoke_reason"
                                                id="revoke_reason"
                                                aria-label=""
                                                placeholder=""
                                                value={this.state.revoke_reason}
                                                helperText="Reason to revoke this agreement"
                                                onChange={
                                                  this.handleRevokeValueChange
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100px",
                                                  borderColor:
                                                    "rgba(0, 0, 0, 0.125)",
                                                  borderRadius: "4px",
                                                }}
                                                variant="outlined"
                                              />
                                            </div>
                                            <div
                                              className="form-group text-right"
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                style={{ marginRight: 5 }}
                                                className="btn btn-light"
                                                onClick={() => {
                                                  this.setState({
                                                    isRevokeReasonActive: false,
                                                    revoke_reason: "",
                                                    errorMessage: "",
                                                    successMessage: "",
                                                  });
                                                }}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                  this.handleClientAgreementRevokeSubmit(
                                                    this.state
                                                      .revokeAgreementData
                                                      .agreement_accountno,
                                                    this.state.client_accountno,
                                                    this.state.revoke_reason
                                                  );
                                                }}
                                                disabled={this.state.disabled}
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}

                                        {this.state.isApproveActive ? (
                                          <div className="row g-gs">
                                            <div className="col-md-12 mb-3">
                                              <div class="card card-bordered">
                                                <div class="card-inner">
                                                  <h5 class="card-title">
                                                    Approve Agreement
                                                  </h5>

                                                  <p class="card-text">
                                                    Are you sure you want to
                                                    approve this agreement?
                                                  </p>

                                                  <div className="col-md-12">
                                                    <div className="form-group text-right">
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.setState({
                                                            isApproveActive: false,
                                                            approve_agreement_accountno:
                                                              "",
                                                            approve_agreement_id:
                                                              "",
                                                          })
                                                        }
                                                        className="btn btn-light"
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                        style={{
                                                          marginRight: "15px",
                                                        }}
                                                      >
                                                        No
                                                      </button>
                                                      <button
                                                        type="button"
                                                        onClick={() =>
                                                          this.approveAgreement(
                                                            this.state
                                                              .approve_agreement_accountno
                                                          )
                                                        }
                                                        className="btn btn-success"
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                        title=""
                                                      >
                                                        Yes
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="card card-bordered card-preview">
                                          <table className="table table-orders viewClient_table-orders_customStyle">
                                            <thead className="tb-odr-head">
                                              <tr className="tb-odr-item">
                                                <th>
                                                  <span className="tb-odr-id">
                                                    Agreement Name
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-date d-none d-md-inline-block">
                                                    Signed Date
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-total">
                                                    Approved
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-total">
                                                    Status
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="tb-odr-status d-none d-md-inline-block">
                                                    Download
                                                  </span>
                                                </th>
                                                <th className="tb-odr-action">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="tb-odr-body">
                                              {this.state.signedData &&
                                              this.state.signedData.length >
                                                0 ? (
                                                <>
                                                  {this.state.signedData.map(
                                                    (signed, index) => (
                                                      <tr
                                                        className="tb-odr-item"
                                                        key={`sign${index}`}
                                                      >
                                                        <td>
                                                          <span className="tb-odr-id">
                                                            {
                                                              signed.agreement_title
                                                            }
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-date">
                                                            {signed.signed_date &&
                                                            signed.signed_date !==
                                                              ""
                                                              ? this.format_date(
                                                                  signed.signed_date
                                                                )
                                                              : "---"}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-status">
                                                            {signed.approved ===
                                                            "Yes" ? (
                                                              <span className="badge badge-outline-success">
                                                                Yes
                                                              </span>
                                                            ) : (
                                                              <span className="badge badge-outline-danger">
                                                                No
                                                              </span>
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="tb-odr-status">
                                                            {signed.is_signed ===
                                                            "Yes" ? (
                                                              <span className="badge badge-outline-success">
                                                                Signed
                                                              </span>
                                                            ) : (
                                                              <span className="badge badge-outline-danger">
                                                                Unsigned
                                                              </span>
                                                            )}
                                                          </span>
                                                        </td>

                                                        <td>
                                                          {signed.pdf_path &&
                                                          signed.pdf_path !==
                                                            "" ? (
                                                            <a
                                                              onClick={() =>
                                                                this.downloadPdf(
                                                                  signed.pdf_path,
                                                                  signed.agreement_title
                                                                )
                                                              }
                                                              title="Download Agreement"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              <span className="badge badge-outline-primary">
                                                                <em className="icon ni ni-download"></em>
                                                              </span>
                                                            </a>
                                                          ) : (
                                                            <span className="badge badge-outline-light">
                                                              ---
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-odr-action">
                                                          <div className="dropdown">
                                                            <a
                                                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                              data-toggle="dropdown"
                                                              data-offset="-8,0"
                                                            >
                                                              <em className="icon ni ni-more-h"></em>
                                                            </a>
                                                            <div
                                                              className="dropdown-menu dropdown-menu-right dropdown-menu-xs"
                                                              style={{
                                                                padding: "0px",
                                                              }}
                                                            >
                                                              <ul className="link-list-plain">
                                                                {signed.is_signed ===
                                                                  "Yes" &&
                                                                signed.approved ===
                                                                  "No" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      className="text-success"
                                                                      onClick={() =>
                                                                        this.setState(
                                                                          {
                                                                            isApproveActive: true,
                                                                            isRevokeReasonActive: false,
                                                                            approve_agreement_id:
                                                                              signed.agreement_id,
                                                                            approve_agreement_accountno:
                                                                              signed.agreement_accountno,
                                                                          },
                                                                          () =>
                                                                            this.scrollToDiv(
                                                                              "card-content"
                                                                            )
                                                                        )
                                                                      }
                                                                    >
                                                                      <em class="icon ni ni-done"></em>
                                                                      <span>
                                                                        Approve
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {signed.is_signed ===
                                                                "Yes" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.revokeAgreement(
                                                                          signed.agreement_accountno
                                                                        )
                                                                      }
                                                                    >
                                                                      <em class="icon ni ni-na"></em>
                                                                      <span>
                                                                        Revoke
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {signed.is_signed ===
                                                                "Yes" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.openSigneeDetails(
                                                                          signed.agreement_accountno
                                                                        )
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Show signee details"
                                                                    >
                                                                      <em class="icon ni ni-table-view"></em>
                                                                      <span>
                                                                        Signee
                                                                        Details
                                                                      </span>
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                <li>
                                                                  <a
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.openUploadContract(
                                                                        signed.agreement_accountno
                                                                      )
                                                                    }
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Upload new contract"
                                                                  >
                                                                    <em class="icon ni ni-upload"></em>
                                                                    <span>
                                                                      Upload
                                                                      Agreement
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "kyc_form"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemKyc"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div
                                  className="modal-title"
                                  style={{ marginBottom: "2rem" }}
                                >
                                  <h5>
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    KYC Details
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke mb-4">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke mb-4">
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="card card-preview">
                                          {this.state.kyc_key === "" ? (
                                            <div className="nk-block">
                                              <div className="card card-bordered">
                                                <div className="card-inner card-inner-lg">
                                                  <div className="nk-help">
                                                    <div className="nk-help-img">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 90 90"
                                                      >
                                                        <rect
                                                          x="5"
                                                          y="7"
                                                          width="60"
                                                          height="56"
                                                          rx="7"
                                                          ry="7"
                                                          fill="#e3e7fe"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></rect>
                                                        <rect
                                                          x="25"
                                                          y="27"
                                                          width="60"
                                                          height="56"
                                                          rx="7"
                                                          ry="7"
                                                          fill="#e3e7fe"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></rect>
                                                        <rect
                                                          x="15"
                                                          y="17"
                                                          width="60"
                                                          height="56"
                                                          rx="7"
                                                          ry="7"
                                                          fill="#fff"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></rect>
                                                        <line
                                                          x1="15"
                                                          y1="29"
                                                          x2="75"
                                                          y2="29"
                                                          fill="none"
                                                          stroke="#6576ff"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <circle
                                                          cx="53"
                                                          cy="23"
                                                          r="2"
                                                          fill="#c4cefe"
                                                        ></circle>
                                                        <circle
                                                          cx="60"
                                                          cy="23"
                                                          r="2"
                                                          fill="#c4cefe"
                                                        ></circle>
                                                        <circle
                                                          cx="67"
                                                          cy="23"
                                                          r="2"
                                                          fill="#c4cefe"
                                                        ></circle>
                                                        <rect
                                                          x="22"
                                                          y="39"
                                                          width="20"
                                                          height="20"
                                                          rx="2"
                                                          ry="2"
                                                          fill="none"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></rect>
                                                        <circle
                                                          cx="32"
                                                          cy="45.81"
                                                          r="2"
                                                          fill="none"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></circle>
                                                        <path
                                                          d="M29,54.31a3,3,0,0,1,6,0"
                                                          fill="none"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></path>
                                                        <line
                                                          x1="49"
                                                          y1="40"
                                                          x2="69"
                                                          y2="40"
                                                          fill="none"
                                                          stroke="#6576ff"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <line
                                                          x1="49"
                                                          y1="51"
                                                          x2="69"
                                                          y2="51"
                                                          fill="none"
                                                          stroke="#c4cefe"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <line
                                                          x1="49"
                                                          y1="57"
                                                          x2="59"
                                                          y2="57"
                                                          fill="none"
                                                          stroke="#c4cefe"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <line
                                                          x1="64"
                                                          y1="57"
                                                          x2="66"
                                                          y2="57"
                                                          fill="none"
                                                          stroke="#c4cefe"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <line
                                                          x1="49"
                                                          y1="46"
                                                          x2="59"
                                                          y2="46"
                                                          fill="none"
                                                          stroke="#c4cefe"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                        <line
                                                          x1="64"
                                                          y1="46"
                                                          x2="66"
                                                          y2="46"
                                                          fill="none"
                                                          stroke="#c4cefe"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        ></line>
                                                      </svg>
                                                    </div>
                                                    <div className="nk-help-text">
                                                      <h5>
                                                        Initiate KYC Process
                                                      </h5>
                                                      <p className="text-soft">
                                                        Register your customer
                                                        with KYC to complete
                                                        their profile. Click on
                                                        "Initiate KYC" button.
                                                        An email with KYC
                                                        registration link will
                                                        be sent to the customer.
                                                      </p>
                                                    </div>
                                                    <div className="nk-help-action">
                                                      {this.state
                                                        .loading_button ===
                                                      false ? (
                                                        <a
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            this.initiateKyc();
                                                          }}
                                                          disabled={
                                                            this.state.disabled
                                                          }
                                                          href="#"
                                                          className="btn btn-lg btn-outline-primary"
                                                        >
                                                          <em className="icon ni ni-file-docs"></em>{" "}
                                                          Initiate KYC
                                                        </a>
                                                      ) : null}
                                                      {this.state
                                                        .loading_button ===
                                                      true ? (
                                                        <button
                                                          className="btn btn-primary btn-lg"
                                                          type="button"
                                                          disabled
                                                        >
                                                          <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                          ></span>
                                                          <span>
                                                            {" "}
                                                            Initiating...{" "}
                                                          </span>
                                                        </button>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}

                                          {this.state.kyc_key !== "" ? (
                                            <div id="printablediv">
                                              <div className="nk-content-body">
                                                <div className="nk-block-head nk-block-head-sm">
                                                  <div className="nk-block-between g-3">
                                                    <div className="nk-block-head-content">
                                                      <h5 className="nk-block-title">
                                                        KYCs /{" "}
                                                        <strong className="text-primary small">
                                                          {
                                                            this.state
                                                              .client_update_name
                                                          }
                                                        </strong>
                                                      </h5>
                                                      <div className="nk-block-des text-soft">
                                                        <ul className="list-inline">
                                                          <li>
                                                            Submited At:{" "}
                                                            <span className="text-base">
                                                              {
                                                                this.state
                                                                  .client_register_date
                                                              }
                                                            </span>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>

                                                    <div className="nk-block-head-content">
                                                      <div className="btn-group">
                                                        {this.state
                                                          .kyc_approved ===
                                                          "No" ||
                                                        this.state
                                                          .kyc_approved ===
                                                          "" ? (
                                                          <>
                                                            {this.state
                                                              .completed_percentage ===
                                                            "100" ? (
                                                              <>
                                                                <button
                                                                  onClick={() =>
                                                                    this.showApprovedCont(
                                                                      "approve"
                                                                    )
                                                                  }
                                                                  type="button"
                                                                  className="btn btn-outline-primary"
                                                                  disabled={
                                                                    this.state
                                                                      .disabled
                                                                  }
                                                                >
                                                                  <em className="icon ni ni-check-round"></em>
                                                                  <span>
                                                                    Approve{" "}
                                                                  </span>
                                                                </button>

                                                                <button
                                                                  onClick={() =>
                                                                    this.showApprovedCont(
                                                                      "reject"
                                                                    )
                                                                  }
                                                                  type="button"
                                                                  className="btn btn-outline-danger"
                                                                  disabled={
                                                                    this.state
                                                                      .disabled
                                                                  }
                                                                >
                                                                  <em className="icon ni ni-cross-round"></em>
                                                                  <span>
                                                                    Reject{" "}
                                                                  </span>
                                                                </button>
                                                              </>
                                                            ) : (
                                                              <button
                                                                onClick={() => {
                                                                  this.setState(
                                                                    {
                                                                      showSendEmail: true,
                                                                    }
                                                                  );
                                                                }}
                                                                type="button"
                                                                className="btn btn-outline-primary"
                                                                disabled={
                                                                  this.state
                                                                    .disabled
                                                                }
                                                              >
                                                                <em className="icon ni ni-emails"></em>
                                                                <span>
                                                                  Send Email{" "}
                                                                </span>
                                                              </button>
                                                            )}
                                                          </>
                                                        ) : this.state
                                                            .kyc_approved ===
                                                          "Yes" ? (
                                                          <>
                                                            <button
                                                              type="button"
                                                              className="btn btn-success"
                                                              disabled={
                                                                this.state
                                                                  .disabled
                                                              }
                                                            >
                                                              <em className="icon ni ni-done"></em>
                                                              <span>
                                                                Approved{" "}
                                                              </span>
                                                            </button>
                                                            <button
                                                              onClick={() =>
                                                                this.showApprovedCont(
                                                                  "reject"
                                                                )
                                                              }
                                                              type="button"
                                                              className="btn btn-outline-danger"
                                                              disabled={
                                                                this.state
                                                                  .disabled
                                                              }
                                                            >
                                                              <em className="icon ni ni-cross-round"></em>
                                                              <span>
                                                                Reject Approval{" "}
                                                              </span>
                                                            </button>
                                                          </>
                                                        ) : null}

                                                        {this.state
                                                          .completed_percentage ===
                                                        "100" ? (
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              this.downloadKycPdf(
                                                                this.state
                                                                  .kyc_key
                                                              );
                                                            }}
                                                            className="btn btn-outline-primary"
                                                          >
                                                            <em className="icon ni ni-download"></em>
                                                            <span>
                                                              Download PDF
                                                            </span>
                                                          </button>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.state.showSendEmail ? (
                                                  <div id="showSendEmailItems">
                                                    <div className="form-group mt-1">
                                                      <MutextField
                                                        required
                                                        id="kyc_email_address"
                                                        name="kyc_email_address"
                                                        type="text"
                                                        label="Email Address"
                                                        value={
                                                          this.state
                                                            .kyc_email_address
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                      />
                                                    </div>
                                                    <div
                                                      className="form-group text-right"
                                                      style={{
                                                        marginTop: "15px",
                                                        marginBottom: "15px",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        style={{
                                                          marginRight: 5,
                                                        }}
                                                        className="btn btn-light"
                                                        onClick={() => {
                                                          this.setState({
                                                            showSendEmail: false,
                                                          });
                                                        }}
                                                      >
                                                        Cancel
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                          this.email_kyc_Details();
                                                        }}
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        Submit
                                                      </button>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                {this.state.showKycApproved ? (
                                                  <div id="showSendEmailItemss">
                                                    <h5>
                                                      {this.state
                                                        .showApprovedAction ===
                                                      "approve"
                                                        ? "Approve KYC"
                                                        : this.state
                                                            .showApprovedAction ===
                                                          "reject"
                                                        ? "Reject KYC"
                                                        : null}
                                                    </h5>
                                                    <div className="form-group mt-1">
                                                      <MutextField
                                                        required
                                                        id="kyc_reason"
                                                        name="kyc_reason"
                                                        type="text"
                                                        label="Reason"
                                                        defaultValue={
                                                          this.state.kyc_reason
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                      />
                                                    </div>
                                                    <div
                                                      className="form-group text-right"
                                                      style={{
                                                        marginTop: "15px",
                                                        marginBottom: "15px",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        style={{
                                                          marginRight: 5,
                                                        }}
                                                        className="btn btn-light"
                                                        onClick={() => {
                                                          this.setState({
                                                            showKycApproved: false,
                                                          });
                                                        }}
                                                      >
                                                        Cancel
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                          this.submit_client_kyc_approval();
                                                        }}
                                                        disabled={
                                                          this.state.disabled
                                                        }
                                                      >
                                                        Submit
                                                      </button>
                                                    </div>
                                                    {/*<div className="example-alert mb-4">
                                                      <div className="alert alert-light">
                                                        {this.state
                                                          .showApprovedAction ===
                                                        "approve" ? (
                                                          <>
                                                            <h5>
                                                              Approval
                                                              Classifications
                                                            </h5>
                                                            <ol className="list">
                                                              <li>
                                                                <strong>
                                                                  Approval A:
                                                                </strong>{" "}
                                                                This indicates a
                                                                direct,
                                                                authenticated
                                                                relationship
                                                                between the
                                                                Approver and the
                                                                customer or the
                                                                Agent's
                                                                customer. It
                                                                signifies that
                                                                the Approver
                                                                vouches for the
                                                                required
                                                                information
                                                                being either
                                                                already
                                                                available or
                                                                known to them.
                                                              </li>
                                                              <li>
                                                                <strong>
                                                                  Approval B:
                                                                </strong>{" "}
                                                                This signifies
                                                                that the
                                                                Approver has
                                                                obtained
                                                                information
                                                                about the
                                                                customer via the
                                                                Agent or
                                                                Sub-Agent. The
                                                                approval is
                                                                based on the
                                                                authenticity of
                                                                this information
                                                                or upon the
                                                                Agent's/Sub-Agent's
                                                                request.
                                                                However, it
                                                                implies the need
                                                                for further
                                                                information
                                                                verification or
                                                                that
                                                                verification is
                                                                in progress.
                                                              </li>
                                                              <li>
                                                                <strong>
                                                                  Approval C:
                                                                </strong>{" "}
                                                                This approval is
                                                                solely based on
                                                                an internal
                                                                request within
                                                                the office. It
                                                                doesn't indicate
                                                                any direct or
                                                                indirect
                                                                relationship
                                                                with the
                                                                customer or any
                                                                substantial
                                                                information
                                                                about the
                                                                customer's
                                                                identity.
                                                              </li>
                                                            </ol>
                                                          </>
                                                        ) : this.state
                                                            .showApprovedAction ===
                                                          "reject" ? (
                                                          <>
                                                            <h5>
                                                              Rejection
                                                              Classifications
                                                            </h5>
                                                            <ol className="list">
                                                              <li>
                                                                <strong>
                                                                  Approval A:
                                                                </strong>{" "}
                                                                This indicates a
                                                                direct,
                                                                authenticated
                                                                relationship
                                                                between the
                                                                Approver and the
                                                                customer or the
                                                                Agent's
                                                                customer. It
                                                                signifies that
                                                                the Approver
                                                                vouches for the
                                                                required
                                                                information
                                                                being either
                                                                already
                                                                available or
                                                                known to them.
                                                              </li>
                                                              <li>
                                                                <strong>
                                                                  Approval B:
                                                                </strong>{" "}
                                                                This signifies
                                                                that the
                                                                Approver has
                                                                obtained
                                                                information
                                                                about the
                                                                customer via the
                                                                Agent or
                                                                Sub-Agent. The
                                                                approval is
                                                                based on the
                                                                authenticity of
                                                                this information
                                                                or upon the
                                                                Agent's/Sub-Agent's
                                                                request.
                                                                However, it
                                                                implies the need
                                                                for further
                                                                information
                                                                verification or
                                                                that
                                                                verification is
                                                                in progress.
                                                              </li>
                                                              <li>
                                                                <strong>
                                                                  Approval C:
                                                                </strong>{" "}
                                                                This approval is
                                                                solely based on
                                                                an internal
                                                                request within
                                                                the office. It
                                                                doesn't indicate
                                                                any direct or
                                                                indirect
                                                                relationship
                                                                with the
                                                                customer or any
                                                                substantial
                                                                information
                                                                about the
                                                                customer's
                                                                identity.
                                                              </li>
                                                            </ol>
                                                          </>
                                                        ) : null}
                                                      </div>
                                                    </div>*/}
                                                  </div>
                                                ) : null}
                                                <div className="nk-block-head">
                                                  <div className="nk-block-head-content">
                                                    <h5 className="nk-block-title title">
                                                      Profile Completed{" "}
                                                      <span className="text-primary">
                                                        (
                                                        {
                                                          this.state
                                                            .completed_percentage
                                                        }
                                                        %)
                                                      </span>
                                                    </h5>
                                                    <div className="card card-bordered card-preview">
                                                      <div className="card-inner">
                                                        <div className="progress progress-lg">
                                                          <div
                                                            className="progress-bar"
                                                            data-progress={
                                                              this.state
                                                                .completed_percentage
                                                            }
                                                            style={{
                                                              width: `${this.state.completed_percentage}%`,
                                                            }}
                                                          >
                                                            {
                                                              this.state
                                                                .completed_percentage
                                                            }
                                                            %
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {this.state.kyc_details &&
                                              this.state.kyc_details.length >
                                                0 ? (
                                                <div
                                                  id="accordion"
                                                  className="accordion"
                                                >
                                                  {this.state.kyc_details.map(
                                                    (kyc, i) => (
                                                      <div
                                                        className="accordion-item"
                                                        key={`kycid${i}`}
                                                      >
                                                        <a
                                                          href="#"
                                                          className={this.handle_accordion_head(
                                                            i
                                                          )}
                                                          data-toggle="collapse"
                                                          data-target={`#accordion-item-${i}`}
                                                          style={{
                                                            background:
                                                              "rgb(245, 246, 250)",
                                                          }}
                                                        >
                                                          <h6 className="title">
                                                            {
                                                              kyc
                                                                .steps_information
                                                                .step_title
                                                            }
                                                            <br />
                                                            <small>
                                                              {
                                                                kyc
                                                                  .steps_information
                                                                  .step_description
                                                              }
                                                            </small>
                                                          </h6>
                                                          <span className="accordion-icon"></span>
                                                        </a>
                                                        <div
                                                          className={this.handle_accordion_body(
                                                            i
                                                          )}
                                                          id={`accordion-item-${i}`}
                                                          data-parent="#accordion"
                                                        >
                                                          <div className="accordion-inner">
                                                            {kyc.steps_data &&
                                                            kyc.steps_data
                                                              .length > 0 ? (
                                                              <div className="card">
                                                                <ul className="data-list is-compact">
                                                                  {kyc.steps_data.map(
                                                                    (
                                                                      kyc_steps,
                                                                      i
                                                                    ) => (
                                                                      <li
                                                                        className="data-item"
                                                                        key={`step${i}`}
                                                                      >
                                                                        <div className="data-col">
                                                                          <div className="data-label">
                                                                            {
                                                                              kyc_steps.field_title
                                                                            }
                                                                          </div>
                                                                          <div className="data-value">
                                                                            {kyc_steps.field_type ===
                                                                            "image" ? (
                                                                              <img
                                                                                src={
                                                                                  kyc_steps.field_value
                                                                                }
                                                                                style={{
                                                                                  width:
                                                                                    "60%",
                                                                                }}
                                                                              />
                                                                            ) : (
                                                                              kyc_steps.field_value
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "notes"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemNotes"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div
                                  className="modal-title"
                                  style={{ marginBottom: "2rem" }}
                                >
                                  <h5>
                                    <em className="icon ni ni-file-docs"></em>{" "}
                                    Notes
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="card card-preview">
                                          <div className="caption-text">
                                            <div className="col-lg-12 mt-4">
                                              <div className="form-group">
                                                <h6>Welcome Content</h6>
                                              </div>
                                              <div className="form-group">
                                                {/* {this.state.client_services_welcome_content ? (
                                                  <> */}
                                                <CKEditor
                                                  onReady={(editor) => {
                                                    console.log(
                                                      "Editor is ready to use!",
                                                      editor
                                                    );
                                                    this.setState({
                                                      isEditorReady: true,
                                                    });
                                                    // Ensure that the editable element exists before manipulating its parent
                                                    const editableElement =
                                                      editor.ui.getEditableElement();
                                                    if (
                                                      editableElement &&
                                                      editableElement.parentElement
                                                    ) {
                                                      // Insert the toolbar before the editable area.
                                                      editableElement.parentElement.insertBefore(
                                                        editor.ui.view.toolbar
                                                          .element,
                                                        editableElement
                                                      );
                                                    }

                                                    this.editor = editor;
                                                  }}
                                                  onError={(
                                                    error,
                                                    { willEditorRestart }
                                                  ) => {
                                                    if (willEditorRestart) {
                                                      // Remove the toolbar element if the editor is restarted
                                                      if (
                                                        this.editor &&
                                                        this.editor.ui &&
                                                        this.editor.ui.view &&
                                                        this.editor.ui.view
                                                          .toolbar
                                                      ) {
                                                        this.editor.ui.view.toolbar.element.remove();
                                                      }
                                                    }
                                                  }}
                                                  onChange={(event, editor) =>
                                                    this.handleEditorChange(
                                                      event,
                                                      editor
                                                    )
                                                  }
                                                  editor={DecoupledEditor}
                                                  data={
                                                    this.state
                                                      .client_services_welcome_content
                                                  }
                                                  config={{
                                                    cloudServices: {
                                                      tokenUrl: fetchToken,
                                                      uploadUrl:
                                                        AGENT_SERVER_URL +
                                                        "agents/editor_image_upload",
                                                    },
                                                    toolbar: {
                                                      items: [
                                                        "undo",
                                                        "redo",
                                                        "sourceEditing",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "underline",
                                                        "strikethrough",
                                                        "|",
                                                        "fontFamily",
                                                        "fontSize",
                                                        "fontColor",
                                                        "fontBackgroundColor",
                                                        "|",
                                                        "alignment",
                                                        "indent",
                                                        "outdent",
                                                        "|",
                                                        "numberedList",
                                                        "bulletedList",
                                                        "|",
                                                        "link",
                                                        "imageUpload",
                                                        "insertTable",
                                                        "|",
                                                        "codeBlock",
                                                        "blockQuote",
                                                        "|",
                                                        "removeFormat",
                                                        "htmlEmbed",
                                                        "|",
                                                        "fullscreen",
                                                        "undo",
                                                        "redo",
                                                      ],
                                                    },
                                                    fontSize: {
                                                      options: [
                                                        "8px",
                                                        "10px",
                                                        "12px",
                                                        "14px",
                                                        "16px",
                                                        "18px",
                                                        "20px",
                                                        "24px",
                                                        "28px",
                                                        "32px",
                                                        "36px",
                                                        "48px",
                                                      ],
                                                      supportAllValues: true, // Optional: Allows users to input custom values
                                                    },
                                                    fontColor: {
                                                      colors: [
                                                        {
                                                          color: "#333",
                                                          label: "Default",
                                                        },
                                                        // You can add more colors if needed
                                                      ],
                                                    },
                                                    style: {
                                                      definitions: [
                                                        {
                                                          element: "p",
                                                          styles: {
                                                            color: "#333",
                                                          },
                                                        },
                                                        {
                                                          element: "h1",
                                                          styles: {
                                                            color: "#333",
                                                          },
                                                        },
                                                        {
                                                          element: "h2",
                                                          styles: {
                                                            color: "#333",
                                                          },
                                                        },
                                                        {
                                                          element: "h3",
                                                          styles: {
                                                            color: "#333",
                                                          },
                                                        },
                                                      ],
                                                    },
                                                    language: "en",
                                                  }}
                                                />
                                                {/* </>
                                                ) : null} */}

                                                {/* <Editor
                                                  id="client_services_welcome_content"
                                                  name="client_services_welcome_content"
                                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                                  value={
                                                    this.state
                                                      .client_services_welcome_content
                                                  }
                                                  selector="textarea#full-featured"
                                                  init={{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: [
                                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                                    ],
                                                    toolbar:
                                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                                  }}
                                                  onEditorChange={
                                                    this.handleEditorChange
                                                  }
                                                /> */}
                                              </div>
                                              {/*<div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <TextareaAutosize
                                  maxrows={4}
                                  name="client_services_welcome_content"
                                  id="client_services_welcome_content"
                                  aria-label=""
                                  placeholder=""
                                  value={
                                    this.state.client_services_welcome_content
                                  }
                                  onChange={this.handleChangeClientServices}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderColor: "rgba(0, 0, 0, 0.125)",
                                    borderRadius: "4px",
                                  }}
                                  variant="outlined"
                                />
                                </div>*/}
                                            </div>
                                            {/*<div
                              className="col-lg-12"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div>
                                <h6>Email</h6>
                              </div>
                              <div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <MutextField
                                  required
                                  id="client_services_email"
                                  name="client_services_email"
                                  type="email"
                                  value={this.state.client_services_email}
                                  onChange={this.handleChangeClientServices}
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                              </div>*/}
                                            {this.state.isEditorReady ? (
                                              <>
                                                <div
                                                  className="col-lg-12"
                                                  style={{
                                                    marginTop: "20px",
                                                  }}
                                                >
                                                  <table className="table table-striped">
                                                    <tbody>
                                                      <tr>
                                                        <th scope="row">
                                                          Client Place Holders
                                                        </th>
                                                        <td>
                                                          <ul className="preview-list">
                                                            <li className="preview-item">
                                                              <span className="badge badge-outline-gray">
                                                                [[Company_Name]]
                                                              </span>
                                                            </li>
                                                            <li className="preview-item">
                                                              <span className="badge badge-outline-gray">
                                                                [[Company_Email]]
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div
                                                  className="form-group text-right col-lg-12"
                                                  style={{
                                                    marginTop: "20px",
                                                  }}
                                                >
                                                  {" "}
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                      this.handleClientServicesSubmit();
                                                    }}
                                                    disabled={
                                                      this.state.disabled
                                                    }
                                                  >
                                                    {" "}
                                                    Submit{" "}
                                                  </button>{" "}
                                                </div>
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "credit_app"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemCreditApp"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="modal-title mb-4" style={{}}>
                                  <h5>
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    Credit Applications
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state.errorMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessageAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .successMessageAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="card card-preview">
                                          {this.state.processing_request ===
                                          true ? (
                                            <div className="example-alert mb-3">
                                              <div className="alert alert-light">
                                                <div
                                                  className="spinner-grow spinner-grow-sm"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>{" "}
                                                Please wait while we are
                                                processing your request.
                                              </div>
                                            </div>
                                          ) : null}

                                          {this.state.rejection_container ===
                                          true ? (
                                            <div className="col-md-12 mb-3">
                                              <h5>Rejection Reason</h5>
                                              <div className="">
                                                <div className="form-group mt-1">
                                                  <MutextField
                                                    required
                                                    id="rejection_reason"
                                                    name="rejection_reason"
                                                    type="text"
                                                    label="Reason"
                                                    defaultValue={
                                                      this.state
                                                        .rejection_reason
                                                    }
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                  />
                                                </div>
                                                <div
                                                  className="form-group text-right"
                                                  style={{
                                                    marginTop: "15px",
                                                    marginBottom: "15px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    style={{ marginRight: 5 }}
                                                    className="btn btn-light"
                                                    onClick={() => {
                                                      this.setState({
                                                        rejection_container: false,
                                                      });
                                                    }}
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                      this.rejectCreditApplication();
                                                    }}
                                                    disabled={
                                                      this.state.disabled
                                                    }
                                                  >
                                                    Submit
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}

                                          <div
                                            className="card card-bordered"
                                            id="creditApplicationTable"
                                          >
                                            {this.state.credit_applications &&
                                            this.state.credit_applications
                                              .length > 0 ? (
                                              <table className="table table-striped table-bordered">
                                                <thead>
                                                  <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">
                                                      Created Date
                                                    </th>
                                                    <th scope="col">
                                                      Approved date
                                                    </th>
                                                    <th scope="col">
                                                      Rejected Date
                                                    </th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.credit_applications.map(
                                                    (applications, index) => (
                                                      <tr key={`cr${index}`}>
                                                        <th scope="row">
                                                          {applications[0]}
                                                        </th>
                                                        <td>
                                                          {this.format_date(
                                                            applications[1]
                                                          )}
                                                        </td>
                                                        <td>
                                                          {applications[2] &&
                                                          applications[2] !== ""
                                                            ? this.format_date(
                                                                applications[2]
                                                              )
                                                            : "---"}
                                                        </td>
                                                        <td>
                                                          {applications[3] &&
                                                          applications[3] !== ""
                                                            ? this.format_date(
                                                                applications[3]
                                                              )
                                                            : "---"}
                                                        </td>
                                                        <td>
                                                          {applications[4] ===
                                                          "pending" ? (
                                                            <span className="badge badge-dot badge-warning">
                                                              Pending
                                                            </span>
                                                          ) : applications[4] ===
                                                            "approved" ? (
                                                            <span className="badge badge-dot badge-success">
                                                              Approved
                                                            </span>
                                                          ) : applications[4] ===
                                                            "rejected" ? (
                                                            <span className="badge badge-dot badge-danger">
                                                              Rejected
                                                            </span>
                                                          ) : null}
                                                        </td>
                                                        <td>
                                                          <div className="dropdown">
                                                            <a
                                                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                                              data-toggle="dropdown"
                                                            >
                                                              <em className="icon ni ni-more-h"></em>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
                                                              <ul className="link-list-plain">
                                                                <li>
                                                                  <a
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.downloadCreditAppPdf(
                                                                        applications[6]
                                                                      )
                                                                    }
                                                                  >
                                                                    View PDF
                                                                  </a>
                                                                </li>
                                                                {applications[4] ===
                                                                "pending" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        this.setState(
                                                                          {
                                                                            application_no:
                                                                              applications[0],
                                                                            rejection_container: true,
                                                                          }
                                                                        );
                                                                      }}
                                                                    >
                                                                      Reject
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                                {applications[4] ===
                                                                "pending" ? (
                                                                  <li>
                                                                    <a
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        this.approveCreditApplication(
                                                                          applications[0]
                                                                        )
                                                                      }
                                                                    >
                                                                      Approve
                                                                    </a>
                                                                  </li>
                                                                ) : null}
                                                              </ul>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            ) : (
                                              <div className="alert alert-pro alert-danger">
                                                <div className="alert-text">
                                                  <h6>No Credit Application</h6>
                                                  <p>
                                                    Credit applications are not
                                                    available for this client at
                                                    the moment.{" "}
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Archived Agreements *****/}
                        <div
                          className={
                            this.state.activeTab === "archived_agreements"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemArchivedAgreements"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="nk-block-head">
                                <div className="modal-title mb-4" style={{}}>
                                  <h5>
                                    <em className="icon ni ni-card-view"></em>{" "}
                                    Archived Agreements
                                  </h5>
                                </div>
                                {this.state.assignedLoader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="caption-text">
                                      <div
                                        className="tab-pane active"
                                        id="tabItemSigned"
                                      >
                                        {this.state
                                          .errorMessageArchiveAgreements !==
                                        "" ? (
                                          <div className="example-alert example-alert-revoke">
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>
                                                  {
                                                    this.state
                                                      .errorMessageArchiveAgreements
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="card card-bordered card-preview">
                                          <DataTableExtended
                                            columns={this.state.columnServices}
                                            tableData={
                                              this.state.archivedAgreements
                                            }
                                            title=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Archived AGreements *****/}
                      </div>
                    </div>

                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-avatar lg bg-primary">
                              <span>{this.state.client_name_initials}</span>
                            </div>
                            <div class="user-info">
                              {this.state.client_status &&
                              this.state.client_status === "Yes" ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Verified
                                </div>
                              ) : (
                                <div class="badge badge-outline-danger badge-pill ucap">
                                  Not Verified
                                </div>
                              )}
                              <h5>{this.state.client_update_name}</h5>
                              <span class="sub-text">
                                {this.state.client_update_email}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #dbdfea",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "stretch", // Make sure all children stretch to full height
                            gap: "10px",
                            fontSize: "20px",
                          }}
                        >
                          <div
                            style={{
                              margin: "5px 0px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <a
                              data-toggle="tooltip"
                              data-placement="left"
                              onClick={() => this.editClientModal()}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <em
                                className="icon ni ni-edit"
                                style={{ fontSize: "14px" }}
                              ></em>
                              <span
                                style={{ fontSize: "12px", marginLeft: "5px" }}
                              >
                                Edit Client
                              </span>
                            </a>
                          </div>

                          {/* Divider */}
                          <div
                            style={{
                              borderLeft: "1px solid #dbdfea",
                              height: "36px",
                              margin: "0 3px",
                            }}
                          ></div>

                          {this.state.client_status &&
                          this.state.client_status === "No" ? (
                            <div
                              style={{
                                margin: "5px 0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <a
                                data-toggle="tooltip"
                                data-placement="left"
                                onClick={() => this.resendActivation()}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <em
                                  className="icon ni ni-signin"
                                  style={{ fontSize: "14px" }}
                                ></em>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Resend Activation
                                </span>
                              </a>
                            </div>
                          ) : (
                            <div
                              style={{
                                margin: "5px 0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <a
                                data-toggle="tooltip"
                                data-placement="left"
                                onClick={() => this.clientLoginSso()}
                                title="Dashboard"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <em
                                  className="icon ni ni-signin"
                                  style={{ fontSize: "14px" }}
                                ></em>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Dashboard
                                </span>
                              </a>
                            </div>
                          )}
                        </div>

                        {/*<div class="card-inner card-inner-sm">
                          <ul class="btn-toolbar justify-center gx-1">
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-shield-off"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-mail"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-download-cloud"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-bookmark"></em>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                class="btn btn-trigger btn-icon text-danger"
                              >
                                <em class="icon ni ni-na"></em>
                              </a>
                            </li>
                          </ul>
                                              </div>*/}
                        {/* <div class="card-inner">
                          <div class="overline-title-alt mb-2">Payments</div>
                          <div class="profile-balance">
                            <div class="profile-balance-group gx-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    0.00{" "}
                                    <small class="currency currency-usd">
                                      USD
                                    </small>
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Paid Amount
                                </div>
                              </div>
                              <div class="profile-balance-sub">
                                <span class="profile-balance-plus text-soft">
                                  <em class="icon ni ni-plus"></em>
                                </span>
                                <div class="profile-balance-amount">
                                  <div class="number">0.00</div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Remaining Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div class="card-inner">
                          <a
                            class="overline-title-alt"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              this.changeActiveTab("kyc_form");
                              this.showDetails();
                            }}
                          >
                            KYC{" "}
                            <span className="text-primary">
                              ({this.state.kyc_percentage}
                              %)
                            </span>
                          </a>
                          <div class="row text-center mt-2">
                            <div class="col-12">
                              <div className="card card-bordered card-preview">
                                <div className="card-inner viewClient_card-inner_customStyle">
                                  <div className="progress progress-lg">
                                    <div
                                      className="progress-bar"
                                      data-progress={this.state.kyc_percentage}
                                      style={{
                                        width: `${this.state.kyc_percentage}%`,
                                      }}
                                    >
                                      {this.state.kyc_percentage}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <a
                            class="overline-title-alt"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.openLinkInNewTab(
                                "/SalesOrders/" + this.state.client_accountno
                              )
                            }
                          >
                            Sale Orders
                          </a>
                          <div class="row text-center">
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.openLinkInNewTab(
                                    "/SalesOrders/" +
                                      this.state.client_accountno
                                  )
                                }
                              >
                                <span class="amount">
                                  {this.state.totalSalesOrders}
                                </span>
                                <span class="sub-text">Total</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.openLinkInNewTab(
                                    "/SalesOrders/" +
                                      this.state.client_accountno
                                  )
                                }
                              >
                                <span class="amount">
                                  {this.state.completedSalesOrders}
                                </span>
                                <span class="sub-text">Completed</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.openLinkInNewTab(
                                    "/SalesOrders/" +
                                      this.state.client_accountno
                                  )
                                }
                              >
                                <span class="amount">
                                  {this.state.paidSalesOrders}
                                </span>
                                <span class="sub-text">Paid</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <a
                            class="overline-title-alt"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              this.changeActiveTab("agreements");
                              this.clientAgreements();
                            }}
                          >
                            Agreements
                          </a>
                          <div class="row text-center">
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  this.changeActiveTab("agreements");
                                  this.clientAgreements();
                                }}
                              >
                                <span class="amount">
                                  {this.state.totalClientAgreements}
                                </span>
                                <span class="sub-text">Total</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  this.changeActiveTab("agreements");
                                  this.clientAgreements();
                                }}
                              >
                                <span class="amount">
                                  {this.state.signedClientAgreements}
                                </span>
                                <span class="sub-text">Signed</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div
                                class="profile-stats"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  this.changeActiveTab("agreements");
                                  this.clientAgreements();
                                }}
                              >
                                <span class="amount">
                                  {this.state.unsignedClientAgreements}
                                </span>
                                <span class="sub-text">Unsigned</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <h6 class="overline-title-alt mb-2">Additional</h6>
                          <div class="row g-3">
                            <div class="col-6">
                              <span class="sub-text">User ID:</span>
                              <span>UD003054</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Last Login:</span>
                              <span>15 Feb, 2019 01:02 PM</span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">KYC Status:</span>
                              <span class="lead-text text-success">
                                Approved
                              </span>
                            </div>
                            <div class="col-6">
                              <span class="sub-text">Register At:</span>
                              <span>Nov 24, 2019</span>
                            </div>
                          </div>
                        </div>
                        <div class="card-inner">
                          <h6 class="overline-title-alt mb-3">Groups</h6>
                          <ul class="g-1">
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                investor
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                support
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                            <li class="btn-group">
                              <a class="btn btn-xs btn-light btn-dim" href="#">
                                another tag
                              </a>
                              <a
                                class="btn btn-xs btn-icon btn-light btn-dim"
                                href="#"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                            </li>
                          </ul>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalUpdateClient">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Client Profile</h5>
                  <a
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorClientMessageEdit !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.errorClientMessageEdit,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.successClientMessageEdit !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successClientMessageEdit}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />

                  <div className="row g-4">
                    {this.state.filer_id && this.state.filer_id != "" && (
                      <div className="col-lg-4">
                        <div className="form-group">
                          <TextField
                            label="499 Filer ID"
                            error={this.state.errorClientCompName}
                            id="filer_id"
                            name="filer_id"
                            value={this.state.filer_id}
                            placeholder="499 Filer ID"
                            change={this.handleChange}
                            type="text"
                            disabled={true}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Company Name"
                          error={this.state.errorClientCompName}
                          id="client_comp_name"
                          name="client_comp_name"
                          value={this.state.client_comp_name}
                          placeholder="Company Name"
                          change={this.handleChange}
                          type="text"
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Full Name"
                          error={this.state.errorClientName}
                          id="client_update_name"
                          name="client_update_name"
                          value={this.state.client_update_name}
                          placeholder="Full Name"
                          change={this.handleChange}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Title"
                          error={this.state.errorClientTitle}
                          id="client_title"
                          name="client_title"
                          value={this.state.client_title}
                          placeholder="Title"
                          change={this.handleChange}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Email"
                          type="text"
                          error={this.state.errorClientEmail}
                          id="client_update_email"
                          name="client_update_email"
                          value={this.state.client_update_email}
                          placeholder="Enter email address"
                          change={this.handleChange}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <PhoneInput
                          required
                          defaultCountry={"us"}
                          inputProps={{
                            name: "client_phone",
                            id: "client_phone",
                            maxLength: 20,
                          }}
                          style={{ marginTop: "30px", height: "45px" }}
                          value={
                            this.state.isLoading ? "" : this.state.client_phone
                          }
                          placeholder={
                            this.state.isLoading
                              ? "Loading..."
                              : "Enter the client phone number"
                          }
                          onChange={(value) =>
                            this.handleChange({
                              target: {
                                name: "client_phone",
                                value,
                              },
                            })
                          }
                          helperText="Enter the client phone number."
                          label="Client Phone Number"
                          ref={this.phoneInputRef}
                          onFocus={this.handleFocus}
                        />
                      </div>
                    </div>
                    {/*<div className="col-lg-4">
                      <div className="form-group">
                      
                        <TextField
                          label="Phone"
                          type="text"
                          error={this.state.errorClientPhone}
                          id="client_phone"
                          name="client_phone"
                          value={this.state.client_phone}
                          placeholder="Enter phone"
                          change={this.handleChange}
                          maxLength={20}
                        />
                      </div>
                    </div>*/}
                    <div className="col-lg-4">
                      <div class="nk-block-between-md g-4">
                        <div class="nk-block-head-content">
                          <label
                            htmlFor="googleLocation-autocomplete"
                            style={{
                              color: "black",
                              fontWeight: "500",
                              marginBottom: "8px",
                            }}
                          >
                            Address *
                          </label>
                        </div>
                        <div class="nk-block-head-content">
                          <ul class="nk-block-tools gx-3">
                            <li>
                              <a
                                onClick={this.handleToggle}
                                class="text-primary fs-11"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "11px",
                                }}
                              >
                                {toggleButtonText}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        {useGooglePlaces ? (
                          <GooglePlacesAutocomplete
                            apiKey={
                              process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                            }
                            selectProps={{
                              value: this.state.client_address
                                ? {
                                    label: this.state.client_address,
                                    value: this.state.client_address,
                                  }
                                : null,
                              onChange: this.handleAddressSelect,
                              placeholder: this.state.client_address
                                ? null
                                : "Enter your address..",
                              styles: {
                                control: (provided) => ({
                                  ...provided,
                                  minHeight: "43px",
                                }),
                              },
                            }}
                            className="googleLocation-autocomplete"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="client_address"
                            name="client_address"
                            value={this.state.client_address}
                            onChange={this.handleChange}
                            placeholder="PO Box Address"
                            maxLength={50}
                            fullWidth
                            error={this.state.errorClientAddress === "error"}
                          />
                        )}
                        {/*  */}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Apartment, Suite, Etc."
                          type="text"
                          
                          id="client_address2"
                          name="client_address2"
                          value={this.state.client_address2}
                          placeholder="Enter city"
                          change={this.handleChange}
                          maxLength={32}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="City"
                          type="text"
                          error={this.state.errorClientCity}
                          id="client_city"
                          name="client_city"
                          value={this.state.client_city}
                          placeholder="Enter city"
                          change={this.handleChange}
                          maxLength={32}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="State"
                          type="text"
                          error={this.state.errorClientState}
                          id="client_state"
                          name="client_state"
                          value={this.state.client_state}
                          placeholder="Enter state"
                          change={this.handleChange}
                          maxLength={3}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Zip Code"
                          type="text"
                          error={this.state.errorClientZip}
                          id="client_zip"
                          name="client_zip"
                          value={this.state.client_zip}
                          placeholder="Enter zip code"
                          change={this.handleChange}
                          maxLength={9}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label
                          className="text-dark"
                          style={{
                            marginBottom: "0.5rem",
                            fontWeight: "600",
                          }}
                        >
                          Country
                        </label>
                        <Select
                          id="client_country"
                          name="client_country"
                          className="customCountrySelect"
                          options={this.countryOptions}
                          value={{
                            label: this.state.client_country,
                            value: this.state.client_country,
                          }}
                          onChange={this.handleChangeCountry}
                          placeholder="Select country"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Password"
                          type="password"
                          error={this.state.errorClientPass}
                          id="client_password"
                          name="client_password"
                          value={this.state.client_password}
                          placeholder="Enter password"
                          change={this.handleChange}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextField
                          label="Confirm Password"
                          type="password"
                          error={this.state.errorClientPass}
                          id="client_confirm_password"
                          name="client_confirm_password"
                          value={this.state.client_confirm_password}
                          placeholder="Enter confirm password"
                          change={this.handleChange}
                          maxLength={30}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 small_select">
                      <div className="form-group">
                        <label
                          className="text-dark"
                          style={{
                            marginBottom: "0.5rem",
                            fontWeight: "600",
                          }}
                        >
                          Account Status
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="client_account_status"
                          name="client_account_status"
                          value={this.state.client_account_status}
                          onChange={this.handleChange}
                        >
                          <option key="5" value="active">
                            Active
                          </option>
                          <option key="10" value="inactive">
                            Inactive
                          </option>
                        </select>
                        {/*<MutextField
                          id="client_account_status"
                          name="client_account_status"
                          select
                          value={this.state.client_account_status}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          style={{paddingTop: "13px", paddingBottom: "13px"}}
                          helperText="Select the status here."
                          variant="outlined"
                          fullWidth
                        >
                          <option key="5" value="active">
                            Active
                          </option>
                          <option key="10" value="inactive">
                            Inactive
                          </option>
                        </MutextField>*/}
                      </div>
                    </div>
                    <div
                      className="custom-control custom-control-lg custom-checkbox col-lg-12"
                      style={{ padding: 0, marginLeft: "3rem" }}
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck2"
                          onChange={() => {
                            const isChecked = !this.state.notifyCheckBox;
                            this.setState({ notifyCheckBox: isChecked });
                            console.log("Notify Checkbox Checked:", isChecked);
                          }}
                        />
                        <label class="custom-control-label" for="customCheck2">
                          Notify Client
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4">&nbsp;</div>
                    <div style={{ clear: "both" }}></div>
                    {/* <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            tabIndex="-1"
                            href="#"
                            className="form-icon form-icon-right passcode-switch"
                            data-target="password"
                          >
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg " +
                              this.state.errorPass
                            }
                            id="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            placeholder="Enter your password"
                          />
                        </div>
                      </div> */}
                    <div className="col-lg-12 text-right">
                      <button
                        type="button"
                        className="btn btn-lg"
                        onClick={() => {
                          this.formUpdateClient();
                        }}
                        style={{
                          backgroundColor: THEME_COLOR,
                          color: THEME_TEXT_COLOR,
                          float: "right",
                        }}
                        disabled={this.state.disabled}
                      >
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalAlertResend">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a href="#" className="close" data-dismiss="modal">
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    {this.state.activation_button === true ? (
                      <>
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-warning"></em>
                        <h4 className="nk-modal-title">Sending!</h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            Please wait while we are processing your request
                          </div>
                          <span className="sub-text-sm"></span>
                        </div>
                        <div className="nk-modal-action"></div>
                      </>
                    ) : null}
                    {this.state.activation_tick === true ? (
                      <>
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">Congratulations!</h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            Activation email has been sent successfully.
                          </div>
                          <span className="sub-text-sm"></span>
                        </div>
                        <div className="nk-modal-action"></div>
                      </>
                    ) : null}
                    {this.state.activation_cross === true ? (
                      <>
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Oops!</h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            There is some error while sending activation email.
                            Please try again later
                          </div>
                          <span className="sub-text-sm"></span>
                        </div>
                        <div className="nk-modal-action"></div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="modal-footer bg-lighter">
                  <div className="text-center w-100">
                    <a
                      href="#"
                      className="btn btn-lg btn-mw btn-primary"
                      data-dismiss="modal"
                    >
                      Close
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Role Assignment Modal START */}
          <div className="modal fade" tabIndex="-1" id="modalAgentAssignRole">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em className="icon ni ni-grid-alt"></em>Control Panel{" "}
                      <sup>
                        <small className="text-soft">
                          {this.state.rolesAgentName}
                        </small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  <div className="nk-modal">
                    <div className="nk-modal-text">
                      {this.state.agentRoleLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          {this.state.errorMessageAgentRole !== "" ? (
                            <div
                              className="example-alert "
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageAgentRole}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessageAgentRole !== "" ? (
                            <div
                              className="example-alert "
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageAgentRole}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="card card-bordered card-preview">
                            <table className="table table-sm">
                              <thead className="tb-odr-head">
                                <tr className="tb-odr-item">
                                  <th
                                    width="35%"
                                    style={{
                                      borderRight: "1px solid #dbdfea",
                                    }}
                                  >
                                    <span className="tb-odr-action">Roles</span>
                                  </th>
                                  <th width="65%">
                                    <span className="tb-odr-action">
                                      Sub Roles
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="tb-odr-body">
                                {this.state.allRoleData &&
                                this.state.allRoleData.length > 0 ? (
                                  <>
                                    {this.state.allRoleData.map(
                                      (role, index) => (
                                        <tr className="" key={`role${index}`}>
                                          <td
                                            style={{
                                              borderRight: "1px solid #dbdfea",
                                            }}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={this.state.allRoleData.some(
                                                    (item) =>
                                                      item.alt === role.alt &&
                                                      item.isChecked
                                                  )}
                                                  onChange={
                                                    this.changedAllRolesRights
                                                  }
                                                  disabled={
                                                    role.seqno === 1 &&
                                                    this.state.assignRoleData.some(
                                                      (subRole) =>
                                                        role.alt === subRole.alt
                                                    )
                                                  }
                                                  name={role?.id?.toString()}
                                                  color="primary"
                                                />
                                              }
                                              style={{
                                                marginBottom: "0px",
                                              }}
                                              label={role.alt}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              paddingLeft: "16px",
                                            }}
                                          >
                                            {this.state.allSubRoleData
                                              .filter((subRole) => {
                                                return (
                                                  subRole.role_id === role.id
                                                );
                                              })
                                              .map((subRole, subIndex) => (
                                                <div key={`subRole${subIndex}`}>
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked={this.state.allSubRoleData.some(
                                                          (item) =>
                                                            item.alt ===
                                                              subRole.alt &&
                                                            item.isChecked
                                                        )}
                                                        onChange={
                                                          this
                                                            .changedAllSubRolesRights
                                                        }
                                                        name={subRole?.tabdetailid?.toString()}
                                                        color="primary"
                                                      />
                                                    }
                                                    style={{
                                                      marginBottom: "0px",
                                                    }}
                                                    label={subRole.alt}
                                                  />
                                                </div>
                                              ))}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                          <div className="nk-modal-action  text-right">
                            <a
                              href="#"
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.handleSubmitRoleCheckbox(
                                  this.state.checkedName,
                                  this.state.checkedEmail
                                );
                              }}
                              disabled={this.state.disableRoleBtn}
                            >
                              Save Changes
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Role Assignment Modal End */}
          {/**** Open Signee Details Modal ***/}
          <div class="modal fade" tabindex="-1" id="modalOpenSignee">
            <div class="modal-dialog modal-xl modal-dialog-top" role="document">
              <div class="modal-content">
                <a
                  href="#"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em class="icon ni ni-cross"></em>
                </a>
                <div class="modal-header">
                  <h5 class="modal-title">Signee Details</h5>
                </div>
                <div class="modal-body">
                  <div class="g-4">
                    <div id="no-more-tables-new">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                          <tr>
                            <th class="tb-col-os">
                              <span class="overline-title">Name</span>
                            </th>
                            <th class="tb-col-os">
                              <span class="overline-title">Email</span>
                            </th>
                            <th class="tb-col-os">
                              <span class="overline-title">Title</span>
                            </th>
                            <th class="tb-col-ip">
                              <span class="overline-title">Signed Date</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.signee_details &&
                          this.state.signee_details.length > 0 ? (
                            <>
                              {this.state.signee_details.map(
                                (details, index) => (
                                  <tr key={`idx${index}`}>
                                    <td class="tb-col-os">
                                      {details.signee_name}
                                    </td>
                                    <td class="tb-col-os">
                                      {details.signee_email}
                                    </td>
                                    <td class="tb-col-os">
                                      {details.signee_title &&
                                      details.signee_title !== ""
                                        ? details.signee_title
                                        : "---"}
                                    </td>
                                    <td class="tb-col-ip">
                                      {details.signed_date &&
                                      details.signed_date !== ""
                                        ? this.format_date(details.signed_date)
                                        : "---"}
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          ) : (
                            <tr>
                              <td class="tb-col-os" colSpan={4}>
                                <strong className="text-danger">
                                  Record not found
                                </strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**** Open Signee Details Modal ***/}

          {/**** Open Contract Modal ***/}
          <div class="modal fade" tabindex="-1" id="modalOpenUploadContract">
            <div class="modal-dialog modal-xl modal-dialog-top" role="document">
              <div class="modal-content">
                <a
                  href="#"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em class="icon ni ni-cross"></em>
                </a>
                <div class="modal-header">
                  <h5 class="modal-title">Upload Agreement</h5>
                </div>
                <div class="modal-body">
                  <div className="row g-gs">
                    <div className="col-md-12 mb-3">
                      {this.state.uploadErrorMessage !== "" ? (
                        <div class="alert alert-pro alert-danger mb-2">
                          <div class="alert-text">
                            <h6>Error</h6>
                            <p>{this.state.uploadErrorMessage} </p>
                          </div>
                        </div>
                      ) : null}
                      {this.state.uploadSuccessMessage !== "" ? (
                        <div class="alert alert-pro alert-success mb-2">
                          <div class="alert-text">
                            <h6>Success</h6>
                            <p>{this.state.uploadSuccessMessage} </p>
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="col-md-12 mb-2"
                        style={{ marginBottom: "20px", clear: "both" }}
                      >
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label">
                              <Tooltip
                                title="Upload agreement directly."
                                placement="right"
                              >
                                <em
                                  className="icon ni ni-info"
                                  style={{
                                    color: "#6576ff",
                                  }}
                                ></em>
                              </Tooltip>{" "}
                              Upload Agreement <sup>*</sup>
                              <span className="text-danger"></span>
                            </label>
                          </div>
                          <div className="form-control-group signatory_upload mb-4">
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="add_picture"
                                name="add_picture"
                                defaultValue={this.state.add_picture}
                                accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt, .rtf, .zip"
                                onChange={this.handleFileChange}
                              />
                              <label
                                class="custom-file-label"
                                for="add_picture"
                              >
                                Choose file
                              </label>
                            </div>
                          </div>
                          <div class="form-note">
                            {this.state.add_picture_name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-5">
                        <div className="example-alert">
                          <div className="alert alert-light">
                            <ul className="list">
                              <li>
                                Accepted Formats: PDF, DOC, DOCX, ZIP, TXT, RTF,
                                PNG, JPEG, JPG
                              </li>
                              <li>Recommended Size: 10MB</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer" style={{ justifyContent: "right" }}>
                  <div class="d-flex justify-content-end">
                    {this.state.upload_agreement_button === true ? (
                      <button className="btn btn-lg btn-primary" disabled>
                        <em className="icon ni ni-swap"></em> Saving
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        onClick={() => this.submitClientContract()}
                      >
                        Save Agreement
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**** Open Contract Modal ***/}
          <div className="modal fade" tabindex="-1" id="modalDefaultLogin">
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em
                      className="icon ni ni-user-list"
                      style={{ marginRight: "15px" }}
                    ></em>{" "}
                    Login to the Client
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.loginErrorMessage === "" ? (
                    <div className="alert alert-pro alert-primary">
                      <div className="alert-text">
                        <h6>Connecting.....</h6>
                        <p>
                          Please wait while we are connecting to the client
                          Login. You will be redirected to the Client dashboard
                          after the authentication.
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {this.state.loginErrorMessage !== "" ? (
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h6>Login Error</h6>
                        <p>{this.state.loginErrorMessage}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* View Archived Details */}
          <div
            className="modal fade zoom"
            tabIndex="-1"
            id="modalArchivedDetails"
          >
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em className="icon ni ni-list-round"></em> Agreement
                      Details <sup></sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* NRC DIVS START */}

                  {this.state.viewLoader === true ? (
                    FormLoader()
                  ) : (
                    <>
                      {this.state.errorMessageArchivedDetails !== "" ? (
                        <div className="example-alert example-alert-revoke">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageArchivedDetails}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div>
                        <div className="card card-bordered">
                          <div className="card-inner-group">
                            {this.state.archivedAgreementsDetails &&
                            this.state.archivedAgreementsDetails.length > 0 ? (
                              <div className="card-inner">
                                <div className="sp-plan-head">
                                  <h6 className="title">Agreement Details</h6>
                                </div>
                                <div className="sp-plan-desc sp-plan-desc-mb">
                                  <table className="table table-bordered table-striped">
                                    {this.state.archivedAgreementsDetails.map(
                                      (general, index) => (
                                        <tbody
                                          key={index}
                                          className="text-dark"
                                        >
                                          <tr>
                                            <td>Agreement Name</td>
                                            <td>{general.agreement_title}</td>
                                          </tr>
                                          <tr>
                                            <td>Archived Date</td>
                                            <td>
                                              {this.format_date(
                                                general.archived_date
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Approved Date</td>
                                            <td>
                                              {this.format_date(
                                                general.approved_date
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Document Uploaded</td>
                                            <td>
                                              {general.is_uploaded &&
                                              general.is_uploaded === "Yes"
                                                ? "Document Uploaded"
                                                : "---"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Signee name</td>
                                            <td>{general.signee_name}</td>
                                          </tr>
                                          <tr>
                                            <td>Signee Email</td>
                                            <td>
                                              {general.signee_email &&
                                              general.signee_email !== ""
                                                ? general.signee_email
                                                : "---"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Signee Title</td>
                                            <td>
                                              {general.signee_title &&
                                              general.signee_title !== ""
                                                ? general.signee_title
                                                : "---"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Reason</td>
                                            <td>{general.revoke_reason}</td>
                                          </tr>
                                        </tbody>
                                      )
                                    )}
                                  </table>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* View Archived Details */}
          {/* DELETE ARCHIVE */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">
                      Delete archived agreement?
                    </h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessageDelete !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessageDelete}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessageDelete !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessageDelete}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this archived agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                        disabled={this.state.disabled}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.delete_archived_agreement();
                        }}
                        disabled={this.state.disabled}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DELETE ARCHIVE */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewClient));
